export const fetchDataConteoAgave = async (conteoAgaves, setDatosAnalisis, precioPlantacion, costosPlantacion, utilidadNeta) => {
    try {
        const datos = {
            conteoAgaves:{
                titulo: "Censo de Agave",
                valor: conteoAgaves || 0
            },
            precioPlantacion: {
                titulo: "Precio de Plantación",
                valor: precioPlantacion || 0
            },
            costosPlantacion: {
                titulo: "Costos de Plantación",
                valor: costosPlantacion || 0
            },
            utilidadNeta: {
                titulo: "Utilidad Neta",
                valor: utilidadNeta || 0
            }
        };
        setDatosAnalisis(datos);
    } catch (error) {
        console.error("Error al cargar datos de Conteo Agaves:", error);
        setDatosAnalisis({});
    }
};

export const shouldEnableExecBashConteoAgaves = (selectedZipFile) => {
    return selectedZipFile !== null;
};

export const processConteoAgavesData = (datosAnalisis, indicadores) => ({
    analisis: "CONTEO_AGAVE",
    ...datosAnalisis,
    indicadores: {
        ...indicadores,
    }
});

export const mappingConfig = {
    costos: {
        semilla: {
            title: "Semilla",
            placeholder: "Costo de semilla",
            value: ""
        },
        siembra: {
            title: "Siembra",
            placeholder: "Costo de siembra",
            value: ""
        },
        riego: {
            title: "Riego",
            placeholder: "Costo de riego",
            value: ""
        },
        fertilizacion: {
            title: "Fertilización",
            placeholder: "Costo de fertilización",
            value: ""
        },
        plagasEnfermedades: {
            title: "Plagas y enfermedades",
            placeholder: "Costo de control de plagas y enfermedades",
            value: ""
        },
        cosecha: {
            title: "Cosecha",
            placeholder: "Costo de cosecha",
            value: ""
        }
    },
    precio: {
        precioPorKilo: {
            title: "Precio por kilo",
            placeholder: "Ingresa el precio por kilo",
            value: ""
        }
    },
    peso: {
        pesoPromedio: {
            title: 'Peso promedio (kg)',
            placeholder: 'Peso promedio (kg)',
            value: ''
        }
    },
    impuestos: {}
};
