// IndicatorConfig.js

import React, { useState, useEffect } from 'react';
import {
    Grid,
    Tabs,
    Tab,
    Box,
    Collapse,
    Button,
    Snackbar,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';
import { AiOutlineSetting, AiOutlineDashboard } from 'react-icons/ai';
import axios from 'axios';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { API_BASE_URL } from '../../utils/config';
import {
    SectionCard,
    SectionTitle,
    ExpandIcon,
    InputsContainer,
    StyledTextField
} from './IndicatorConfigStyle';
import analysisConfig from '../../utils/analysisConfig';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const IndicatorConfig = () => {
    const [indicators, setIndicators] = useState({});
    const [mappingData, setMappingData] = useState({});
    const [selectedTab, setSelectedTab] = useState(0);
    const [expandedSection, setExpandedSection] = useState(null);

    // Estados para snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    // Estados para diálogos (añadir costos/impuestos)
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogSectionType, setDialogSectionType] = useState(''); // 'costos' o 'impuestos'
    const [dialogAnalysisType, setDialogAnalysisType] = useState('');
    const [newItemTitle, setNewItemTitle] = useState('');
    const [taxType, setTaxType] = useState('porcentaje'); // por defecto, 'porcentaje'

    useEffect(() => {
        const fetchAllConfigs = async () => {
            const allConfigs = {};

            // 1. Obtenemos la configuración de límites para cada tipo de análisis
            for (const analysisType in analysisConfig) {
                if (analysisConfig[analysisType].initialIndicators) {
                    try {
                        const response = await axios.get(
                            `${API_BASE_URL}configuration/limites/${analysisType.toLowerCase()}`
                        );
                        allConfigs[analysisType] = {
                            cardData: response.data.limites.reduce((acc, limit) => {
                                acc[limit.indicador] = {
                                    max: limit.max !== null ? limit.max : '',
                                    min: limit.min !== null ? limit.min : '',
                                    title: limit.indicador
                                };
                                return acc;
                            }, {})
                        };
                    } catch (error) {
                        console.error(`Error al obtener la configuración para ${analysisType}:`, error);
                        allConfigs[analysisType] = {
                            cardData: analysisConfig[analysisType].initialIndicators
                        };
                    }
                }
            }
            setIndicators(allConfigs);

            // 2. Obtenemos la configuración de mapeo para cada tipo de análisis
            const initialMappingData = {};
            for (const analysisType in analysisConfig) {
                if (analysisConfig[analysisType].mappingConfig) {
                    try {
                        const response = await axios.get(
                            `${API_BASE_URL}configuration/mappingConfig/${analysisType.toLowerCase()}`
                        );
                        if (response.data?.mapping) {
                            initialMappingData[analysisType] = {
                                ...JSON.parse(JSON.stringify(analysisConfig[analysisType].mappingConfig)),
                                ...response.data.mapping
                            };
                        } else {
                            initialMappingData[analysisType] = JSON.parse(
                                JSON.stringify(analysisConfig[analysisType].mappingConfig)
                            );
                        }
                    } catch (error) {
                        console.error(
                            `Error al obtener la configuración de mapeo para ${analysisType}:`,
                            error
                        );
                        initialMappingData[analysisType] = JSON.parse(
                            JSON.stringify(analysisConfig[analysisType].mappingConfig)
                        );
                    }
                }
            }
            setMappingData(initialMappingData);
        };

        fetchAllConfigs();
    }, []);

    // Maneja cambios en los tabs
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        setExpandedSection(null);
    };

    // Expande/colapsa sección
    const toggleSection = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    // Maneja cambios en los indicadores (Dashboard)
    const handleInputChange = (section, analysisType, key, field, value) => {
        setIndicators((prev) => ({
            ...prev,
            [analysisType]: {
                ...prev[analysisType],
                [section]: {
                    ...prev[analysisType][section],
                    [key]: {
                        ...prev[analysisType][section][key],
                        [field]: value || ''
                    }
                }
            }
        }));
    };

    // Maneja cambios en el mapeo
    const handleMappingInputChange = (analysisType, sectionKey, fieldKey, value) => {
        setMappingData((prev) => ({
            ...prev,
            [analysisType]: {
                ...prev[analysisType],
                [sectionKey]: {
                    ...prev[analysisType][sectionKey],
                    [fieldKey]: {
                        ...prev[analysisType][sectionKey][fieldKey],
                        value: value || ''
                    }
                }
            }
        }));
    };

    // Guardar límites de indicadores (Dashboard)
    const handleSaveClick = async () => {
        try {
            for (const analysisType in indicators) {
                const limites = Object.keys(indicators[analysisType]?.cardData || {}).map((key) => ({
                    indicador: key,
                    max: parseFloat(indicators[analysisType].cardData[key].max) || null,
                    min: parseFloat(indicators[analysisType].cardData[key].min) || null
                }));

                await axios.post(`${API_BASE_URL}configuration/limites`, {
                    tipo_analisis: analysisType.toLowerCase(),
                    limites
                });
            }

            setSnackbarMessage('Configuración guardada exitosamente');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error al guardar la configuración:', error);
            setSnackbarMessage('Error al guardar la configuración');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    // Guardar datos de mapeo
    const handleSaveMappingClick = async () => {
        try {
            for (const analysisType in mappingData) {
                await axios.post(`${API_BASE_URL}configuration/mappingConfig`, {
                    analysisType: analysisType.toLowerCase(),
                    mapping: mappingData[analysisType]
                });
            }

            setSnackbarMessage('Mapeo guardado exitosamente');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error al guardar el mapeo:', error);
            setSnackbarMessage('Error al guardar el mapeo');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    // Abre diálogo para añadir costos o impuestos
    const handleOpenDialog = (analysisType, sectionKey) => {
        setDialogAnalysisType(analysisType);
        setDialogSectionType(sectionKey);
        setNewItemTitle('');
        setTaxType('porcentaje'); // Valor por defecto para nuevo impuesto
        setOpenDialog(true);
    };

    // Cierra diálogo
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // Añadir nuevo costo o impuesto
    const handleAddNewItem = () => {
        if (!newItemTitle.trim()) return;

        let computedTitle = newItemTitle;

        // Si es un impuesto, vemos si es porcentaje o fijo
        if (dialogSectionType === 'impuestos') {
            if (taxType === 'porcentaje') {
                computedTitle += ' (%)';
            } else {
                // Asumimos "fijo"
                computedTitle += ' (fijo)';
            }
        }

        // Se crea el nuevo campo con label y placeholder iguales
        setMappingData((prev) => ({
            ...prev,
            [dialogAnalysisType]: {
                ...prev[dialogAnalysisType],
                [dialogSectionType]: {
                    ...prev[dialogAnalysisType][dialogSectionType],
                    [computedTitle]: {
                        title: computedTitle,
                        placeholder: computedTitle,
                        value: '',
                        type: dialogSectionType === 'impuestos' ? taxType : 'costo'
                        // 'type' es un campo extra para saber si es 'porcentaje'/'fijo'/'costo'
                    }
                }
            }
        }));

        handleCloseDialog();
    };

    // Inputs del Dashboard (cardData)
    const renderInputsForSection = (analysisType) => (
        <InputsContainer>
            <Grid container spacing={2}>
                {Object.keys(indicators[analysisType]?.cardData || {}).map((key) => (
                    <Grid item xs={12} sm={6} md={4} key={key}>
                        <StyledTextField
                            label={`${indicators[analysisType].cardData[key].title} - Máximo`}
                            variant="outlined"
                            value={indicators[analysisType].cardData[key].max || ''}
                            onChange={(e) =>
                                handleInputChange('cardData', analysisType, key, 'max', e.target.value)
                            }
                        />
                        <StyledTextField
                            label={`${indicators[analysisType].cardData[key].title} - Mínimo`}
                            variant="outlined"
                            value={indicators[analysisType].cardData[key].min || ''}
                            onChange={(e) =>
                                handleInputChange('cardData', analysisType, key, 'min', e.target.value)
                            }
                        />
                    </Grid>
                ))}
            </Grid>
        </InputsContainer>
    );

    // Panel de "Dashboard Indicadores" (acordeón)
    const renderDashboardSections = () =>
        Object.keys(indicators).map((analysisType) => (
            <Box key={analysisType}>
                <SectionCard onClick={() => toggleSection(analysisType)}>
                    <SectionTitle>{analysisType.replace('_', ' ')}</SectionTitle>
                    <ExpandIcon>
                        {expandedSection === analysisType ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ExpandIcon>
                </SectionCard>
                <Collapse in={expandedSection === analysisType}>
                    {renderInputsForSection(analysisType)}
                </Collapse>
            </Box>
        ));

    // Panel de "Mapeo" (acordeón)
    const renderMappingInputsForSection = (analysisType) => {
        const mapping = mappingData[analysisType] || {};
        return (
            <InputsContainer>
                {Object.keys(mapping).map((sectionKey) => {
                    const items = mapping[sectionKey];

                    return (
                        <Box key={sectionKey} sx={{ marginBottom: 2 }}>
                            <h3>{sectionKey.charAt(0).toUpperCase() + sectionKey.slice(1)}</h3>
                            <Grid container spacing={2}>
                                {Object.keys(items).map((fieldKey) => {
                                    const fieldData = items[fieldKey];
                                    return (
                                        <Grid item xs={12} sm={6} md={4} key={fieldKey}>
                                            <StyledTextField
                                                label={fieldData.title}
                                                placeholder={fieldData.placeholder}
                                                variant="outlined"
                                                value={fieldData.value || ''}
                                                onChange={(e) =>
                                                    handleMappingInputChange(analysisType, sectionKey, fieldKey, e.target.value)
                                                }
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>

                            {/* Botones para añadir nuevo costo o impuesto */}
                            {sectionKey === 'costos' && (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleOpenDialog(analysisType, 'costos')}
                                    style={{ marginTop: '10px' }}
                                >
                                    Añadir nuevo costo
                                </Button>
                            )}
                            {sectionKey === 'impuestos' && (
                                <Button
                                    variant="outlined"
                                    onClick={() => handleOpenDialog(analysisType, 'impuestos')}
                                    style={{ marginTop: '10px' }}
                                >
                                    Añadir nuevo impuesto
                                </Button>
                            )}
                        </Box>
                    );
                })}
            </InputsContainer>
        );
    };

    const renderMappingSections = () =>
        Object.keys(mappingData).map((analysisType) => (
            <Box key={analysisType}>
                <SectionCard onClick={() => toggleSection(analysisType)}>
                    <SectionTitle>{analysisType.replace('_', ' ')}</SectionTitle>
                    <ExpandIcon>
                        {expandedSection === analysisType ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ExpandIcon>
                </SectionCard>
                <Collapse in={expandedSection === analysisType}>
                    {renderMappingInputsForSection(analysisType)}
                </Collapse>
            </Box>
        ));

    return (
        <Box sx={{ width: '100%' }}>
            {/* Tabs */}
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Tabs de configuración" centered>
                <Tab label="Dashboard indicadores" icon={<AiOutlineSetting />} />
                <Tab label="Mapeo" icon={<AiOutlineDashboard />} />
            </Tabs>

            {/* Panel: Dashboard indicadores */}
            <TabPanel value={selectedTab} index={0}>
                {renderDashboardSections()}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveClick}
                    style={{ marginTop: '20px' }}
                >
                    Guardar Configuración
                </Button>
            </TabPanel>

            {/* Panel: Mapeo */}
            <TabPanel value={selectedTab} index={1}>
                {renderMappingSections()}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveMappingClick}
                    style={{ marginTop: '20px' }}
                >
                    Guardar Mapeo
                </Button>
            </TabPanel>

            {/* Snackbar */}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            {/* Diálogo para añadir costos/impuestos */}
            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                <DialogTitle>
                    {dialogSectionType === 'costos' ? 'Añadir nuevo costo' : 'Añadir nuevo impuesto'}
                </DialogTitle>
                <DialogContent>
                    {/* Campo para el título */}
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Título"
                        fullWidth
                        variant="outlined"
                        value={newItemTitle}
                        onChange={(e) => setNewItemTitle(e.target.value)}
                    />

                    {/* Si es impuestos, mostramos opciones "Porcentaje" o "Fijo" */}
                    {dialogSectionType === 'impuestos' && (
                        <Box mt={2}>
                            <FormControl>
                                <FormLabel>Tipo de Impuesto</FormLabel>
                                <RadioGroup
                                    row
                                    value={taxType}
                                    onChange={(e) => setTaxType(e.target.value)}
                                >
                                    <FormControlLabel value="porcentaje" control={<Radio />} label="Porcentaje (%)" />
                                    <FormControlLabel value="fijo" control={<Radio />} label="Tasa Fija ($)" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="inherit">
                        Cancelar
                    </Button>
                    <Button onClick={handleAddNewItem} color="primary" variant="contained">
                        Añadir
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default IndicatorConfig;
