import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Button,
    List,
    ListItem,
    ListItemText,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    Chip,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import { ContentContainer, ScrollableSection, DownloadButton, MapPreviewContainer } from './HistorialModalStyle';
import { Download as DownloadIcon, Delete as DeleteIcon } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Map, { Source, Layer, NavigationControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as turf from '@turf/turf';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { toast } from 'react-toastify';
import { API_BASE_PYTHON_SERVICE } from '../../utils/config';

const HistorialContent = ({
                              analyses,
                              loading,
                              onClose,
                              selectedFiles = [],
                              onCheckboxChange = () => {}
                          }) => {
    const { userData } = useAuth();
    const [currentGeojson, setCurrentGeojson] = useState(null);
    const [currentNoFiltradas, setCurrentNoFiltradas] = useState(null);
    const [currentFiltradas, setCurrentFiltradas] = useState(null);
    const [currentBuffers, setCurrentBuffers] = useState(null);
    const [currentBufferIntersections, setCurrentBufferIntersections] = useState(null);
    const [mapVisible, setMapVisible] = useState(false);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [mapStyle] = useState('mapbox://styles/mapbox/satellite-streets-v12');
    const mapRef = useRef(null);
    const [mapPosition, setMapPosition] = useState({ top: 200, left: 200 });
    const [loadingGeojson, setLoadingGeojson] = useState(false);

    const [isConteoPalmas, setIsConteoPalmas] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [imageCoordinates, setImageCoordinates] = useState(null);
    const [expandedSection, setExpandedSection] = useState(false);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [analysesToDelete, setAnalysesToDelete] = useState([]);
    const [deleting, setDeleting] = useState(false);

    const [localAnalyses, setLocalAnalyses] = useState({});

    const [showBuffers, setShowBuffers] = useState(true);
    const [showNoFiltradasRoutes, setShowNoFiltradasRoutes] = useState(true);

    useEffect(() => {
        const safeAnalyses = (analyses && typeof analyses === 'object') ? { ...analyses } : {};
        const sections = ['Aplicaciones aéreas', 'Cosecha mecánica', 'Conteo de palmas'];

        sections.forEach(section => {
            if (!Array.isArray(safeAnalyses[section])) {
                safeAnalyses[section] = [];
            }
        });

        sections.forEach(section => {
            if (section !== 'Aplicaciones aéreas') {
                safeAnalyses[section] = safeAnalyses[section].filter(file => {
                    return !isTiff(file.file_name);
                });
            }
        });

        if (Array.isArray(safeAnalyses['Aplicaciones aéreas']) && safeAnalyses['Aplicaciones aéreas'].length > 0) {
            const grouped = {};
            safeAnalyses['Aplicaciones aéreas'].forEach(file => {
                if (!file?.file_name) return;
                if (isTiff(file.file_name)) return;
                const fname = file.file_name.split('/').pop() || '';

                let key;
                let isFiltradas = false;
                let isNoFiltradas = false;

                if (fname.includes('_no_filtradas')) {
                    key = fname.replace('_no_filtradas.geojson', '');
                    isNoFiltradas = true;
                } else if (fname.includes('_filtradas')) {
                    key = fname.replace('_filtradas.geojson', '');
                    isFiltradas = true;
                } else {
                    key = fname.replace('.geojson', '');
                }

                if (!grouped[key]) {
                    grouped[key] = {
                        key,
                        filtradas: null,
                        no_filtradas: null,
                        updated: file.updated || null
                    };
                }

                if (file.updated && (!grouped[key].updated || new Date(file.updated) > new Date(grouped[key].updated))) {
                    grouped[key].updated = file.updated;
                }

                if (isFiltradas) grouped[key].filtradas = file;
                if (isNoFiltradas) grouped[key].no_filtradas = file;
                if (!isFiltradas && !isNoFiltradas) {
                    grouped[key].no_filtradas = file;
                }
            });

            const combined = Object.values(grouped).map(item => ({
                key: item.key,
                updated: item.updated,
                filtradas: item.filtradas,
                no_filtradas: item.no_filtradas
            }));
            safeAnalyses['Aplicaciones aéreas'] = combined;
        }

        setLocalAnalyses(safeAnalyses);
    }, [analyses]);

    const isTiff = (fileName) => {
        if (!fileName) return false;
        const ext = fileName.split('.').pop().toLowerCase();
        return ext === 'tif' || ext === 'tiff';
    };

    const validGeometryTypes = ['Point', 'LineString', 'Polygon'];

    const pointLayer = {
        id: 'point-layer',
        type: 'circle',
        paint: {
            'circle-radius': 2,
            'circle-color': '#48ff00',
            'circle-stroke-width': 1,
            'circle-stroke-color': '#48ff00'
        },
        filter: ['==', '$type', 'Point']
    };

    const pointLayerNoFiltradas = {
        id: 'point-layer-no-filtradas',
        type: 'circle',
        paint: {
            'circle-radius': 5,
            'circle-color': '#888888',
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff'
        },
        filter: ['==', '$type', 'Point']
    };

    const lineLayerNoFiltradas = {
        id: 'line-layer-no-filtradas',
        type: 'line',
        paint: {
            'line-color': '#888888',
            'line-width': 2
        },
        layout: {
            'line-sort-key': 0
        },
        filter: ['==', '$type', 'LineString']
    };

    const lineLayerFiltradas = {
        id: 'line-layer-filtradas',
        type: 'line',
        paint: {
            'line-color': '#ff0000',
            'line-width': 2
        },
        layout: {
            'line-sort-key': 1
        },
        filter: ['==', '$type', 'LineString']
    };

    const polygonLayerNoFiltradas = {
        id: 'polygon-layer-no-filtradas',
        type: 'fill',
        paint: {
            'fill-color': '#48ff00',
            'fill-opacity': 0.4,
            'fill-outline-color': '#48ff00'
        },
        filter: ['==', '$type', 'Polygon']
    };

    const polygonLayerAplicacionesAereas = {
        id: 'polygon-layer-aplicaciones-aereas',
        type: 'fill',
        paint: {
            'fill-color': '#00FF00',
            'fill-opacity': 0.5,
            'fill-outline-color': '#00FF00'
        },
        filter: ['all', ['==', '$type', 'Polygon'], ['!=', ['get', 'type'], 'overlap']]
    };

    const polygonLayerOverlap = {
        id: 'polygon-layer-overlap',
        type: 'fill',
        paint: {
            'fill-color': '#FF0000',
            'fill-opacity': 0.7,
            'fill-outline-color': '#FF0000'
        },
        filter: ['all', ['==', '$type', 'Polygon'], ['==', ['get', 'type'], 'overlap']]
    };

    const bufferPolygonLayer = {
        id: 'buffer-polygon-layer',
        type: 'fill',
        paint: {
            'fill-color': '#800080',
            'fill-opacity': 0.5,
            'fill-outline-color': '#800080'
        },
        filter: ['==', '$type', 'Polygon']
    };

    const bufferIntersectionPolygonLayer = {
        id: 'buffer-intersection-polygon-layer',
        type: 'fill',
        paint: {
            'fill-color': '#0000FF',
            'fill-opacity': 0.7,
            'fill-outline-color': '#0000FF'
        },
        filter: ['==', '$type', 'Polygon']
    };

    useEffect(() => {
        if (mapVisible && mapLoaded && mapRef.current) {
            const map = mapRef.current.getMap();
            map.resize();

            if (isConteoPalmas && imageUrl && imageCoordinates) {
                const lons = imageCoordinates.map(c => c[0]);
                const lats = imageCoordinates.map(c => c[1]);
                const minLon = Math.min(...lons);
                const maxLon = Math.max(...lons);
                const minLat = Math.min(...lats);
                const maxLat = Math.max(...lats);

                if ([minLon, maxLon, minLat, maxLat].every(coord => typeof coord === 'number' && !isNaN(coord))) {
                    map.fitBounds(
                        [
                            [minLon, minLat],
                            [maxLon, maxLat]
                        ],
                        { padding: 20, maxZoom: 18, duration: 0 }
                    );
                } else {
                    map.setCenter([0, 0]);
                    map.setZoom(2);
                }
            } else {
                let featuresCombined = [];
                if (currentGeojson?.features) featuresCombined.push(...currentGeojson.features);
                if (currentNoFiltradas?.features) featuresCombined.push(...currentNoFiltradas.features);
                if (currentFiltradas?.features) featuresCombined.push(...currentFiltradas.features);
                if (currentBuffers?.features) featuresCombined.push(...currentBuffers.features);
                if (currentBufferIntersections?.features) featuresCombined.push(...currentBufferIntersections.features);

                featuresCombined = featuresCombined.filter(feature => {
                    return feature.geometry && validGeometryTypes.includes(feature.geometry.type);
                });

                if (featuresCombined.length > 0) {
                    const combinedFc = {
                        type: 'FeatureCollection',
                        features: featuresCombined
                    };
                    const bbox = turf.bbox(combinedFc);
                    if (bbox && bbox.length === 4 && bbox.every(coord => typeof coord === 'number' && !isNaN(coord))) {
                        map.fitBounds(
                            [
                                [bbox[0], bbox[1]],
                                [bbox[2], bbox[3]],
                            ],
                            { padding: 20, maxZoom: 18, duration: 0 }
                        );
                    } else {
                        map.setCenter([0, 0]);
                        map.setZoom(2);
                    }
                } else {
                    map.setCenter([0, 0]);
                    map.setZoom(2);
                }
            }
        }
    }, [
        mapVisible,
        mapLoaded,
        currentGeojson,
        currentNoFiltradas,
        currentFiltradas,
        currentBuffers,
        currentBufferIntersections,
        isConteoPalmas,
        imageUrl,
        imageCoordinates
    ]);

    if (loading) {
        return (
            <ContentContainer>
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mt={2}>
                    <CircularProgress />
                    <Typography>Cargando análisis...</Typography>
                </Box>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button variant="contained" color="primary" onClick={onClose}>
                        Cerrar
                    </Button>
                </Box>
            </ContentContainer>
        );
    }

    if (!localAnalyses || Object.keys(localAnalyses).length === 0) {
        return (
            <ContentContainer>
                <Typography>No hay análisis disponibles.</Typography>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button variant="contained" color="primary" onClick={onClose}>
                        Cerrar
                    </Button>
                </Box>
            </ContentContainer>
        );
    }

    const formatDate = (dateString) => {
        if (!dateString) return 'Fecha desconocida';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Fecha desconocida';
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    const handleDownload = (signedUrl, fileName) => {
        if (!signedUrl || !fileName) return;
        const link = document.createElement('a');
        link.href = signedUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleCheckbox = (file) => {
        if (!file) return;
        onCheckboxChange(file);
    };

    const toggleMapPreview = async (archivo, section, event) => {
        if (!archivo) return;

        const isPalmCount = (section === "Conteo de palmas");
        const isAplicAereas = (section === "Aplicaciones aéreas");

        let currentFileName = 'unknown';
        if (isPalmCount) {
            currentFileName = archivo.file_name || archivo.signed_url || 'unknown_palmas';
        } else if (isAplicAereas) {
            currentFileName = archivo.key || 'unknown_aplicaciones';
        } else {
            currentFileName = archivo.file_name || archivo.signed_url || 'unknown';
        }

        const isCurrent = mapVisible && (
            (isConteoPalmas && imageUrl && imageUrl._fileName === currentFileName) ||
            (currentGeojson && currentGeojson._fileName === currentFileName) ||
            (currentNoFiltradas && currentNoFiltradas._fileName === currentFileName) ||
            (currentFiltradas && currentFiltradas._fileName === currentFileName) ||
            (currentBuffers && currentBuffers._fileName === currentFileName) ||
            (currentBufferIntersections && currentBufferIntersections._fileName === currentFileName)
        );

        if (isCurrent) {
            setMapVisible(false);
            setCurrentGeojson(null);
            setCurrentNoFiltradas(null);
            setCurrentFiltradas(null);
            setCurrentBuffers(null);
            setCurrentBufferIntersections(null);
            setImageUrl(null);
            setIsConteoPalmas(false);
            setImageCoordinates(null);
            setMapLoaded(false);
            return;
        }

        const rect = event.currentTarget.getBoundingClientRect();
        const previewLeft = rect.right + 10;
        const previewTop = rect.top + window.scrollY;
        setMapPosition({ top: previewTop, left: previewLeft });

        setLoadingGeojson(true);
        setMapVisible(true);
        setMapLoaded(false);
        setCurrentGeojson(null);
        setCurrentFiltradas(null);
        setCurrentNoFiltradas(null);
        setCurrentBuffers(null);
        setCurrentBufferIntersections(null);
        setImageUrl(null);
        setIsConteoPalmas(false);
        setImageCoordinates(null);

        try {
            if (isPalmCount) {
                const imageUrlValue = archivo.signed_url;
                setIsConteoPalmas(true);
                setImageUrl({ url: imageUrlValue, _fileName: currentFileName });

                const NWLat = parseFloat(archivo.bounds?.north_west_lat);
                const NWLon = parseFloat(archivo.bounds?.north_west_lon);
                const SELat = parseFloat(archivo.bounds?.south_east_lat);
                const SELon = parseFloat(archivo.bounds?.south_east_lon);

                if (
                    !isNaN(NWLat) && !isNaN(NWLon) &&
                    !isNaN(SELat) && !isNaN(SELon)
                ) {
                    const coords = [
                        [NWLon, NWLat],
                        [NWLon, SELat],
                        [SELon, SELat],
                        [SELon, NWLat]
                    ];
                    setImageCoordinates(coords);
                }
            } else if (isAplicAereas) {
                if (archivo.filtradas && archivo.filtradas.signed_url) {
                    const respFiltradas = await axios.get(archivo.filtradas.signed_url);
                    let fg = respFiltradas.data;
                    if (fg.type === 'Feature') {
                        fg = { type: 'FeatureCollection', features: [fg] };
                    } else if (fg.type !== 'FeatureCollection') {
                        fg = { type: 'FeatureCollection', features: [] };
                    }
                    fg._fileName = currentFileName;

                    fg.features = fg.features.filter(feature => feature.geometry && validGeometryTypes.includes(feature.geometry.type));

                    const bufferFeaturesFiltradas = fg.features.filter(f => f.properties?.type === 'buffer');
                    const bufferIntersectionFeaturesFiltradas = fg.features.filter(f => f.properties?.type === 'bufferIntersection' && f.geometry && f.geometry.type === 'Polygon');
                    const overlapFeaturesFiltradas = fg.features.filter(f => f.properties?.type === 'overlap' && f.geometry && f.geometry.type === 'Polygon');
                    const otherFeaturesFiltradas = fg.features.filter(f => f.properties?.type !== 'buffer' && f.properties?.type !== 'bufferIntersection' && f.properties?.type !== 'overlap');

                    const otherFiltradasGeojson = {
                        type: 'FeatureCollection',
                        features: otherFeaturesFiltradas,
                        _fileName: currentFileName
                    };
                    setCurrentFiltradas(otherFiltradasGeojson);

                    if (bufferFeaturesFiltradas.length > 0) {
                        const buffersGeojsonFiltradas = {
                            type: 'FeatureCollection',
                            features: bufferFeaturesFiltradas,
                            _fileName: currentFileName
                        };
                        setCurrentBuffers(buffersGeojsonFiltradas);
                    }

                    if (bufferIntersectionFeaturesFiltradas.length > 0) {
                        const bufferIntersectionsGeojsonFiltradas = {
                            type: 'FeatureCollection',
                            features: bufferIntersectionFeaturesFiltradas,
                            _fileName: currentFileName
                        };
                        setCurrentBufferIntersections(bufferIntersectionsGeojsonFiltradas);
                    }

                    if (overlapFeaturesFiltradas.length > 0) {
                        const overlapsGeojsonFiltradas = {
                            type: 'FeatureCollection',
                            features: overlapFeaturesFiltradas,
                            _fileName: currentFileName
                        };

                        setCurrentBufferIntersections(prev => prev ? {
                            ...prev,
                            features: [...prev.features, ...overlapFeaturesFiltradas]
                        } : overlapsGeojsonFiltradas);
                    }
                }

                if (archivo.no_filtradas && archivo.no_filtradas.signed_url) {
                    const respNoFiltradas = await axios.get(archivo.no_filtradas.signed_url);
                    let ng = respNoFiltradas.data;
                    if (ng.type === 'Feature') {
                        ng = { type: 'FeatureCollection', features: [ng] };
                    } else if (ng.type !== 'FeatureCollection') {
                        ng = { type: 'FeatureCollection', features: [] };
                    }
                    ng._fileName = currentFileName;

                    ng.features = ng.features.filter(feature => feature.geometry && validGeometryTypes.includes(feature.geometry.type));

                    const bufferFeaturesNoFiltradas = ng.features.filter(f => f.properties?.type === 'buffer');
                    const bufferIntersectionFeaturesNoFiltradas = ng.features.filter(f => f.properties?.type === 'bufferIntersection' && f.geometry && f.geometry.type === 'Polygon');
                    const overlapFeaturesNoFiltradas = ng.features.filter(f => f.properties?.type === 'overlap' && f.geometry && f.geometry.type === 'Polygon');
                    const otherFeaturesNoFiltradas = ng.features.filter(f => f.properties?.type !== 'buffer' && f.properties?.type !== 'bufferIntersection' && f.properties?.type !== 'overlap');

                    const otherNoFiltradasGeojson = {
                        type: 'FeatureCollection',
                        features: otherFeaturesNoFiltradas,
                        _fileName: currentFileName
                    };
                    setCurrentNoFiltradas(otherNoFiltradasGeojson);

                    if (bufferFeaturesNoFiltradas.length > 0) {
                        const buffersGeojsonNoFiltradas = {
                            type: 'FeatureCollection',
                            features: bufferFeaturesNoFiltradas,
                            _fileName: currentFileName
                        };
                        setCurrentBuffers(prevBuffers => prevBuffers ? {
                            ...prevBuffers,
                            features: [...prevBuffers.features, ...bufferFeaturesNoFiltradas]
                        } : buffersGeojsonNoFiltradas);
                    }

                    if (bufferIntersectionFeaturesNoFiltradas.length > 0) {
                        const bufferIntersectionsGeojsonNoFiltradas = {
                            type: 'FeatureCollection',
                            features: bufferIntersectionFeaturesNoFiltradas,
                            _fileName: currentFileName
                        };
                        setCurrentBufferIntersections(prevBufferIntersections => prevBufferIntersections ? {
                            ...prevBufferIntersections,
                            features: [...prevBufferIntersections.features, ...bufferIntersectionFeaturesNoFiltradas]
                        } : bufferIntersectionsGeojsonNoFiltradas);
                    }

                    if (overlapFeaturesNoFiltradas.length > 0) {
                        const overlapsGeojsonNoFiltradas = {
                            type: 'FeatureCollection',
                            features: overlapFeaturesNoFiltradas,
                            _fileName: currentFileName
                        };

                        setCurrentBufferIntersections(prev => prev ? {
                            ...prev,
                            features: [...prev.features, ...overlapFeaturesNoFiltradas]
                        } : overlapsGeojsonNoFiltradas);
                    }
                }

                if (!archivo.filtradas && !archivo.no_filtradas) {
                    setCurrentNoFiltradas({
                        type: 'FeatureCollection',
                        features: [],
                        _fileName: 'empty'
                    });
                }
            } else {
                if (archivo.signed_url) {
                    const resp = await axios.get(archivo.signed_url);
                    let data = resp.data;

                    if (data.type === 'Feature') {
                        data = { type: 'FeatureCollection', features: [data] };
                    } else if (data.type !== 'FeatureCollection') {
                        data = { type: 'FeatureCollection', features: [] };
                    }

                    data._fileName = currentFileName;

                    data.features = data.features.filter(feature => feature.geometry && validGeometryTypes.includes(feature.geometry.type));

                    const bufferFeatures = data.features.filter(f => f.properties?.type === 'buffer');
                    const bufferIntersectionFeatures = data.features.filter(f => f.properties?.type === 'bufferIntersection' && f.geometry && f.geometry.type === 'Polygon');
                    const overlapFeatures = data.features.filter(f => f.properties?.type === 'overlap' && f.geometry && f.geometry.type === 'Polygon');
                    const otherFeatures = data.features.filter(f => f.properties?.type !== 'buffer' && f.properties?.type !== 'bufferIntersection' && f.properties?.type !== 'overlap');

                    if (otherFeatures.length > 0) {
                        const otherGeojson = {
                            type: 'FeatureCollection',
                            features: otherFeatures,
                            _fileName: currentFileName
                        };
                        setCurrentGeojson(otherGeojson);
                    }

                    if (bufferFeatures.length > 0) {
                        const buffersGeojson = {
                            type: 'FeatureCollection',
                            features: bufferFeatures,
                            _fileName: currentFileName
                        };
                        setCurrentBuffers(buffersGeojson);
                    }

                    if (bufferIntersectionFeatures.length > 0) {
                        const bufferIntersectionsGeojson = {
                            type: 'FeatureCollection',
                            features: bufferIntersectionFeatures,
                            _fileName: currentFileName
                        };
                        setCurrentBufferIntersections(bufferIntersectionsGeojson);
                    }

                    if (overlapFeatures.length > 0) {
                        const overlapsGeojson = {
                            type: 'FeatureCollection',
                            features: overlapFeatures,
                            _fileName: currentFileName
                        };

                        setCurrentBufferIntersections(prev => prev ? {
                            ...prev,
                            features: [...prev.features, ...overlapFeatures]
                        } : overlapsGeojson);
                    }

                    if (otherFeatures.length === 0 && bufferFeatures.length === 0 && bufferIntersectionFeatures.length === 0 && overlapFeatures.length === 0) {
                        setCurrentGeojson({
                            type: 'FeatureCollection',
                            features: [],
                            _fileName: 'empty'
                        });
                    }
                } else {
                    setCurrentGeojson({
                        type: 'FeatureCollection',
                        features: [],
                        _fileName: 'empty'
                    });
                }
            }
        } catch (error) {
            console.error('Error al cargar datos:', error);
            setIsConteoPalmas(false);
            setImageUrl(null);
            setCurrentGeojson({ type: 'FeatureCollection', features: [], _fileName: 'empty' });
            setCurrentNoFiltradas(null);
            setCurrentFiltradas(null);
            setCurrentBuffers(null);
            setCurrentBufferIntersections(null);
            setImageCoordinates(null);
        } finally {
            setLoadingGeojson(false);
        }
    };

    const handleDeleteClick = (section) => {
        const files = localAnalyses[section];
        const filesToDelete = [];

        if (section === 'Aplicaciones aéreas') {
            files.forEach(item => {
                if (item.filtradas && selectedFiles.some(s => s.file_name === item.filtradas.file_name)) {
                    filesToDelete.push(item.filtradas);
                }
                if (item.no_filtradas && selectedFiles.some(s => s.file_name === item.no_filtradas.file_name)) {
                    filesToDelete.push(item.no_filtradas);
                }
            });
        } else {
            files.forEach(file => {
                if (selectedFiles.some(s => s.file_name === file.file_name)) {
                    filesToDelete.push(file);
                }
            });
        }

        if (filesToDelete.length > 0) {
            setAnalysesToDelete(filesToDelete);
            setDeleteDialogOpen(true);
        }
    };

    const handleDeleteConfirm = async () => {
        if (!analysesToDelete || analysesToDelete.length === 0) return;
        setDeleting(true);

        try {
            const deletePromises = analysesToDelete.map(file => {
                const id = file.analisis_id || file._id || (file.key ? file.key.split('_')[0] : null);
                if (!id) {
                    throw new Error(`ID de análisis no encontrado para el archivo: ${file.file_name}`);
                }
                return axios.delete(`${API_BASE_PYTHON_SERVICE}mapping/analisis/${id}`);
            });

            const responses = await Promise.all(deletePromises);
            const success = responses.every(response => response.data.status === 'success');

            if (success) {
                setLocalAnalyses(prev => {
                    const updated = { ...prev };
                    analysesToDelete.forEach(file => {
                        const section = Object.keys(updated).find(sec => {
                            if (sec === 'Aplicaciones aéreas') {
                                return updated[sec].some(item => item.filtradas?.file_name === file.file_name || item.no_filtradas?.file_name === file.file_name);
                            } else {
                                return updated[sec].some(f => f.file_name === file.file_name);
                            }
                        });

                        if (section) {
                            if (section === 'Aplicaciones aéreas') {
                                updated[section] = updated[section].filter(item => {
                                    if (item.filtradas && item.filtradas.file_name === file.file_name) return false;
                                    if (item.no_filtradas && item.no_filtradas.file_name === file.file_name) return false;
                                    return true;
                                });
                            } else {
                                updated[section] = updated[section].filter(f => f.file_name !== file.file_name);
                            }
                        }
                    });
                    return updated;
                });

                toast.success('Análisis eliminados exitosamente.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Error al eliminar algunos análisis.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error('Error al eliminar los análisis:', error);
            toast.error('Ocurrió un error al eliminar los análisis.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setDeleting(false);
            setDeleteDialogOpen(false);
            setAnalysesToDelete([]);
        }
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setAnalysesToDelete([]);
    };

    const isAllSelected = (section) => {
        const files = localAnalyses[section];
        if (section === 'Aplicaciones aéreas') {
            return files.every(item => {
                const filtradasSelected = item.filtradas ? selectedFiles.some(s => s.file_name === item.filtradas.file_name) : true;
                const noFiltradasSelected = item.no_filtradas ? selectedFiles.some(s => s.file_name === item.no_filtradas.file_name) : true;
                return filtradasSelected && noFiltradasSelected;
            });
        } else {
            return files.every(file => selectedFiles.some(s => s.file_name === file.file_name));
        }
    };

    const handleSelectAllChange = (section) => (event) => {
        const checked = event.target.checked;
        const files = localAnalyses[section];

        if (checked) {
            if (section === 'Aplicaciones aéreas') {
                files.forEach(item => {
                    if (item.filtradas && !selectedFiles.some(s => s.file_name === item.filtradas.file_name)) onCheckboxChange(item.filtradas);
                    if (item.no_filtradas && !selectedFiles.some(s => s.file_name === item.no_filtradas.file_name)) onCheckboxChange(item.no_filtradas);
                });
            } else {
                files.forEach(file => {
                    if (!selectedFiles.some(s => s.file_name === file.file_name)) onCheckboxChange(file);
                });
            }
        } else {
            if (section === 'Aplicaciones aéreas') {
                files.forEach(item => {
                    if (item.filtradas) onCheckboxChange(item.filtradas);
                    if (item.no_filtradas) onCheckboxChange(item.no_filtradas);
                });
            } else {
                files.forEach(file => {
                    onCheckboxChange(file);
                });
            }
        }
    };

    return (
        <ContentContainer>
            <Box flexGrow={1} overflow="hidden">
                {Object.keys(localAnalyses).map((section, index) => {
                    const sectionCount = Array.isArray(localAnalyses[section]) ? localAnalyses[section].length : 0;
                    const isExpanded = expandedSection === section;
                    const allSelected = isAllSelected(section);
                    const isIndeterminate = !allSelected && selectedFiles.some(file => {
                        if (section === 'Aplicaciones aéreas') {
                            return localAnalyses[section].some(item =>
                                (item.filtradas && file.file_name === item.filtradas.file_name) ||
                                (item.no_filtradas && file.file_name === item.no_filtradas.file_name)
                            );
                        } else {
                            return localAnalyses[section].some(item => file.file_name === item.file_name);
                        }
                    });
                    return (
                        <Accordion
                            key={index}
                            expanded={isExpanded}
                            onChange={() => setExpandedSection(isExpanded ? false : section)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel-content-${index}`}
                                id={`panel-header-${index}`}
                            >
                                <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
                                    <Box display="flex" alignItems="center">
                                        <Checkbox
                                            checked={allSelected}
                                            indeterminate={isIndeterminate}
                                            onChange={handleSelectAllChange(section)}
                                            inputProps={{ 'aria-label': `Seleccionar todos en ${section}` }}
                                            size="small"
                                        />
                                        <Typography variant="h6" fontWeight="bold" style={{ marginLeft: '8px' }}>
                                            {section}
                                        </Typography>
                                        {loading ? (
                                            <Box sx={{ display: 'inline-flex', alignItems: 'center', marginLeft: '8px' }}>
                                                <CircularProgress size={16} />
                                            </Box>
                                        ) : (
                                            <Chip
                                                label={sectionCount}
                                                size="small"
                                                style={{ marginLeft: '8px' }}
                                                color="primary"
                                            />
                                        )}
                                    </Box>
                                    <Tooltip title="Eliminar seleccionados">
                                        <span>
                                            <IconButton
                                                onClick={() => handleDeleteClick(section)}
                                                color="secondary"
                                                disabled={
                                                    section === 'Aplicaciones aéreas'
                                                        ? !localAnalyses[section].some(item =>
                                                            (item.filtradas && selectedFiles.some(s => s.file_name === item.filtradas.file_name)) ||
                                                            (item.no_filtradas && selectedFiles.some(s => s.file_name === item.no_filtradas.file_name))
                                                        )
                                                        : !localAnalyses[section].some(file => selectedFiles.some(s => s.file_name === file.file_name))
                                                }
                                                size="small"
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                {sectionCount === 0 ? (
                                    <Typography>No hay archivos en esta sección.</Typography>
                                ) : (
                                    <ScrollableSection>
                                        <List>
                                            {localAnalyses[section].map((archivo, fileIdx) => {
                                                const isAplicAereas = (section === "Aplicaciones aéreas");
                                                const isPalmCount = (section === "Conteo de palmas");

                                                let fileNameToCheck, updatedDate, analysisId;
                                                if (isAplicAereas) {
                                                    fileNameToCheck = archivo.key || 'unknown_aplicaciones';
                                                    updatedDate = formatDate(archivo.updated);
                                                    analysisId = archivo.key;
                                                } else {
                                                    const fn = archivo.file_name || archivo.signed_url || 'unknown';
                                                    fileNameToCheck = fn;
                                                    updatedDate = formatDate(archivo.updated);
                                                    analysisId = archivo.analisis_id || archivo._id;
                                                }

                                                if (isAplicAereas) {
                                                    const hasTiffFiltradas = archivo.filtradas && isTiff(archivo.filtradas.file_name);
                                                    const hasTiffNoFiltradas = archivo.no_filtradas && isTiff(archivo.no_filtradas.file_name);
                                                    if (hasTiffFiltradas || hasTiffNoFiltradas) {
                                                        return null;
                                                    }
                                                } else {
                                                    if (isTiff(fileNameToCheck)) {
                                                        return null;
                                                    }
                                                }

                                                const isChecked = (() => {
                                                    if (isAplicAereas) {
                                                        let checked = true;
                                                        if (archivo.filtradas) checked = checked && selectedFiles.some(s => s.file_name === archivo.filtradas.file_name);
                                                        if (archivo.no_filtradas) checked = checked && selectedFiles.some(s => s.file_name === archivo.no_filtradas.file_name);
                                                        return checked;
                                                    } else {
                                                        return selectedFiles.some(selected => selected.file_name === archivo.file_name);
                                                    }
                                                })();

                                                return (
                                                    <ListItem
                                                        key={fileIdx}
                                                        divider
                                                        style={{ cursor: 'default', paddingLeft: '8px', paddingRight: '8px' }}
                                                    >
                                                        <Checkbox
                                                            edge="start"
                                                            checked={isChecked}
                                                            onChange={() => {
                                                                if (isAplicAereas) {
                                                                    if (archivo.filtradas) handleCheckbox(archivo.filtradas);
                                                                    if (archivo.no_filtradas) handleCheckbox(archivo.no_filtradas);
                                                                } else {
                                                                    handleCheckbox(archivo);
                                                                }
                                                            }}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            size="small"
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2" color="textSecondary">
                                                                    {updatedDate}
                                                                </Typography>
                                                            }
                                                        />
                                                        <Box display="flex" alignItems="center">
                                                            <IconButton
                                                                onClick={(e) => toggleMapPreview(archivo, section, e)}
                                                                color="primary"
                                                                size="small"
                                                                style={{ marginRight: '4px' }}
                                                            >
                                                                {mapVisible && (
                                                                    (isAplicAereas && currentGeojson?._fileName === archivo.key) ||
                                                                    (archivo.filtradas && currentFiltradas?._fileName === archivo.key) ||
                                                                    (archivo.no_filtradas && currentNoFiltradas?._fileName === archivo.key) ||
                                                                    (currentGeojson && currentGeojson._fileName === fileNameToCheck) ||
                                                                    (currentBuffers && currentBuffers._fileName === fileNameToCheck) ||
                                                                    (currentBufferIntersections && currentBufferIntersections._fileName === fileNameToCheck) ||
                                                                    (isPalmCount && imageUrl && imageUrl._fileName === fileNameToCheck)
                                                                ) ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
                                                            </IconButton>
                                                            {isAplicAereas ? (
                                                                <>
                                                                    {archivo.filtradas && archivo.filtradas.file_name && (
                                                                        <Tooltip title="Ruta de vuelo filtrada">
                                                                            <DownloadButton
                                                                                color="primary"
                                                                                onClick={() =>
                                                                                    handleDownload(
                                                                                        archivo.filtradas.signed_url,
                                                                                        archivo.filtradas.file_name ? archivo.filtradas.file_name.split('/').pop() : 'filtradas.geojson'
                                                                                    )
                                                                                }
                                                                                size="small"
                                                                                style={{ marginRight: '4px' }}
                                                                            >
                                                                                <DownloadIcon fontSize="small" />
                                                                            </DownloadButton>
                                                                        </Tooltip>
                                                                    )}
                                                                    {archivo.no_filtradas && archivo.no_filtradas.file_name && (
                                                                        <Tooltip title="Ruta de vuelo no filtrada">
                                                                            <DownloadButton
                                                                                color="primary"
                                                                                onClick={() =>
                                                                                    handleDownload(
                                                                                        archivo.no_filtradas.signed_url,
                                                                                        archivo.no_filtradas.file_name ? archivo.no_filtradas.file_name.split('/').pop() : 'no_filtradas.geojson'
                                                                                    )
                                                                                }
                                                                                size="small"
                                                                                style={{ marginRight: '4px' }}
                                                                            >
                                                                                <DownloadIcon fontSize="small" />
                                                                            </DownloadButton>
                                                                        </Tooltip>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {archivo.file_name && !isTiff(archivo.file_name) && (
                                                                        <Tooltip title="Ruta">
                                                                            <DownloadButton
                                                                                color="primary"
                                                                                onClick={() =>
                                                                                    handleDownload(
                                                                                        archivo.signed_url,
                                                                                        archivo.file_name ? archivo.file_name.split('/').pop() : 'archivo.geojson'
                                                                                    )
                                                                                }
                                                                                size="small"
                                                                                style={{ marginRight: '4px' }}
                                                                            >
                                                                                <DownloadIcon fontSize="small" />
                                                                            </DownloadButton>
                                                                        </Tooltip>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Box>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </ScrollableSection>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button variant="contained" color="primary" onClick={onClose}>
                    Cerrar
                </Button>
            </Box>

            {mapVisible && (currentGeojson || imageUrl || currentNoFiltradas || currentFiltradas || currentBuffers || currentBufferIntersections || loadingGeojson) ? (
                <MapPreviewContainer top={mapPosition.top} left={mapPosition.left} visible={true}>
                    {/* Controles de leyenda para buffers y rutas de vuelo no filtradas */}
                    <Box
                        position="absolute"
                        top={10}
                        left={10}
                        display="flex"
                        flexDirection="column"
                        zIndex={10}
                        bgcolor="rgba(255, 255, 255, 0.8)"
                        borderRadius="4px"
                        padding="4px"
                    >
                        <Tooltip title={showBuffers ? "Ocultar Buffers" : "Mostrar Buffers"}>
                            <IconButton
                                onClick={() => setShowBuffers(prev => !prev)}
                                size="small"
                                style={{ margin: '2px' }}
                            >
                                <span
                                    style={{
                                        display: 'block',
                                        width: '12px',
                                        height: '12px',
                                        backgroundColor: showBuffers ? '#800080' : '#cccccc',
                                        border: '1px solid #000',
                                        borderRadius: '2px'
                                    }}
                                ></span>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={showNoFiltradasRoutes ? "Ocultar Rutas de Vuelo No Filtradas" : "Mostrar Rutas de Vuelo No Filtradas"}>
                            <IconButton
                                onClick={() => setShowNoFiltradasRoutes(prev => !prev)}
                                size="small"
                                style={{ margin: '2px' }}
                            >
                                <span
                                    style={{
                                        display: 'block',
                                        width: '12px',
                                        height: '12px',
                                        backgroundColor: showNoFiltradasRoutes ? '#888888' : '#cccccc',
                                        border: '1px solid #000',
                                        borderRadius: '2px'
                                    }}
                                ></span>
                            </IconButton>
                        </Tooltip>
                    </Box>

                    {loadingGeojson || (
                        !currentGeojson &&
                        !imageUrl &&
                        !currentNoFiltradas &&
                        !currentFiltradas &&
                        !currentBuffers &&
                        !currentBufferIntersections
                    ) ? (
                        <Box
                            width="100%"
                            height="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            bgcolor="#fff"
                        >
                            <CircularProgress size={30} />
                        </Box>
                    ) : (
                        <Map
                            key={(currentGeojson && currentGeojson._fileName) ||
                                (imageUrl && imageUrl._fileName) ||
                                (currentNoFiltradas && currentNoFiltradas._fileName) ||
                                (currentFiltradas && currentFiltradas._fileName) ||
                                (currentBuffers && currentBuffers._fileName) ||
                                (currentBufferIntersections && currentBufferIntersections._fileName) ||
                                'mapKey'}
                            initialViewState={{
                                longitude: 0,
                                latitude: 0,
                                zoom: 2,
                            }}
                            mapStyle={mapStyle}
                            mapboxAccessToken="pk.eyJ1IjoiamRtYW4iLCJhIjoiY20xNGpkN2d0MHlsejJ4cTA1Z3lwdGNhMCJ9.O5UY8q02UPPoCCMrobQ0sA"
                            style={{ height: '100%', width: '100%' }}
                            ref={mapRef}
                            onLoad={() => setMapLoaded(true)}
                            interactive={true}
                        >
                            <NavigationControl position="top-right" />

                            {isConteoPalmas && imageUrl && imageCoordinates && (
                                <Source
                                    id="image-overlay"
                                    type="image"
                                    url={imageUrl.url}
                                    coordinates={imageCoordinates}
                                >
                                    <Layer
                                        id="image-overlay-layer"
                                        type="raster"
                                        paint={{
                                            'raster-opacity': 1,
                                        }}
                                    />
                                </Source>
                            )}

                            {/* Rutas de vuelo no filtradas (líneas grises) */}
                            {(!isConteoPalmas && currentNoFiltradas && showNoFiltradasRoutes) && (
                                <Source id="preview-geojson-no-filtradas" type="geojson" data={currentNoFiltradas}>
                                    <Layer {...polygonLayerNoFiltradas} />
                                    <Layer {...lineLayerNoFiltradas} />
                                    <Layer {...pointLayerNoFiltradas} />
                                </Source>
                            )}

                            {/* Rutas de vuelo filtradas (líneas rojas) siempre visibles */}
                            {(!isConteoPalmas && currentFiltradas) && (
                                <Source id="preview-geojson-filtradas" type="geojson" data={currentFiltradas}>
                                    <Layer {...polygonLayerAplicacionesAereas} />
                                    <Layer {...lineLayerFiltradas} />
                                    <Layer {...pointLayer} />
                                </Source>
                            )}

                            {/* GeoJSON general (polígonos verdes y overlaps rojos) */}
                            {(!isConteoPalmas && currentGeojson && currentGeojson.features.length > 0) && (
                                <Source id="preview-geojson" type="geojson" data={currentGeojson}>
                                    <Layer {...polygonLayerAplicacionesAereas} />
                                    <Layer {...polygonLayerOverlap} />
                                    <Layer {...lineLayerFiltradas} />
                                    <Layer {...pointLayer} />
                                </Source>
                            )}

                            {/* Buffers */}
                            {(!isConteoPalmas && currentBuffers && currentBuffers.features.length > 0 && showBuffers) && (
                                <Source id="preview-geojson-buffers" type="geojson" data={currentBuffers}>
                                    <Layer {...bufferPolygonLayer} />
                                </Source>
                            )}

                            {/* Buffer Intersections */}
                            {(!isConteoPalmas && currentBufferIntersections && currentBufferIntersections.features.length > 0 && showBuffers) && (
                                <Source id="preview-geojson-buffer-intersections" type="geojson" data={currentBufferIntersections}>
                                    <Layer {...bufferIntersectionPolygonLayer} />
                                </Source>
                            )}

                        </Map>
                    )}
                </MapPreviewContainer>
            ) : null}

            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">Confirmar Eliminación</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        ¿Estás seguro de que deseas eliminar {analysesToDelete.length > 1 ? 'los análisis seleccionados' : 'este análisis'}? Esta acción no se puede deshacer.
                    </DialogContentText>
                    {analysesToDelete.length > 0 && (
                        <List>
                            {analysesToDelete.map((file, idx) => (
                                <ListItem key={idx}>
                                    <ListItemText primary={file.file_name || 'Archivo desconocido'} />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary" disabled={deleting}>
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="secondary" disabled={deleting}>
                        {deleting ? <CircularProgress size={24} /> : 'Eliminar'}
                    </Button>
                </DialogActions>
            </Dialog>
        </ContentContainer>
    );
};

export default HistorialContent;
