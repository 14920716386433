// DataSection.jsx
import React, { useEffect } from 'react';
import {
    DataSectionContainer,
    TableContainer,
    TableHeader,
    StyledTable,
    TableCell,
    TableHeaderCell,
} from './DataSectionStyle';

import { processApsData } from "../../../analysis/aps/apsFetchData";
import { validateApsData } from "../../../analysis/aps/apsValidations";
import { renderApsDataCards } from "../../../analysis/aps/apsDataCards";

import { processCosechaMecanicaData } from "../../../analysis/cosechaMecanica/cosechaMecanicaFetchData";
import { validateCosechaMecanicaData } from "../../../analysis/cosechaMecanica/cosechaMecanicaValidations";
import { renderCosechaMecanicaDataCards } from "../../../analysis/cosechaMecanica/cosechaMecanicaDataCards";

import { validateConteoPalmasData } from "../../../analysis/conteoPalmas/conteoPalmasValidation";
import { renderConteoPalmasDataCards } from "../../../analysis/conteoPalmas/conteoPalmasDataCards";

import { validateConteoAgavesData } from "../../../analysis/conteoAgave/conteoAgaveValidation";
import { processConteoAgavesData } from "../../../analysis/conteoAgave/conteoAgaveFetch";
import { displayValue } from "../../../utils/generalUtils";
import { processConteoPalmasData } from "../../../analysis/conteoPalmas/conteoPalmasFetch";
import { renderConteoAgaveDataCards } from "../../../analysis/conteoAgave/conteoAgaveDataCards";

const DataSection = ({
                         selectedAnalysisType,
                         promedioAltura,
                         areaSobreAplicada,
                         areaAplicada,
                         areaFueraDeLote,
                         areaSobreCalles,
                         porcentajeVariacion,
                         promedioVelocidad,
                         areaBrutaCm,
                         eficienciaCm,
                         promedioVelocidadCm,
                         porcentajeAreaPilotoCm,
                         porcentajeAreaAutoTrackerCm,
                         porcentajeModoCortadorBaseCm,
                         esValorValido,
                         setIndicadores,
                         datosAnalisis,
                         conteoObjetos,
                         areaNoAplicada,
                         onAreasCalculatedLoader,
                         dosisTotalRealAplicada,
                         diferenciaDeAreaApsKml
                     }) => {
    useEffect(() => {
        if (datosAnalisis && Object.keys(datosAnalisis).length > 0) {
            let datos = {};
            switch (selectedAnalysisType) {
                case 'APLICACIONES_AEREAS':
                    validateApsData(datosAnalisis);
                    datos = processApsData(datosAnalisis, {
                        promedioAltura,
                        areaSobreAplicada,
                        areaAplicada,
                        areaNoAplicada,
                        areaFueraDeLote,
                        areaSobreCalles,
                        porcentajeVariacion,
                        promedioVelocidad,
                        diferenciaDeAreaApsKml,
                        dosisTotalRealAplicada
                    });
                    break;
                case 'COSECHA_MECANICA':
                    validateCosechaMecanicaData(datosAnalisis);
                    datos = processCosechaMecanicaData(datosAnalisis, {
                        areaBrutaCm,
                        eficienciaCm,
                        promedioVelocidadCm,
                        porcentajeAreaPilotoCm,
                        porcentajeAreaAutoTrackerCm,
                        porcentajeModoCortadorBaseCm,
                    });
                    break;
                case 'CONTEO_PALMA':
                    validateConteoPalmasData(datosAnalisis);
                    datos = processConteoPalmasData(datosAnalisis, {});
                    break;
                case 'CONTEO_AGAVE':
                    validateConteoAgavesData(datosAnalisis);
                    datos = processConteoAgavesData(datosAnalisis, {});
                    break;
                default:
                    datos = {};
                    break;
            }

            setIndicadores(datos);
        }
    }, [
        selectedAnalysisType,
        datosAnalisis,
        promedioAltura,
        areaSobreAplicada,
        areaAplicada,
        areaNoAplicada,
        areaFueraDeLote,
        areaSobreCalles,
        porcentajeVariacion,
        promedioVelocidad,
        areaBrutaCm,
        eficienciaCm,
        promedioVelocidadCm,
        porcentajeAreaPilotoCm,
        porcentajeAreaAutoTrackerCm,
        porcentajeModoCortadorBaseCm,
        diferenciaDeAreaApsKml,
        dosisTotalRealAplicada,
        setIndicadores
    ]);

    const tableDetails = {
        responsable: displayValue(datosAnalisis.nombreResponsable?.valor),
        fechaInicio: displayValue(datosAnalisis.fechaInicio?.valor || datosAnalisis.fecha?.valor),
        fechaFin: displayValue(datosAnalisis.fechaFin?.valor),
        nombreFinca: displayValue(datosAnalisis.nombreFinca?.valor),
        operador: displayValue(datosAnalisis.nombreOperador?.valor || datosAnalisis.operador?.valor),
        actividad: displayValue(datosAnalisis.actividad?.valor),
    };

    const renderDataCards = () => {
        switch (selectedAnalysisType) {
            case 'APLICACIONES_AEREAS':
                return renderApsDataCards(
                    validateApsData(datosAnalisis),
                    datosAnalisis,
                    {
                        promedioAltura,
                        areaSobreAplicada,
                        areaAplicada,
                        areaNoAplicada,
                        areaFueraDeLote,
                        areaSobreCalles,
                        porcentajeVariacion,
                        promedioVelocidad,
                        diferenciaDeAreaApsKml,
                        dosisTotalRealAplicada
                    },
                    onAreasCalculatedLoader
                );

            case 'COSECHA_MECANICA':
                console.log("DATOS ANALISIS: ", datosAnalisis);
                return renderCosechaMecanicaDataCards(
                    validateCosechaMecanicaData(datosAnalisis),
                    datosAnalisis,
                    {
                        areaBrutaCm,
                        eficienciaCm,
                        promedioVelocidadCm,
                        porcentajeAreaPilotoCm,
                        porcentajeAreaAutoTrackerCm,
                        porcentajeModoCortadorBaseCm,
                    }
                );

            case 'CONTEO_PALMA':
                return renderConteoPalmasDataCards(
                    validateConteoPalmasData(datosAnalisis),
                    datosAnalisis,
                    {
                        conteoObjetos
                    }
                );

            case 'CONTEO_AGAVE':
                return renderConteoAgaveDataCards(
                    validateConteoAgavesData(datosAnalisis),
                    datosAnalisis,
                    {
                        conteoObjetos
                    }
                );

            default:
                return null;
        }
    };

    return (
        <DataSectionContainer>
            {selectedAnalysisType !== 'CONTEO_PALMA' && selectedAnalysisType !== 'CONTEO_AGAVE' && (
                <TableContainer>
                    <TableHeader>Detalles</TableHeader>
                    <StyledTable>
                        <thead>
                        <tr>
                            <TableHeaderCell>Responsable</TableHeaderCell>
                            <TableHeaderCell>Fecha Inicio</TableHeaderCell>
                            <TableHeaderCell>Fecha Fin</TableHeaderCell>
                            <TableHeaderCell>Nombre Finca</TableHeaderCell>
                            <TableHeaderCell>Operador</TableHeaderCell>
                            <TableHeaderCell>Actividad</TableHeaderCell>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <TableCell>{tableDetails.responsable}</TableCell>
                            <TableCell>{tableDetails.fechaInicio}</TableCell>
                            <TableCell>{tableDetails.fechaFin}</TableCell>
                            <TableCell>{tableDetails.nombreFinca}</TableCell>
                            <TableCell>{tableDetails.operador}</TableCell>
                            <TableCell>{tableDetails.actividad}</TableCell>
                        </tr>
                        </tbody>
                    </StyledTable>
                </TableContainer>
            )}
            {renderDataCards()}
        </DataSectionContainer>
    );
};

export default DataSection;
