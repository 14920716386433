import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import Map, { Source, Layer, NavigationControl, Popup } from 'react-map-gl';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import HomeIcon from '@mui/icons-material/Home';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LayersIcon from '@mui/icons-material/Layers';
import { styled } from '@mui/system';
import axios from 'axios';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    marginTop: theme.spacing(1),
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
        backgroundColor: 'lightgray',
    },
    width: 36,
    right: 50,
    height: 36,
}));

const CommonMap = (props) => {
    const {
        center = [14.634915, -90.506882],
        zoom = 8,
        polygons = [],
        lines = [],
        points = [],
        areasSuperpuestas = [],
        nonIntersectedAreas = [],
        bufferedLines = [],
        bufferedIntersections = [],
        onLineHover = () => {},
        onLineMouseOut = () => {},
        onLineClick = () => {},
        polygonProperties = [],
        showIntersections = true,
        mapRef,
        polygonsData = [],
        highlightedLote = null,
        activeLotes = [],
        onSelectLote = () => {},
        onHoverLote = () => {},
        onLeaveLote = () => {},
        imageUrl = '',
        northWestCoords,
        southEastCoords,
        filteredColors,
        selectedLine,
        getLineColor,
        lineasNoFiltradas = [],
        popupInfo,
        setPopupInfo,
        selectedFiles,
        isEditing
    } = props;

    const localMapRef = useRef(null);

    const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v12');
    const [selectedStyle, setSelectedStyle] = useState(() => {
        return localStorage.getItem('selectedMapStyle') || 'Satellite View (No Details)';
    });

    const [mapLoaded, setMapLoaded] = useState(false);
    const [selectedPopup, setSelectedPopup] = useState(null);
    const [hasWarnedInvalidCoords, setHasWarnedInvalidCoords] = useState(false);

    const [selectedPolygonId, setSelectedPolygonId] = useState(null);

    const mapStyles = {
        'Satellite View (No Details)': 'mapbox://styles/mapbox/satellite-v9',
        'Street Map': 'mapbox://styles/mapbox/streets-v12',
        'Terrain Map': 'mapbox://styles/mapbox/terrain-v2',
        'Outdoors Map': 'mapbox://styles/mapbox/outdoors-v12',
        'Satellite Streets': 'mapbox://styles/mapbox/satellite-streets-v12',
        'Light Map': 'mapbox://styles/mapbox/light-v10',
    };

    useEffect(() => {
        try {
            if (mapStyles[selectedStyle]) {
                setMapStyle(mapStyles[selectedStyle]);
                localStorage.setItem('selectedMapStyle', selectedStyle);
            } else {
                console.warn(`El estilo "${selectedStyle}" no está definido en mapStyles.`);
            }
        } catch (error) {
            console.error('Error al actualizar el estilo del mapa:', error);
        }
    }, [selectedStyle]);

    const handleMapLoad = useCallback(() => {
        try {
            setMapLoaded(true);
            if (mapRef && localMapRef.current) {
                mapRef.current = localMapRef.current.getMap();
            }
        } catch (error) {
            console.error('Error en handleMapLoad:', error);
        }
    }, [mapRef]);

    const isTiled = useMemo(() => {
        if (!imageUrl) return false;
        return imageUrl.includes('{z}') && imageUrl.includes('{x}') && imageUrl.includes('{y}');
    }, [imageUrl]);

    const getPolygonFeatures = useCallback((dataArray) => {
        try {
            if (!Array.isArray(dataArray)) {
                console.warn('getPolygonFeatures: dataArray no es un arreglo.');
                return [];
            }

            const features = dataArray
                .map((item) => {
                    try {
                        if (item && item.geometry && item.geometry.type && item.geometry.coordinates) {
                            let geometry = item.geometry;
                            if (geometry.type === 'Polygon') {
                                const coordinates = geometry.coordinates.map((ring) =>
                                    ring
                                        .map((coord) => (Array.isArray(coord) && coord.length >= 2 ? [coord[0], coord[1]] : null))
                                        .filter((c) => c !== null)
                                ).filter((ring) => ring.length > 0);

                                return {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Polygon',
                                        coordinates: coordinates,
                                    },
                                    properties: item.properties || {},
                                };
                            } else if (geometry.type === 'MultiPolygon') {
                                const coordinates = geometry.coordinates
                                    .map((polygon) =>
                                        polygon
                                            .map((ring) =>
                                                ring.map((coord) =>
                                                    Array.isArray(coord) && coord.length >= 2 ? [coord[0], coord[1]] : null
                                                ).filter((c) => c !== null)
                                            )
                                            .filter((ring) => ring.length > 0)
                                    )
                                    .filter((polygon) => polygon.length > 0);

                                return {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'MultiPolygon',
                                        coordinates: coordinates,
                                    },
                                    properties: item.properties || {},
                                };
                            } else {
                                console.warn(`getPolygonFeatures: Tipo de geometría "${geometry.type}" no soportado.`);
                                return null;
                            }
                        } else if (Array.isArray(item)) {
                            const coordinates = item
                                .map((coord) => (Array.isArray(coord) && coord.length >= 2 ? [coord[0], coord[1]] : null))
                                .filter((c) => c !== null);
                            if (coordinates.length > 0) {
                                return {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Polygon',
                                        coordinates: [coordinates],
                                    },
                                    properties: {},
                                };
                            }
                            return null;
                        } else {
                            console.warn('getPolygonFeatures: Item inválido.', item);
                            return null;
                        }
                    } catch (innerErr) {
                        console.error('Error interno procesando un item en getPolygonFeatures:', innerErr, item);
                        return null;
                    }
                })
                .filter((feature) => feature !== null);

            return features;
        } catch (error) {
            console.error('Error en getPolygonFeatures:', error);
            return [];
        }
    }, []);

    const pointData = useMemo(() => {
        try {
            if (Array.isArray(points)) {
                const pointFeatures = points
                    .map((point, index) => {
                        try {
                            if (
                                point &&
                                point.geometry &&
                                Array.isArray(point.geometry.coordinates) &&
                                point.geometry.coordinates.length >= 2
                            ) {
                                const [lon, lat] = point.geometry.coordinates;
                                if (typeof lon !== 'number' || typeof lat !== 'number') {
                                    console.warn(`Coordenadas inválidas en punto ${index}.`, point);
                                    return null;
                                }
                                return {
                                    type: 'Feature',
                                    geometry: { type: 'Point', coordinates: [lon, lat] },
                                    properties: {
                                        color: point.color || '#FF0000',
                                    },
                                };
                            }
                            return null;
                        } catch (err) {
                            console.error('Error procesando un punto en pointData:', err, point);
                            return null;
                        }
                    })
                    .filter(Boolean);

                return { type: 'FeatureCollection', features: pointFeatures };
            } else {
                console.warn('pointData: "points" no es un arreglo.');
                return { type: 'FeatureCollection', features: [] };
            }
        } catch (error) {
            console.error('Error en pointData useMemo:', error);
            return { type: 'FeatureCollection', features: [] };
        }
    }, [points]);


    const lineData = useMemo(() => {
        try {
            if (!Array.isArray(lines)) {
                console.warn('lineData: "lines" no es un arreglo.');
                return { type: 'FeatureCollection', features: [] };
            }
            const lineFeatures = lines
                .map((line, index) => {
                    try {
                        if (line && Array.isArray(line.latlngs)) {
                            const coordinates = line.latlngs
                                .map((latlng) => {
                                    if (
                                        latlng &&
                                        typeof latlng.lat === 'number' &&
                                        typeof latlng.lng === 'number'
                                    ) {
                                        return [latlng.lng, latlng.lat];
                                    }
                                    console.warn(`lineData: Coordenada inválida en línea index ${index}`, latlng);
                                    return null;
                                })
                                .filter((coord) => coord !== null);

                            if (coordinates.length > 0) {
                                // ID único
                                const featureId = line.id !== undefined ? line.id : `line-${index}`;
                                return {
                                    type: 'Feature',
                                    id: featureId,
                                    geometry: { type: 'LineString', coordinates },
                                    properties: {
                                        ...line.properties,
                                        color: '#ff0000',
                                    },
                                };
                            }
                        } else {
                            console.warn(`lineData: Estructura de línea inválida en index ${index}.`, line);
                        }
                        return null;
                    } catch (err) {
                        console.error('Error procesando un item en lineData:', err, line);
                        return null;
                    }
                })
                .filter(Boolean);

            return { type: 'FeatureCollection', features: lineFeatures };
        } catch (error) {
            console.error('Error en lineData useMemo:', error);
            return { type: 'FeatureCollection', features: [] };
        }
    }, [lines]);

    // (Opcional) Generar GeoJSON para líneas si se requiere, no se usa directamente aquí
    function generateGeoJSON(lines) {
        try {
            if (!Array.isArray(lines)) {
                console.error("generateGeoJSON: El parámetro 'lines' no es un arreglo válido.");
                return null;
            }

            const featureCollection = {
                type: 'FeatureCollection',
                features: lines.map((line, index) => {
                    if (line && Array.isArray(line.latlngs)) {
                        const coordinates = line.latlngs
                            .map((latlng) => {
                                const { lat, lng } = latlng;
                                if (typeof lat === 'number' && typeof lng === 'number') {
                                    return [lng, lat];
                                }
                                console.warn('generateGeoJSON: Coordenadas inválidas en línea.', latlng);
                                return null;
                            })
                            .filter((coord) => coord !== null);

                        if (coordinates.length > 0) {
                            return {
                                type: 'Feature',
                                id: line.id !== undefined ? line.id : `geojson-line-${index}`,
                                geometry: { type: 'LineString', coordinates },
                                properties: {
                                    ...line.properties,
                                    color: '#ff0000',
                                },
                            };
                        }
                    }
                    console.warn('generateGeoJSON: Línea inválida.', line);
                    return null;
                }).filter(feature => feature !== null),
            };

            return featureCollection;
        } catch (error) {
            console.error('Error en generateGeoJSON:', error);
            return null;
        }
    }

    // -----------------------------------------------------------
    //            Datos de líneas no filtradas
    // -----------------------------------------------------------
    const lineasNoFiltradasData = useMemo(() => {
        try {
            if (!Array.isArray(lineasNoFiltradas)) {
                console.warn('lineasNoFiltradasData: "lineasNoFiltradas" no es un arreglo.');
                return { type: 'FeatureCollection', features: [] };
            }

            const features = lineasNoFiltradas
                .map((line, index) => {
                    try {
                        if (line && Array.isArray(line.latlngs)) {
                            const coordinates = line.latlngs
                                .map((latlng) => {
                                    if (
                                        latlng &&
                                        typeof latlng.lat === 'number' &&
                                        typeof latlng.lng === 'number'
                                    ) {
                                        return [latlng.lng, latlng.lat];
                                    }
                                    console.warn(`lineasNoFiltradasData: Coordenada inválida en index ${index}`, latlng);
                                    return null;
                                })
                                .filter((c) => c !== null);

                            if (coordinates.length > 0) {
                                return {
                                    type: 'Feature',
                                    id: line.id !== undefined ? line.id : `no-filtradas-line-${index}`,
                                    geometry: { type: 'LineString', coordinates },
                                    properties: {
                                        ...line.properties,
                                        color: '#e0e0e0',
                                    },
                                };
                            }
                        } else if (line && line.geometry) {
                            // Si ya viene con geometry
                            if (['LineString', 'MultiLineString'].includes(line.geometry.type)) {
                                return {
                                    type: 'Feature',
                                    id: line.id !== undefined ? line.id : `no-filtradas-geom-${index}`,
                                    geometry: line.geometry,
                                    properties: {
                                        ...line.properties,
                                        color: '#e0e0e0',
                                    },
                                };
                            }
                            console.warn(
                                `lineasNoFiltradasData: Tipo de geometría no soportado en index ${index}.`,
                                line.geometry.type
                            );
                        } else {
                            console.warn(`lineasNoFiltradasData: Línea inválida en index ${index}.`, line);
                        }
                        return null;
                    } catch (err) {
                        console.error('Error procesando un ítem en lineasNoFiltradasData:', err, line);
                        return null;
                    }
                })
                .filter(Boolean);

            return { type: 'FeatureCollection', features };
        } catch (error) {
            console.error('Error en lineasNoFiltradasData useMemo:', error);
            return { type: 'FeatureCollection', features: [] };
        }
    }, [lineasNoFiltradas]);

    // -----------------------------------------------------------
    //        Función para obtener ID de un lote (polígono)
    // -----------------------------------------------------------
    const getLoteId = (properties) => {
        try {
            if (!properties || typeof properties !== 'object') {
                console.warn('getLoteId: properties no es un objeto válido.', properties);
                return 'Sin ID';
            }

            const keys = Object.keys(properties).map((key) => key.toLowerCase());
            const idLoteIndex = keys.indexOf('id_lote');
            const idIndex = keys.indexOf('id');

            if (idLoteIndex !== -1) {
                return properties[Object.keys(properties)[idLoteIndex]] || 'Sin ID';
            } else if (idIndex !== -1) {
                return properties[Object.keys(properties)[idIndex]] || 'Sin ID';
            } else {
                return 'Sin ID';
            }
        } catch (error) {
            console.error('Error en getLoteId:', error);
            return 'Sin ID';
        }
    };

    // -----------------------------------------------------------
    //       Datos de polígonos provenientes de polygonsData
    // -----------------------------------------------------------
    const polygonDataFeatures = useMemo(() => {
        try {
            if (!Array.isArray(polygonsData)) {
                console.warn('polygonDataFeatures: "polygonsData" no es un arreglo.');
                return { type: 'FeatureCollection', features: [] };
            }

            const features = polygonsData
                .map((feature, index) => {
                    try {
                        if (!feature || typeof feature !== 'object') {
                            console.warn(`polygonDataFeatures: Item inválido en index ${index}`, feature);
                            return null;
                        }

                        if (!feature.geometry || typeof feature.geometry !== 'object') {
                            console.warn(`polygonDataFeatures: geometry ausente o inválida en index ${index}`, feature);
                            return null;
                        }

                        const { type, coordinates } = feature.geometry;
                        if (!type || !Array.isArray(coordinates)) {
                            console.warn(`polygonDataFeatures: geometry con formato inválido en index ${index}`, feature);
                            return null;
                        }

                        const cleanPolygon = (coordsArray) => {
                            return coordsArray
                                .map((coord) => {
                                    if (Array.isArray(coord) && coord.length >= 2) {
                                        const [lng, lat] = coord;
                                        if (typeof lng === 'number' && typeof lat === 'number') {
                                            return [lng, lat];
                                        }
                                    }
                                    return null;
                                })
                                .filter(Boolean);
                        };

                        if (type === 'Polygon') {
                            const validCoordinates = coordinates
                                .map((ring) => (Array.isArray(ring) ? cleanPolygon(ring) : []))
                                .filter((ring) => ring.length > 0);

                            if (validCoordinates.length === 0) {
                                console.warn(`polygonDataFeatures: Polygon vacío/invalid en index ${index}.`);
                                return null;
                            }

                            return {
                                type: 'Feature',
                                id: feature.id !== undefined ? feature.id : `polygons-data-feature-${index}`,
                                geometry: {
                                    type: 'Polygon',
                                    coordinates: validCoordinates,
                                },
                                properties: {
                                    ...feature.properties,
                                },
                            };
                        } else if (type === 'MultiPolygon') {
                            const validCoordinates = coordinates
                                .map((polygon) => {
                                    if (!Array.isArray(polygon)) return null;
                                    return polygon
                                        .map((ring) => (Array.isArray(ring) ? cleanPolygon(ring) : []))
                                        .filter((r) => r.length > 0);
                                })
                                .filter((poly) => Array.isArray(poly) && poly.length > 0);

                            if (validCoordinates.length === 0) {
                                console.warn(`polygonDataFeatures: MultiPolygon vacío/invalid en index ${index}.`);
                                return null;
                            }

                            return {
                                type: 'Feature',
                                id: feature.id !== undefined ? feature.id : `polygons-data-multi-feature-${index}`,
                                geometry: {
                                    type: 'MultiPolygon',
                                    coordinates: validCoordinates,
                                },
                                properties: {
                                    ...feature.properties,
                                },
                            };
                        } else {
                            console.warn(
                                `polygonDataFeatures: Tipo de geometría "${type}" no soportado en index ${index}.`
                            );
                            return null;
                        }
                    } catch (innerErr) {
                        console.error('Error interno procesando polygonDataFeatures item:', innerErr, feature);
                        return null;
                    }
                })
                .filter(Boolean);

            return { type: 'FeatureCollection', features };
        } catch (error) {
            console.error('Error en polygonDataFeatures useMemo:', error);
            return { type: 'FeatureCollection', features: [] };
        }
    }, [polygonsData]);


    const polygonDataMemo = useMemo(() => {
        try {
            if (!Array.isArray(polygons)) {
                console.warn('polygonDataMemo: "polygons" no es un arreglo.');
                return { type: 'FeatureCollection', features: [] };
            }

            let foundInvalidCoords = false;

            const polygonFeatures = polygons
                .map((polygonCoords, index) => {
                    try {
                        if (Array.isArray(polygonCoords)) {
                            const loteId = getLoteId(polygonProperties[index] || {});
                            const isActive = activeLotes.includes(loteId);

                            const coordinates = polygonCoords
                                .map((coord) =>
                                    Array.isArray(coord) && coord.length >= 2
                                        ? [coord[1], coord[0]]
                                        : null
                                )
                                .filter((c) => c !== null);

                            if (coordinates.length === 0) {
                                foundInvalidCoords = true;
                                return null;
                            }

                            const properties = {
                                active: isActive,
                                loteId,
                                ...(polygonProperties && polygonProperties[index]
                                    ? polygonProperties[index]
                                    : {}),
                            };

                            return {
                                type: 'Feature',
                                id: `polygon-memo-${index}`,
                                geometry: {
                                    type: 'Polygon',
                                    coordinates: [coordinates],
                                },
                                properties,
                            };
                        }
                        foundInvalidCoords = true;
                        return null;
                    } catch (innerErr) {
                        console.error(
                            'Error interno procesando un polígono en polygonDataMemo:',
                            innerErr,
                            polygonCoords
                        );
                        return null;
                    }
                })
                .filter(Boolean);

            if (foundInvalidCoords && !hasWarnedInvalidCoords) {
                console.warn(
                    'polygonDataMemo: Se encontraron coordenadas inválidas en al menos un polígono.'
                );
                setHasWarnedInvalidCoords(true);
            }

            const filteredFeatures =
                activeLotes.length === 0
                    ? polygonFeatures
                    : polygonFeatures.filter((feature) =>
                        activeLotes.includes(feature.properties.loteId)
                    );

            return { type: 'FeatureCollection', features: filteredFeatures };
        } catch (error) {
            console.error('Error en polygonDataMemo useMemo:', error);
            return { type: 'FeatureCollection', features: [] };
        }
    }, [polygons, activeLotes, polygonProperties, hasWarnedInvalidCoords]);

    // -----------------------------------------------------------
    //           Datos de líneas bufferizadas
    // -----------------------------------------------------------
    const bufferedLinesData = useMemo(() => {
        try {
            if (!Array.isArray(bufferedLines)) {
                console.warn('bufferedLinesData: "bufferedLines" no es un arreglo.');
                return { type: 'FeatureCollection', features: [] };
            }
            const features = getPolygonFeatures(bufferedLines);
            return { type: 'FeatureCollection', features };
        } catch (error) {
            console.error('Error en bufferedLinesData useMemo:', error);
            return { type: 'FeatureCollection', features: [] };
        }
    }, [bufferedLines, getPolygonFeatures]);

    const bufferedIntersectionsData = useMemo(() => {
        try {
            if (!Array.isArray(bufferedIntersections)) {
                console.warn('bufferedIntersectionsData: "bufferedIntersections" no es un arreglo.');
                return { type: 'FeatureCollection', features: [] };
            }
            const features = getPolygonFeatures(bufferedIntersections);
            return { type: 'FeatureCollection', features };
        } catch (error) {
            console.error('Error en bufferedIntersectionsData useMemo:', error);
            return { type: 'FeatureCollection', features: [] };
        }
    }, [bufferedIntersections, getPolygonFeatures]);


    const areasSuperpuestasData = useMemo(() => {
        try {
            if (!Array.isArray(areasSuperpuestas)) {
                console.warn('areasSuperpuestasData: "areasSuperpuestas" no es un arreglo.');
                return { type: 'FeatureCollection', features: [] };
            }

            const processedAreas = areasSuperpuestas.map((item) => {
                try {
                    if (item && item.geometry && item.geometry.coordinates) {
                        let geometry = item.geometry;
                        if (geometry.type === 'Polygon') {
                            geometry = {
                                ...geometry,
                                coordinates: geometry.coordinates
                                    .map((ring) =>
                                        ring
                                            .map((coord) =>
                                                Array.isArray(coord) && coord.length >= 2 ? [coord[0], coord[1]] : null
                                            )
                                            .filter((c) => c !== null)
                                    )
                                    .filter((ring) => ring.length > 0),
                            };
                        } else if (geometry.type === 'MultiPolygon') {
                            geometry = {
                                ...geometry,
                                coordinates: geometry.coordinates
                                    .map((polygon) =>
                                        polygon
                                            .map((ring) =>
                                                ring
                                                    .map((coord) =>
                                                        Array.isArray(coord) && coord.length >= 2 ? [coord[0], coord[1]] : null
                                                    )
                                                    .filter((c) => c !== null)
                                            )
                                            .filter((ring) => ring.length > 0)
                                    )
                                    .filter((polygon) => polygon.length > 0),
                            };
                        }
                        return { ...item, geometry };
                    } else if (Array.isArray(item)) {
                        const coordinates = item
                            .map((coord) => (Array.isArray(coord) && coord.length >= 2 ? [coord[0], coord[1]] : null))
                            .filter((c) => c !== null);
                        if (coordinates.length > 0) {
                            return {
                                type: 'Feature',
                                geometry: {
                                    type: 'Polygon',
                                    coordinates: [coordinates],
                                },
                                properties: {},
                            };
                        }
                    }
                    console.warn('areasSuperpuestasData: Item inválido.', item);
                    return null;
                } catch (innerErr) {
                    console.error('Error interno procesando un item en areasSuperpuestasData:', innerErr, item);
                    return null;
                }
            }).filter(Boolean);

            const features = getPolygonFeatures(processedAreas);
            return { type: 'FeatureCollection', features };
        } catch (error) {
            console.error('Error en areasSuperpuestasData useMemo:', error);
            return { type: 'FeatureCollection', features: [] };
        }
    }, [areasSuperpuestas, getPolygonFeatures]);

    // -----------------------------------------------------------
    //       Datos de áreas no intersectadas
    // -----------------------------------------------------------
    const nonIntersectedAreasData = useMemo(() => {
        try {
            if (!Array.isArray(nonIntersectedAreas)) {
                console.warn('nonIntersectedAreasData: "nonIntersectedAreas" no es un arreglo.');
                return { type: 'FeatureCollection', features: [] };
            }

            const processedAreas = nonIntersectedAreas.map((item) => {
                try {
                    if (item && item.geometry && item.geometry.coordinates) {
                        let geometry = item.geometry;
                        if (geometry.type === 'Polygon') {
                            geometry = {
                                ...geometry,
                                coordinates: geometry.coordinates
                                    .map((ring) =>
                                        ring
                                            .map((coord) =>
                                                Array.isArray(coord) && coord.length >= 2 ? [coord[0], coord[1]] : null
                                            )
                                            .filter((c) => c !== null)
                                    )
                                    .filter((ring) => ring.length > 0),
                            };
                        } else if (geometry.type === 'MultiPolygon') {
                            geometry = {
                                ...geometry,
                                coordinates: geometry.coordinates
                                    .map((polygon) =>
                                        polygon
                                            .map((ring) =>
                                                ring
                                                    .map((coord) =>
                                                        Array.isArray(coord) && coord.length >= 2 ? [coord[0], coord[1]] : null
                                                    )
                                                    .filter((c) => c !== null)
                                            )
                                            .filter((ring) => ring.length > 0)
                                    )
                                    .filter((polygon) => polygon.length > 0),
                            };
                        }
                        return { ...item, geometry };
                    } else if (Array.isArray(item)) {
                        const coordinates = item
                            .map((coord) =>
                                Array.isArray(coord) && coord.length >= 2 ? [coord[0], coord[1]] : null
                            )
                            .filter((c) => c !== null);
                        if (coordinates.length > 0) {
                            return {
                                type: 'Feature',
                                geometry: {
                                    type: 'Polygon',
                                    coordinates: [coordinates],
                                },
                                properties: {},
                            };
                        }
                    }
                    console.warn('nonIntersectedAreasData: Item inválido.', item);
                    return null;
                } catch (innerErr) {
                    console.error('Error interno procesando un item en nonIntersectedAreasData:', innerErr, item);
                    return null;
                }
            }).filter(Boolean);

            const features = getPolygonFeatures(processedAreas);
            return { type: 'FeatureCollection', features };
        } catch (error) {
            console.error('Error en nonIntersectedAreasData useMemo:', error);
            return { type: 'FeatureCollection', features: [] };
        }
    }, [nonIntersectedAreas, getPolygonFeatures]);

    // -----------------------------------------------------------
    //          Ajuste de bounds automáticos
    // -----------------------------------------------------------
    const hasFitBoundsRef = useRef({
        points: false,
        lines: false,
        polygons: false,
        polygonsData: false,
        lineasNoFiltradas: false,
        image: false,
    });

    const fitBoundsToData = useCallback((dataFeatures) => {
        try {
            if (localMapRef.current && localMapRef.current.getMap()) {
                const map = localMapRef.current.getMap();
                const bounds = new mapboxgl.LngLatBounds();

                dataFeatures.forEach((feature) => {
                    try {
                        const geometry = feature.geometry;
                        if (!geometry) {
                            console.warn('fitBoundsToData: Feature sin geometría.', feature);
                            return;
                        }
                        const { type, coordinates } = geometry;

                        switch (type) {
                            case 'Point':
                                bounds.extend(coordinates);
                                break;
                            case 'LineString':
                                coordinates.forEach((coord) => bounds.extend(coord));
                                break;
                            case 'Polygon':
                                coordinates.forEach((ring) => {
                                    ring.forEach((coord) => bounds.extend(coord));
                                });
                                break;
                            case 'MultiPolygon':
                                coordinates.forEach((polygon) => {
                                    polygon.forEach((ring) => {
                                        ring.forEach((coord) => bounds.extend(coord));
                                    });
                                });
                                break;
                            default:
                                console.warn(`fitBoundsToData: Tipo de geometría "${type}" no soportado.`);
                        }
                    } catch (geomErr) {
                        console.error('Error procesando feature en fitBoundsToData:', geomErr, feature);
                    }
                });

                if (!bounds.isEmpty()) {
                    map.fitBounds(bounds, { padding: 20 });
                }
            }
        } catch (error) {
            console.error('Error en fitBoundsToData:', error);
        }
    }, []);

    useEffect(() => {
        try {
            if (mapLoaded && localMapRef.current && localMapRef.current.getMap()) {
                const map = localMapRef.current.getMap();
                const bounds = new mapboxgl.LngLatBounds();
                let shouldFitBounds = false;

                // 1) Imagen overlay
                if (imageUrl && northWestCoords && southEastCoords && !hasFitBoundsRef.current.image) {
                    try {
                        bounds.extend([northWestCoords[0], northWestCoords[1]]);
                        bounds.extend([southEastCoords[0], northWestCoords[1]]);
                        bounds.extend([southEastCoords[0], southEastCoords[1]]);
                        bounds.extend([northWestCoords[0], southEastCoords[1]]);
                        hasFitBoundsRef.current.image = true;
                        shouldFitBounds = true;
                    } catch (errImg) {
                        console.error('Error al ajustar bounds para image overlay:', errImg);
                    }
                }

                // 2) Puntos
                if (pointData.features.length > 0 && !hasFitBoundsRef.current.points) {
                    try {
                        pointData.features.forEach((feature) => {
                            if (feature.geometry && feature.geometry.coordinates) {
                                bounds.extend(feature.geometry.coordinates);
                            }
                        });
                        hasFitBoundsRef.current.points = true;
                        shouldFitBounds = true;
                    } catch (errPoints) {
                        console.error('Error al ajustar bounds para puntos:', errPoints);
                    }
                }

                // 3) Líneas
                if (lineData.features.length > 0 && !hasFitBoundsRef.current.lines) {
                    try {
                        lineData.features.forEach((feature) => {
                            if (feature.geometry && feature.geometry.type === 'LineString') {
                                feature.geometry.coordinates.forEach((coord) => bounds.extend(coord));
                            }
                        });
                        hasFitBoundsRef.current.lines = true;
                        shouldFitBounds = true;
                    } catch (errLines) {
                        console.error('Error al ajustar bounds para líneas:', errLines);
                    }
                }

                if (lineasNoFiltradasData.features.length > 0 && !hasFitBoundsRef.current.lineasNoFiltradas) {
                    try {
                        lineasNoFiltradasData.features.forEach((feature) => {
                            if (feature.geometry.type === 'LineString') {
                                feature.geometry.coordinates.forEach((coord) => bounds.extend(coord));
                            } else if (feature.geometry.type === 'MultiLineString') {
                                feature.geometry.coordinates.forEach((line) => {
                                    line.forEach((coord) => bounds.extend(coord));
                                });
                            }
                        });
                        hasFitBoundsRef.current.lineasNoFiltradas = true;
                        shouldFitBounds = true;
                    } catch (errNoFiltradas) {
                        console.error('Error al ajustar bounds para líneas no filtradas:', errNoFiltradas);
                    }
                }

                if (polygonDataMemo.features.length > 0 && !hasFitBoundsRef.current.polygons) {
                    try {
                        polygonDataMemo.features.forEach((feature) => {
                            if (feature.geometry && feature.geometry.type === 'Polygon') {
                                feature.geometry.coordinates.forEach((ring) => {
                                    ring.forEach((coord) => bounds.extend(coord));
                                });
                            } else if (feature.geometry && feature.geometry.type === 'MultiPolygon') {
                                feature.geometry.coordinates.forEach((polygon) => {
                                    polygon.forEach((ring) => {
                                        ring.forEach((coord) => bounds.extend(coord));
                                    });
                                });
                            }
                        });
                        hasFitBoundsRef.current.polygons = true;
                        shouldFitBounds = true;
                    } catch (errPolygons) {
                        console.error('Error al ajustar bounds para polígonos:', errPolygons);
                    }
                }

                if (polygonDataFeatures.features.length > 0 && !hasFitBoundsRef.current.polygonsData) {
                    try {
                        polygonDataFeatures.features.forEach((feature) => {
                            if (feature.geometry && feature.geometry.type === 'Polygon') {
                                feature.geometry.coordinates.forEach((ring) => {
                                    ring.forEach((coord) => bounds.extend(coord));
                                });
                            } else if (feature.geometry && feature.geometry.type === 'MultiPolygon') {
                                feature.geometry.coordinates.forEach((polygon) => {
                                    polygon.forEach((ring) => {
                                        ring.forEach((coord) => bounds.extend(coord));
                                    });
                                });
                            }
                        });
                        hasFitBoundsRef.current.polygonsData = true;
                        shouldFitBounds = true;
                    } catch (errPolygonsData) {
                        console.error('Error al ajustar bounds para polígonos de datos:', errPolygonsData);
                    }
                }

                if (shouldFitBounds && !bounds.isEmpty()) {
                    try {
                        map.fitBounds(bounds, { padding: 20 });
                    } catch (errFit) {
                        console.error('Error al llamar a fitBounds:', errFit);
                    }
                }
            }
        } catch (error) {
            console.error('Error en el useEffect de fitBounds:', error);
        }
    }, [
        mapLoaded,
        pointData.features.length,
        lineData.features.length,
        polygonDataMemo.features.length,
        polygonDataFeatures.features.length,
        lineasNoFiltradasData.features.length,
        imageUrl,
        northWestCoords,
        southEastCoords,
    ]);


    const handleCenterToPolygonsData = useCallback(() => {
        try {
            if (polygonDataFeatures.features.length > 0 && localMapRef.current) {
                fitBoundsToData(polygonDataFeatures.features);
            } else {
                console.warn('handleCenterToPolygonsData: No hay características de polígonos para centrar.');
            }
        } catch (error) {
            console.error('Error en handleCenterToPolygonsData:', error);
        }
    }, [polygonDataFeatures.features, fitBoundsToData]);

    const handleCenterToAnalysis = useCallback(() => {
        try {
            if (localMapRef.current) {
                const map = localMapRef.current.getMap();
                if (pointData.features.length > 0) {
                    fitBoundsToData(pointData.features);
                } else if (lineData.features.length > 0) {
                    fitBoundsToData(lineData.features);
                } else if (polygonDataMemo.features.length > 0) {
                    fitBoundsToData(polygonDataMemo.features);
                } else if (imageUrl && northWestCoords && southEastCoords) {
                    const bounds = new mapboxgl.LngLatBounds();
                    bounds.extend([northWestCoords[0], northWestCoords[1]]);
                    bounds.extend([southEastCoords[0], northWestCoords[1]]);
                    bounds.extend([southEastCoords[0], southEastCoords[1]]);
                    bounds.extend([northWestCoords[0], southEastCoords[1]]);
                    map.fitBounds(bounds, { padding: 20 });
                } else {
                    console.warn('handleCenterToAnalysis: No hay datos disponibles para centrar.');
                }
            }
        } catch (error) {
            console.error('Error en handleCenterToAnalysis:', error);
        }
    }, [
        localMapRef,
        pointData.features,
        lineData.features,
        polygonDataMemo.features,
        imageUrl,
        northWestCoords,
        southEastCoords,
        fitBoundsToData,
    ]);


    const onLineClickRef = useRef(onLineClick);
    const onLineHoverRef = useRef(onLineHover);
    const onLineMouseOutRef = useRef(onLineMouseOut);

    useEffect(() => {
        onLineClickRef.current = onLineClick;
    }, [onLineClick]);

    useEffect(() => {
        onLineHoverRef.current = onLineHover;
    }, [onLineHover]);

    useEffect(() => {
        onLineMouseOutRef.current = onLineMouseOut;
    }, [onLineMouseOut]);

    useEffect(() => {
        try {
            if (!mapLoaded || !localMapRef.current || !localMapRef.current.getMap()) return;

            const map = localMapRef.current.getMap();

            const handleClick = (e) => {
                try {
                    const features = e.features;
                    if (features && features.length > 0) {
                        onLineClickRef.current(e);
                    }
                } catch (error) {
                    console.error('handleClick en líneas:', error);
                }
            };

            const handleMouseMove = (e) => {
                try {
                    if (e.features && e.features.length > 0) {
                        const feature = e.features[0];
                        if (feature.layer && feature.layer.id === 'lines-layer') {
                            map.getCanvas().style.cursor = 'pointer';
                            map.setFeatureState({ source: 'lines', id: feature.id }, { hover: true });
                            onLineHoverRef.current(feature);
                        }
                    }
                } catch (error) {
                    console.error('handleMouseMove en líneas:', error);
                }
            };

            const handleMouseLeave = () => {
                try {
                    map.getCanvas().style.cursor = '';
                    const features = map.querySourceFeatures('lines');
                    features.forEach((feature) => {
                        map.setFeatureState({ source: 'lines', id: feature.id }, { hover: false });
                    });
                    onLineMouseOutRef.current();
                } catch (error) {
                    console.error('handleMouseLeave en líneas:', error);
                }
            };

            map.on('click', 'lines-layer', handleClick);
            map.on('mousemove', 'lines-layer', handleMouseMove);
            map.on('mouseleave', 'lines-layer', handleMouseLeave);

            return () => {
                try {
                    map.off('click', 'lines-layer', handleClick);
                    map.off('mousemove', 'lines-layer', handleMouseMove);
                    map.off('mouseleave', 'lines-layer', handleMouseLeave);
                } catch (error) {
                    console.error('Error al remover eventos de líneas:', error);
                }
            };
        } catch (error) {
            console.error('Error en useEffect de eventos de líneas:', error);
        }
    }, [mapLoaded]);

    // -----------------------------------------------------------
    //             Manejo de selectedFiles (capas)
    // -----------------------------------------------------------
    const [selectedFilesData, setSelectedFilesData] = useState([]);

    // Determinar tipo de análisis por nombre de archivo
    const getAnalysisTypeFromFileName = (fileName) => {
        try {
            if (!fileName || typeof fileName !== 'string') return 'Desconocido';
            if (fileName.includes('aplicaciones_aereas')) return 'Aplicaciones aéreas';
            if (fileName.includes('cosecha_mecanica')) return 'Cosecha mecánica';
            if (fileName.includes('conteo_de_palmas')) return 'Conteo de palmas';
            return 'Desconocido';
        } catch (error) {
            console.error('Error en getAnalysisTypeFromFileName:', error);
            return 'Desconocido';
        }
    };

    // Verificar si un archivo es de imagen
    const isImageFile = (fileName) => {
        try {
            if (!fileName || typeof fileName !== 'string') return false;
            const ext = fileName.split('.').pop().toLowerCase();
            return ['jpg', 'jpeg', 'tif', 'tiff', 'png'].includes(ext);
        } catch (error) {
            console.error('Error en isImageFile:', error);
            return false;
        }
    };

    // Cargar los archivos seleccionados
    useEffect(() => {
        const loadSelectedFiles = async () => {
            try {
                if (!Array.isArray(selectedFiles)) {
                    console.warn('loadSelectedFiles: "selectedFiles" no es un arreglo.');
                    setSelectedFilesData([]);
                    return;
                }

                const results = [];

                for (let i = 0; i < selectedFiles.length; i++) {
                    try {
                        const file = selectedFiles[i];
                        const analysisType = getAnalysisTypeFromFileName(file.file_name || '');
                        const date = file.updated || 'Fecha desconocida';
                        const isFiltradas = file.file_name.includes('_filtradas');
                        const isNoFiltradas = file.file_name.includes('_no_filtradas');

                        if (isImageFile(file.file_name)) {
                            results.push({
                                type: 'image',
                                id: `selectedfile-image-${i}`,
                                imageUrl: file.signed_url,
                                coordinates: null, // Ajustar si conoces las coords exactas
                                analysisType,
                                date,
                            });
                        } else {
                            // Asumir que es un GeoJSON
                            try {
                                const resp = await axios.get(file.signed_url);
                                let data = resp.data;

                                // Normalizar a FeatureCollection
                                if (data.type === 'Feature') {
                                    data = { type: 'FeatureCollection', features: [data] };
                                } else if (data.type !== 'FeatureCollection') {
                                    data = { type: 'FeatureCollection', features: [] };
                                }

                                // Agregar propiedades
                                data.features = data.features.map((f) => ({
                                    ...f,
                                    properties: {
                                        ...f.properties,
                                        analysisType,
                                        date,
                                        filtradoEstado: isFiltradas
                                            ? 'filtradas'
                                            : isNoFiltradas
                                                ? 'no_filtradas'
                                                : 'filtradas',
                                    },
                                }));

                                results.push({
                                    type: 'geojson',
                                    id: `selectedfile-geojson-${i}`,
                                    data,
                                    analysisType,
                                    date,
                                });
                            } catch (fileErr) {
                                console.error('Error al cargar el archivo geojson:', fileErr);
                                results.push({
                                    type: 'geojson',
                                    id: `selectedfile-geojson-${i}`,
                                    data: { type: 'FeatureCollection', features: [] },
                                    analysisType,
                                    date,
                                });
                            }
                        }
                    } catch (loopErr) {
                        console.error(`Error en iteración de selectedFiles index ${i}:`, loopErr);
                    }
                }

                setSelectedFilesData(results);
            } catch (error) {
                console.error('Error en loadSelectedFiles:', error);
                setSelectedFilesData([]);
            }
        };
        loadSelectedFiles();
    }, [selectedFiles]);

    // Popup para los selectedFiles
    const [selectedFilesPopup, setSelectedFilesPopup] = useState(null);

    // Definir capas interactivas para selectedFiles
    const interactiveLayers = useMemo(() => {
        try {
            const layers = [];
            selectedFilesData.forEach((fileData, index) => {
                if (fileData.type === 'geojson') {
                    layers.push(`selectedfile-polygon-layer-normal-${index}`);
                    layers.push(`selectedfile-polygon-layer-overlap-${index}`);
                    layers.push(`selectedfile-line-layer-${index}`);
                    layers.push(`selectedfile-point-layer-${index}`);
                } else if (fileData.type === 'image' && fileData.coordinates) {
                    layers.push(`${fileData.id}-layer`);
                }
            });
            return layers;
        } catch (error) {
            console.error('Error en interactiveLayers useMemo:', error);
            return [];
        }
    }, [selectedFilesData]);

    // Manejo de clic en las capas de selectedFiles
    useEffect(() => {
        try {
            if (!mapLoaded || !localMapRef.current || !localMapRef.current.getMap()) return;

            const map = localMapRef.current.getMap();

            const handleClick = (e) => {
                try {
                    if (e.features && e.features.length > 0) {
                        const feat = e.features[0];
                        if (feat.layer && feat.layer.id && feat.properties && feat.properties.analysisType) {
                            const lngLat = e.lngLat;
                            const content = (
                                <div>
                                    <strong>Análisis:</strong> {feat.properties.analysisType}
                                    <br />
                                    <strong>Fecha:</strong> {feat.properties.date}
                                </div>
                            );
                            setSelectedFilesPopup({ lngLat, content });
                        }
                    }
                } catch (error) {
                    console.error('handleClick en selectedFiles:', error);
                }
            };

            map.on('click', handleClick);

            return () => {
                try {
                    map.off('click', handleClick);
                } catch (error) {
                    console.error('Error al remover handleClick en selectedFiles:', error);
                }
            };
        } catch (error) {
            console.error('Error en useEffect de handleClick en selectedFiles:', error);
        }
    }, [mapLoaded, selectedFilesData]);

    const polygonLayerSelectedNormal = (id) => ({
        id: `selectedfile-polygon-layer-normal-${id}`,
        type: 'fill',
        paint: {
            'fill-color': '#00FF00',
            'fill-opacity': 0.5,
            'fill-outline-color': '#00FF00',
        },
        filter: ['all', ['==', '$type', 'Polygon'], ['!=', ['get', 'type'], 'overlap']],
    });

    const polygonLayerSelectedOverlap = (id) => ({
        id: `selectedfile-polygon-layer-overlap-${id}`,
        type: 'fill',
        paint: {
            'fill-color': '#FF0000',
            'fill-opacity': 0.7,
            'fill-outline-color': '#FF0000',
        },
        filter: ['all', ['==', '$type', 'Polygon'], ['==', ['get', 'type'], 'overlap']],
    });

    const lineLayerSelected = (id) => ({
        id: `selectedfile-line-layer-${id}`,
        type: 'line',
        paint: {
            'line-color': [
                'match',
                ['get', 'filtradoEstado'],
                'filtradas', '#ff0000',
                'no_filtradas', '#888',
                '#888',
            ],
            'line-width': 2,
        },
        filter: ['==', '$type', 'LineString'],
    });

    const pointLayerSelected = (id) => ({
        id: `selectedfile-point-layer-${id}`,
        type: 'circle',
        paint: {
            'circle-radius': 5,
            'circle-color': '#48ff00',
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff',
        },
        filter: ['==', '$type', 'Point'],
    });

    // -----------------------------------------------------------
    //               Controles de capas (LayersIcon)
    // -----------------------------------------------------------
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleLayersClick = (event) => {
        try {
            setAnchorEl(event.currentTarget);
        } catch (error) {
            console.error('Error en handleLayersClick:', error);
        }
    };

    const handleLayersClose = () => {
        try {
            setAnchorEl(null);
        } catch (error) {
            console.error('Error en handleLayersClose:', error);
        }
    };

    const handleStyleSelect = (styleName) => {
        try {
            setSelectedStyle(styleName);
            setMapStyle(mapStyles[styleName]);
            handleLayersClose();
        } catch (error) {
            console.error('Error en handleStyleSelect:', error);
        }
    };

    // -----------------------------------------------------------
    //        Manejo de eventos en polígonos (polygons-data)
    // -----------------------------------------------------------
    const handlePolygonsDataClick = useCallback(
        (event) => {
            if (isEditing) return;
            try {
                const feature = event.features && event.features[0];
                if (feature) {
                    const map = localMapRef.current.getMap();
                    const featureId = feature.id;

                    if (selectedPolygonId === featureId) {
                        // Deseleccionar el polígono
                        map.setFeatureState({ source: 'polygons-data', id: featureId }, { selected: false });
                        setSelectedPolygonId(null);
                        setSelectedPopup(null);
                    } else {
                        // Deseleccionar el anterior
                        if (selectedPolygonId !== null) {
                            map.setFeatureState({ source: 'polygons-data', id: selectedPolygonId }, { selected: false });
                        }

                        // Seleccionar el nuevo
                        map.setFeatureState({ source: 'polygons-data', id: featureId }, { selected: true });
                        setSelectedPolygonId(featureId);

                        // Generar contenido del popup
                        const properties = feature.properties || {};
                        const content = Object.entries(properties).map(([key, value]) => (
                            <div key={key}>
                                <strong>{key.replace(/_/g, ' ')}:</strong> {value}
                            </div>
                        ));

                        setSelectedPopup({
                            lngLat: event.lngLat,
                            content: <div>{content}</div>,
                        });

                        onSelectLote(properties.loteId || properties.ID || 'Sin ID');
                    }
                }
            } catch (error) {
                console.error('Error en handlePolygonsDataClick:', error);
            }
        },
        [selectedPolygonId, onSelectLote, isEditing]
    );

    useEffect(() => {
        try {
            if (!mapLoaded || !localMapRef.current || !localMapRef.current.getMap()) return;
            const map = localMapRef.current.getMap();

            map.on('click', 'polygons-data-layer', handlePolygonsDataClick);

            map.on('mouseenter', 'polygons-data-layer', () => {
                try {
                    map.getCanvas().style.cursor = 'pointer';
                } catch (errEnter) {
                    console.error('Error en mouseenter de polygons-data-layer:', errEnter);
                }
            });

            map.on('mouseleave', 'polygons-data-layer', () => {
                try {
                    map.getCanvas().style.cursor = '';
                } catch (errLeave) {
                    console.error('Error en mouseleave de polygons-data-layer:', errLeave);
                }
            });

            return () => {
                try {
                    map.off('click', 'polygons-data-layer', handlePolygonsDataClick);
                    map.off('mouseenter', 'polygons-data-layer');
                    map.off('mouseleave', 'polygons-data-layer');
                } catch (error) {
                    console.error('Error al remover eventos de polygons-data-layer:', error);
                }
            };
        } catch (error) {
            console.error('Error en useEffect de polygons-data-layer:', error);
        }
    }, [mapLoaded, handlePolygonsDataClick]);

    const handleClosePopup = () => {
        try {
            if (selectedPolygonId !== null && localMapRef.current) {
                const map = localMapRef.current.getMap();
                map.setFeatureState({ source: 'polygons-data', id: selectedPolygonId }, { selected: false });
                setSelectedPolygonId(null);
            }
            setSelectedPopup(null);
        } catch (error) {
            console.error('Error en handleClosePopup:', error);
        }
    };

    return (
        <Box position="relative">
            <Map
                initialViewState={{
                    longitude: center[1],
                    latitude: center[0],
                    zoom: zoom,
                }}
                mapStyle={mapStyle}
                mapboxAccessToken="pk.eyJ1IjoiamRtYW4iLCJhIjoiY20xNGpkN2d0MHlsejJ4cTA1Z3lwdGNhMCJ9.O5UY8q02UPPoCCMrobQ0sA"
                style={{ height: '65vh', width: '100%' }}
                ref={localMapRef}
                projection="globe"
                preserveDrawingBuffer={true}
                onLoad={handleMapLoad}
                interactiveLayerIds={['polygons-data-layer']}
            >
                <NavigationControl position="top-right" />

                {isTiled && (
                    <Source
                        id="raster-tiles-overlay"
                        type="raster"
                        tiles={[imageUrl]}
                        tileSize={256}
                    >
                        <Layer
                            id="raster-tiles-layer"
                            type="raster"
                            paint={{
                                'raster-opacity': 1,
                                'raster-resampling': 'linear',
                            }}
                        />
                    </Source>
                )}

                {!isTiled && imageUrl && northWestCoords && southEastCoords && (
                    <Source
                        id="image-overlay"
                        type="image"
                        url={imageUrl}
                        coordinates={[
                            [northWestCoords[0], northWestCoords[1]],
                            [southEastCoords[0], northWestCoords[1]],
                            [southEastCoords[0], southEastCoords[1]],
                            [northWestCoords[0], southEastCoords[1]],
                        ]}
                    >
                        <Layer
                            id="image-overlay-layer"
                            type="raster"
                            paint={{
                                'raster-opacity': 1,
                                'raster-resampling': 'linear',
                            }}
                        />
                    </Source>
                )}

                {pointData && pointData.features.length > 0 && (
                    <Source id="points" type="geojson" data={pointData}>
                        <Layer
                            id="points-layer"
                            type="circle"
                            paint={{
                                'circle-radius': 2,
                                'circle-color': ['get', 'color'],
                            }}
                        />
                    </Source>
                )}

                {lineasNoFiltradasData && lineasNoFiltradasData.features.length > 0 && (
                    <Source id="lineas-no-filtradas" type="geojson" data={lineasNoFiltradasData}>
                        <Layer
                            id="lineas-no-filtradas-layer"
                            type="line"
                            beforeId="lines-layer"
                            paint={{
                                'line-color': ['get', 'color'],
                                'line-width': 2,
                            }}
                        />
                    </Source>
                )}

                {lineData && lineData.features.length > 0 && (
                    <Source id="lines" type="geojson" data={lineData}>
                        <Layer
                            id="lines-layer"
                            type="line"
                            paint={{
                                'line-color': [
                                    'case',
                                    ['==', ['feature-state', 'hover'], true],
                                    '#E0FFFF',
                                    ['get', 'color'],
                                ],
                                'line-width': 2,
                            }}
                        />
                    </Source>
                )}

                {polygonDataMemo && polygonDataMemo.features.length > 0 && (
                    <Source id="polygons" type="geojson" data={polygonDataMemo}>
                        <Layer
                            id="polygons-layer"
                            type="fill"
                            paint={{
                                'fill-color': [
                                    'case',
                                    ['boolean', ['get', 'active'], false],
                                    'red',
                                    ['has', 'color'],
                                    ['get', 'color'],
                                    '#4ca100',
                                ],
                                'fill-opacity': 1,
                            }}
                        />
                    </Source>
                )}

                {polygonDataFeatures && polygonDataFeatures.features.length > 0 && (
                    <Source id="polygons-data" type="geojson" data={polygonDataFeatures}>
                        <Layer
                            id="polygons-data-layer"
                            type="fill"
                            paint={{
                                'fill-color': [
                                    'case',
                                    ['==', ['feature-state', 'selected'], true],
                                    '#FFA500',
                                    'yellow',
                                ],
                                'fill-opacity': [
                                    'case',
                                    ['==', ['feature-state', 'selected'], true],
                                    0.9,
                                    0.8,
                                ],
                                'fill-outline-color': [
                                    'case',
                                    ['==', ['feature-state', 'selected'], true],
                                    '#FF4500',
                                    'yellow',
                                ],
                            }}
                        />
                    </Source>
                )}

                {/* ---------- Líneas bufferizadas ---------- */}
                {bufferedLinesData && bufferedLinesData.features.length > 0 && (
                    <Source id="buffered-lines" type="geojson" data={bufferedLinesData}>
                        <Layer
                            id="buffered-lines-layer"
                            type="fill"
                            paint={{
                                'fill-color': 'purple',
                                'fill-opacity': 0.5,
                            }}
                        />
                    </Source>
                )}

                {/* ---------- Intersecciones bufferizadas ---------- */}
                {bufferedIntersectionsData && bufferedIntersectionsData.features.length > 0 && (
                    <Source id="buffered-intersections" type="geojson" data={bufferedIntersectionsData}>
                        <Layer
                            id="buffered-intersections-layer"
                            type="fill"
                            paint={{
                                'fill-color': 'blue',
                                'fill-opacity': 1,
                            }}
                        />
                    </Source>
                )}

                {/* ---------- Áreas superpuestas ---------- */}
                {areasSuperpuestasData && areasSuperpuestasData.features.length > 0 && (
                    <Source id="areas-superpuestas" type="geojson" data={areasSuperpuestasData}>
                        <Layer
                            id="areas-superpuestas-layer"
                            type="fill"
                            paint={{
                                'fill-color': 'red',
                                'fill-opacity': 1,
                                'fill-outline-color': 'red',
                            }}
                        />
                    </Source>
                )}

                {/* ---------- Áreas no intersectadas ---------- */}
                {nonIntersectedAreasData && nonIntersectedAreasData.features.length > 0 && (
                    <Source id="non-intersected-areas" type="geojson" data={nonIntersectedAreasData}>
                        <Layer
                            id="non-intersected-areas-layer"
                            type="fill"
                            paint={{
                                'fill-color': 'yellow',
                                'fill-opacity': 1,
                            }}
                        />
                    </Source>
                )}
                {selectedFilesData &&
                    selectedFilesData.map((fileData, index) => {
                        try {
                            if (fileData.type === 'geojson' && fileData.data) {
                                return (
                                    <Source key={fileData.id} id={fileData.id} type="geojson" data={fileData.data}>
                                        <Layer {...polygonLayerSelectedNormal(index)} />
                                        <Layer {...polygonLayerSelectedOverlap(index)} />
                                        <Layer {...lineLayerSelected(index)} />
                                        <Layer {...pointLayerSelected(index)} />
                                    </Source>
                                );
                            } else if (fileData.type === 'image' && fileData.coordinates) {
                                return (
                                    <Source
                                        key={fileData.id}
                                        id={fileData.id}
                                        type="image"
                                        url={fileData.imageUrl}
                                        coordinates={fileData.coordinates}
                                    >
                                        <Layer
                                            id={`${fileData.id}-layer`}
                                            type="raster"
                                            paint={{ 'raster-opacity': 1 }}
                                        />
                                    </Source>
                                );
                            }
                            return null;
                        } catch (renderErr) {
                            console.error(`Error al renderizar selectedFilesData en index ${index}:`, renderErr);
                            return null;
                        }
                    })}

                {selectedPopup && selectedPopup.lngLat && selectedPopup.content && (
                    <Popup
                        longitude={selectedPopup.lngLat.lng}
                        latitude={selectedPopup.lngLat.lat}
                        onClose={handleClosePopup}
                        closeOnClick={false}
                    >
                        {selectedPopup.content}
                    </Popup>
                )}

                {popupInfo && popupInfo.lngLat && popupInfo.content && (
                    <Popup
                        longitude={popupInfo.lngLat.lng}
                        latitude={popupInfo.lngLat.lat}
                        onClose={() => setPopupInfo(null)}
                        closeOnClick={false}
                    >
                        {popupInfo.content}
                    </Popup>
                )}

                {selectedFilesPopup && selectedFilesPopup.lngLat && selectedFilesPopup.content && (
                    <Popup
                        longitude={selectedFilesPopup.lngLat.lng}
                        latitude={selectedFilesPopup.lngLat.lat}
                        onClose={() => setSelectedFilesPopup(null)}
                        closeOnClick={false}
                    >
                        {selectedFilesPopup.content}
                    </Popup>
                )}
            </Map>

            <Box position="absolute" top={10} left={10} zIndex={1000}>
                <IconButton
                    sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        '&:hover': { backgroundColor: 'lightgray' },
                    }}
                    onClick={handleLayersClick}
                >
                    <LayersIcon />
                </IconButton>

                <Menu
                    id="layer-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleLayersClose}
                    PaperProps={{
                        style: {
                            maxHeight: 200,
                            width: 'auto',
                            padding: 0,
                        },
                    }}
                >
                    {Object.keys(mapStyles).map((styleName) => (
                        <MenuItem
                            key={styleName}
                            onClick={() => handleStyleSelect(styleName)}
                            sx={{ fontSize: '0.85rem' }}
                        >
                            {styleName}
                        </MenuItem>
                    ))}
                </Menu>
            </Box>

            <Box position="absolute" top={10} right={10} zIndex={1000}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <StyledIconButton onClick={handleCenterToPolygonsData}>
                        <HomeIcon fontSize="small" />
                    </StyledIconButton>
                    <StyledIconButton onClick={handleCenterToAnalysis}>
                        <MyLocationIcon fontSize="small" />
                    </StyledIconButton>
                </Box>
            </Box>
        </Box>
    );
};

export default CommonMap;
