
// DashboardStyle.js
import { styled } from '@mui/system';

export const DashboardContainer = styled('div')({
    display: 'flex',
    marginLeft: '60px',
    backgroundColor: '#f7f7f7',
    minHeight: '100vh',
});

export const MainContent = styled('main')(({ isSidebarOpen }) => ({
    marginLeft: '0px',
    width: `100%`,
    boxSizing: 'border-box',
    backgroundColor: '#ffffff'
}));

export const DashboardControls = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
});

export const MapSectionContainer = styled('div')({
    marginTop: '135px',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    backgroundColor: '#fff',
    padding: '10px'
});

export const AnalysisSection = styled('div')({
    marginTop: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
});
