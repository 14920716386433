// FilterToolbarStyle.js

import { styled } from '@mui/system';
import { AppBar, Button, Divider, Menu, MenuItem, Box } from '@mui/material';
import { red } from '@mui/material/colors';

export const FilterBar = styled(AppBar)(
    ({ isSidebarOpen, isDashboardIndicators, isSmallScreen }) => ({
        backgroundColor: '#ffffff',
        color: '#000',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        borderBottom: '1px solid #ddd',
        padding: '11px',
        position: 'fixed',
        top: isDashboardIndicators ? '50px' : '135px',
        width: isDashboardIndicators ? '100%' : 'calc(100% - 100px)',
        transition: 'top 0.3s ease, margin-bottom 0.3s ease',
        zIndex: 1001,
        marginBottom: isDashboardIndicators ? '16px' : '0', // Añadido margen inferior condicional
    })
);

export const ResetButton = styled(Button)({
    marginLeft: '8px',
    color: red[600],
    textTransform: 'none',
    fontWeight: 'bold',
    borderColor: red[600],
    '&:hover': {
        borderColor: red[800],
        backgroundColor: 'rgba(255, 0, 0, 0.04)',
    },
});

export const StyledDivider = styled(Divider)({
    height: '40px',
    marginX: '16px',
});

export const ToolsMenu = styled(Menu)({
    '& .MuiPaper-root': {
        minWidth: '200px',
        padding: '16px',
    },
});

export const ToolButton = styled(Button)(({ active }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '6px 10px', // Reducido padding para mayor compacidad
    color: active ? '#1976d2' : '#000',
    backgroundColor: active ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
    borderRadius: '4px',
    textTransform: 'none',
    fontSize: '0.875rem', // Ajustado tamaño de fuente
    '&:hover': {
        backgroundColor: 'rgba(25, 118, 210, 0.12)',
    },
    '& .icon': {
        marginRight: '6px', // Reducido margen entre icono y texto
    },
}));
