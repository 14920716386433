import { API_BASE_URL } from "../../utils/Constants";
import { ultimoAnalisis } from "../../utils/mapUtils";
import {obtenerDatosCompletosAps} from "../../utils/Constants";
import {API_BASE_URL_DASHBOARD} from "../../utils/config";

export const fetchDataAps = async (idAnalisisAps, setDatosAnalisis) => {
    try {
        const data = await obtenerDatosCompletosAps(idAnalisisAps);
        const datos = {
            nombreResponsable: {
                titulo: "Nombre del Responsable",
                valor: data.nombreResponsable || ''
            },
            fechaInicio: {
                titulo: "Fecha de Inicio Actividad",
                valor: data.fechaInicio || ''
            },
            fechaFin: {
                titulo: "Fecha de Fin Actividad",
                valor: data.fechaFin || ''
            },
            nombreFinca: {
                titulo: "Nombre de la Finca",
                valor: data.nombreFinca || ''
            },
            codigoFinca: {
                titulo: "Código de la Finca",
                valor: data.codigoFinca || ''
            },
            codigoLote: {
                titulo: "Código del Lote",
                valor: data.codigoLote || ''
            },
            nombreOperador: {
                titulo: "Nombre del Operador",
                valor: data.nombreOperador || ''
            },
            codigoEquipo: {
                titulo: "Código del Equipo",
                valor: data.codigoEquipo || ''
            },
            horaInicio: {
                titulo: "Hora de Inicio",
                valor: data.horaInicio || ''
            },
            horaFin: {
                titulo: "Hora de Finalización",
                valor: data.horaFin || ''
            },
            eficiencia: {
                titulo: "Eficiencia",
                valor: data.eficiencia || ''
            },
            dosisTeorica: {
                titulo: "Dosis Teórica",
                valor: data.dosisTeorica || ''
            },
            humedadDelCultivo: {
                titulo: "Humedad del Cultivo",
                valor: data.humedadDelCultivo || ''
            },
            tchEstimado: {
                titulo: "TCH Estimado",
                valor: data.tchEstimado || ''
            },
            tiempoTotal: {
                titulo: "Tiempo Total",
                valor: data.tiempoTotal || 0
            },
            productoAps: {
                titulo: "Producto APS",
                valor: data.productoAps || ''
            },
            alturaAps: {
                titulo: "Altura APS",
                valor: data.altura ? `${data.altura.toFixed(3)} m` : ''
            },
            velocidadAps: {
                titulo: "Velocidad APS",
                valor: data.velocidad ? `${data.velocidad.toFixed(3)} m/s` : ''
            },
            equipo: {
                titulo: "Equipo",
                valor: data.equipo || ''
            },
            dosisReal: {
                titulo: "Volumen Total",
                valor: data.dosisReal ? `${data.dosisReal.toFixed(3)} L` : ''
            },
        };
        setDatosAnalisis(datos);
    } catch (error) {
        console.error("Error al cargar datos de APS:", error);
        setDatosAnalisis({});
    }
};


export const shouldEnableExecBashAps = (selectedZipFile) => {
    return selectedZipFile !== null;
};

export const cargaDatosAps = async (userData, selectedAnalysisTypeRef) => {
    if (selectedAnalysisTypeRef.current && userData.userId) {
        try {
            const response = await ultimoAnalisis(selectedAnalysisTypeRef, userData.userId);
            if (response && response.data && response.data.ID_ANALISIS) {
                return response;
            } else {
                console.error("Respuesta del último análisis no contiene datos esperados");
            }
        } catch (error) {
            console.error("Error al obtener último análisis:", error);
        }
    }
};


export const processApsData = (datosAnalisis, indicadores) => ({
    analisis: "APLICACIONES_AEREAS",
    ...datosAnalisis,
    indicadores: {
        promedioAltura: {
            titulo: "Promedio de Altura",
            valor: indicadores.promedioAltura ? `${indicadores.promedioAltura} m` : ''
        },
        areaSobreAplicada: {
            titulo: "Área Sobre Aplicada",
            valor: indicadores.areaSobreAplicada ? `${indicadores.areaSobreAplicada} ha` : ''
        },
        areaAplicada: {
            titulo: "Área Aplicada",
            valor: indicadores.areaAplicada ? `${indicadores.areaAplicada} ha` : ''
        },
        areaNoAplicada: {
            titulo: "Área No Aplicada",
            valor: indicadores.areaNoAplicada ? `${indicadores.areaNoAplicada} ha` : ''
        },
        areaAplicadaEnCalles: {
            titulo: "Área Aplicada en Calles",
            valor: indicadores.areaSobreCalles ? `${indicadores.areaSobreCalles} ha` : ''
        },
        areaAplicadaFueraDelLote: {
            titulo: "Área Aplicada Fuera del Lote",
            valor: indicadores.areaFueraDeLote ? `${indicadores.areaFueraDeLote} ha` : ''
        },
        porcentajeVariacion: {
            titulo: "Porcentaje de Variación",
            valor: indicadores.porcentajeVariacion
        },
        promedioDosisReal: {
            titulo: "Promedio de Dosis Real",
            valor: indicadores.promedioDosisReal ? `${indicadores.promedioDosisReal} L` : ''
        },
        promedioVelocidad: {
            titulo: "Promedio de Velocidad",
            valor: indicadores.promedioVelocidad ? `${indicadores.promedioVelocidad} m/s` : ''
        },
        diferenciaDeAreaApsKml: {
          titulo: "Diferencia Dosis teorica y real",
            valor: indicadores.diferenciaDeAreaApsKml ? `${indicadores.diferenciaDeAreaApsKml} ha` : ''
        },
        dosisTotalRealAplicada: {
            titulo: "Dosis Total Real Aplicada",
            valor: indicadores.dosisTotalRealAplicada ? `${indicadores.dosisTotalRealAplicada} L/ha` : ''
        }
    }
});


export const fetchDataApsIndicators = async (startDate, endDate, endpoint, idUsuario, minMaxParams, equipo, filters = {}) => {
    try {
        const queryParams = new URLSearchParams({
            FECHA_INICIO: startDate,
            userId: idUsuario,
            FECHA_FINAL: endDate,
            minMax: JSON.stringify(minMaxParams),
            EQUIPO: equipo,
            NOMBRE_DE_OPERADOR: filters.operadorAA || '',
            NOMBRE_FINCA: filters.fincaAA || '',
            PRODUCTO: filters.productoAA || '',
            ZAFRA: filters.zafraAA || '',
            TERCIO: filters.tercioAA || '',
            LOTE: filters.loteAA || '',
        }).toString();


        const response = await fetch(`${API_BASE_URL_DASHBOARD}api/indicators/aplicaciones-aereas/${endpoint}?${queryParams}`);
        const data = await response.json();

        return data.map(item => {
            const indicadores = {
                _id: item._id || null
            };

            Object.keys(item).forEach(key => {
                const indicador = item[key];

                if (indicador && typeof indicador === 'object' && 'valor' in indicador && 'titulo' in indicador) {
                    indicadores[key] = {
                        title: indicador.titulo,
                        value: indicador.valor
                    };
                }
            });

            return indicadores;
        });
    } catch (error) {
        console.error("Error al obtener los indicadores de aplicaciones aéreas:", error);
        return [];
    }
};


export const initialIndicatorsAps = {
    cardData: {
        velocidad: { title: 'Velocidad', max: '', min: '' },
        dosisReal: { title: 'Dosis Real', max: '', min: '' },
        dosisTeorica: { title: 'Dosis Teorica', max: '', min: '' },
        areaAplicada: {title: 'Área aplicada', max: '', min: ''},
        areaNoAplicada: {title: 'Área no aplicada', max: '', min: ''},
        areaSobreAplicada: {title: 'Área sobre aplicada', max: '', min: ''},
        tch: {title: 'TCH', max: '', min: ''},
        altura: {title: 'Altura', max: '', min: ''},
    }
};
