import React, { useState, useCallback } from 'react';
import SideBar from "../SideBar/SideBar";
import Navbar from "../Navbar/Navbar";
import { LayoutContainer, MainContent, ContentWrapper } from './LayoutWithSidebarStyle';

const LayoutWithSidebar = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [selectedFilesInLayout, setSelectedFilesInLayout] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});

    const handleToggleSidebar = useCallback((isOpen) => {
        setIsSidebarOpen(isOpen);
    }, []);

    const handleSelectedFilesChange = useCallback((files) => {
        setSelectedFilesInLayout(files);
    }, []);

    const childrenWithProps = React.Children.map(children, (child) => {
        return React.cloneElement(child, {
            isSidebarOpen,
            selectedFiles: selectedFilesInLayout,
            selectedFilters,
            setSelectedFilters
        });
    });

    return (
        <LayoutContainer>
            <SideBar onToggle={handleToggleSidebar} onSelectedFilesChange={handleSelectedFilesChange} selectedFilters={selectedFilters} />
            <MainContent>
                <Navbar isSidebarOpen={isSidebarOpen} />
                <ContentWrapper>{childrenWithProps}</ContentWrapper>
            </MainContent>
        </LayoutContainer>
    );
};

export default LayoutWithSidebar;
