
// NoDataMessage.jsx
import React from 'react';
import { Typography } from '@mui/material';

const NoDataMessage = ({ message = "No hay datos" }) => (
    <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: '#B0B0B0',
        fontSize: '16px',
    }}>
        <Typography variant="h6">{message}</Typography>
    </div>
);

export default NoDataMessage;
