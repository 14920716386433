// analysis/conteoPalmas/conteoPalmasDataCards.jsx
import React from 'react';
import DataCard from "../../components/CardData/DataCard";
import {displayValue} from "../../utils/generalUtils";
import { styled } from '@mui/system';

const CategoryContainer = styled('div')({
    marginTop: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    width: '100%',
});

const CategoryTitle = styled('h3')({
    fontSize: '1.2em',
    fontWeight: '600',
    color: '#fff',
    backgroundColor: '#1565C0',
    padding: '10px',
    borderRadius: '4px',
    marginBottom: '20px',
    textAlign: 'center',
    textTransform: 'uppercase'
});

const CardsRow = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px,1fr))',
    gap: '20px',
    width: '100%',
});

export const renderConteoAgaveDataCards = (validations, datosAnalisis, indicadores) => {
    const variosCards = [
        { title: "Conteo de Agaves", value: datosAnalisis.conteoAgaves?.valor ? displayValue(datosAnalisis.conteoAgaves?.valor) : "No hay datos" },
        { title: "Precio de Plantación", value: datosAnalisis.precioPlantacion?.valor ? displayValue(datosAnalisis.precioPlantacion?.valor) : "No hay datos" },
        { title: "Costos de Plantación", value: datosAnalisis.costosPlantacion?.valor ? displayValue(datosAnalisis.costosPlantacion?.valor) : "No hay datos" },
        { title: "Utilidad Neta", value: datosAnalisis.utilidadNeta?.valor ? displayValue(datosAnalisis.utilidadNeta?.valor) : "No hay datos" },
    ];

    return (
        <CategoryContainer>
            <CategoryTitle>Indicadores Conteo de Agave</CategoryTitle>
            <CardsRow>
                {variosCards.map((card, index) => (
                    <DataCard key={index} title={card.title}>
                        {card.value}
                    </DataCard>
                ))}
            </CardsRow>
        </CategoryContainer>
    );
};
