import { toast } from 'react-toastify';
import { API_BASE_URL_AI } from '../../utils/config';
import { insertarUltimoAnalisis } from "../../utils/mapUtils";

export const ejecutarProcesoConteoPalmas = async ({
                                                      selectedZipFile,
                                                      setProcessingFinished,
                                                      setImageUrl,
                                                      setNorthWestCoords,
                                                      setSouthEastCoords,
                                                      setConteoObjetos,
                                                      socket,
                                                      socketSessionID,
                                                      setProgress,
                                                      setTitleLoader,
                                                      setShowProgressBar,
                                                      selectedAnalysisTypeRef,
                                                      idAnalisisBash,
                                                      nombre,
                                                      userId,
                                                      calidad
                                                  }) => {
    setProgress(0);
    setTitleLoader("Iniciando conteo de palmas...");

    if (!selectedZipFile) {
        toast.error("Por favor selecciona un archivo ZIP antes de continuar.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: true,
        });
        return;
    }

    const result = await insertarUltimoAnalisis(
        selectedAnalysisTypeRef,
        idAnalisisBash,
        nombre,
        userId
    );

    const CHUNK_SIZE = 10 * 1024 * 1024;
    const fileReader = new FileReader();
    setProgress(10);
    setTitleLoader("Subiendo archivo...");

    fileReader.onload = function (event) {
        const blob = new Blob([event.target.result], { type: selectedZipFile.type });
        const totalChunks = Math.ceil(blob.size / CHUNK_SIZE);
        const worker = new Worker('Workers/ImageCountWorker.js');
        setProgress(20);
        setTitleLoader("Archivo subido, procesando fragmentos...");

        let processedChunks = 0;

        worker.postMessage({
            file: blob,
            chunkSize: CHUNK_SIZE,
            apiUrl: `${API_BASE_URL_AI}image_detection/count`,
            sessionID: socketSessionID,
            totalChunks: totalChunks,
            idAnalisis: result.data.idAnalisis,
            nameModel: 'palma',
            calidad
        });

        worker.onmessage = (event) => {
            if (event.data.progress) {
                processedChunks++;
                const progressPercentage = 20 + ((processedChunks / totalChunks) * 70);
                setProgress(progressPercentage.toFixed(2));
                setTitleLoader(`Procesando fragmentos (${processedChunks}/${totalChunks})...`);

                socket.emit(`${socketSessionID}:progressUpdate`, { progress: event.data.progress });
            } else if (event.data.error) {
                console.error(event.data.error);
                worker.terminate();
                setShowProgressBar(false);

                toast.error('Error al subir fragmento: ' + event.data.error, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: true,
                });
            } else if (event.data.done) {
                setProgress(100);
                setTitleLoader("Proceso completado.");
                setShowProgressBar(false);
                toast.success('Archivo subido exitosamente. Procesando análisis...', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: true,
                });

                const { imageUrl, northWest, southEast, totalObjetos } = event.data;
                setImageUrl(imageUrl);
                setNorthWestCoords(northWest);
                setSouthEastCoords(southEast);
                setConteoObjetos(totalObjetos);
                worker.terminate();
                setProcessingFinished(true);
            }
        };
    };

    fileReader.readAsArrayBuffer(selectedZipFile);
};
