import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const NavbarContainer = styled(Box)(({ theme, isSidebarOpen }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60px',
    padding: '0 20px',
    backgroundColor: '#fff',
    position: 'fixed',
    left: '75px',
    top: 0,
    zIndex: 1001,
    width: 'calc(100% - 75px)',
    boxSizing: 'border-box',
}));

export const NavbarSectionLeft = styled(Box)({
    position: 'absolute',
    left: '20px',
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
});

export const NavbarSectionCentered = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1 auto',
});

export const NavbarSectionRight = styled(Box)({
    position: 'absolute',
    right: '20px',
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
});

export const CompanyLogoImage = styled('img')({
    maxHeight: '50px',
    maxWidth: '200px',
    height: 'auto',
    width: 'auto',
    objectFit: 'contain',
});

export const NavbarLogoImage = styled('img')({
    width: '150px',
    height: 'auto',
});
