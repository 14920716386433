// FilterToolbar.jsx

import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import {
    IconButton,
    TextField,
    Button,
    useMediaQuery,
    Box,
    Tooltip,
    Toolbar,
    FormControlLabel,
    Switch,
} from '@mui/material';
import {
    FilterList as FilterListIcon,
    Refresh as RefreshIcon,
    ArrowDropDown as ArrowDropDownIcon,
    Build as BuildIcon,
} from '@mui/icons-material';
import {
    FaFilePdf,
    FaCut,
    FaDrawPolygon,
    FaTrash,
    FaBuffer,
    FaUndo,
    FaArrowsAltH,
} from 'react-icons/fa';
import Autocomplete from '@mui/material/Autocomplete';
import { VariableSizeList } from 'react-window';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import 'moment/locale/es';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
    ResetButton,
    FilterBar,
    StyledDivider,
    ToolsMenu,
    ToolButton,
} from './FilterToolbarStyle';

const LISTBOX_PADDING = 8;

const renderRow = ({ data, index, style }) => {
    const option = data[index];
    return (
        <Tooltip title={option.props.children} key={index}>
            {React.cloneElement(option, {
                style: {
                    ...style,
                    top: style.top + LISTBOX_PADDING,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '200px',
                },
            })}
        </Tooltip>
    );
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef(function OuterElementType(props, ref) {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = useCallback(
        (child) => {
            if (React.isValidElement(child) && child.type === 'ListSubheader') {
                return 48;
            }
            return itemSize;
        },
        [itemSize]
    );

    const getHeight = useCallback(() => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.reduce((a, b) => a + getChildSize(b), 0);
    }, [itemCount, itemData, getChildSize, itemSize]);

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const toolDetails = {
    cut: { label: 'Cortar línea', icon: <FaCut /> },
    draw: { label: 'Dibujar línea', icon: <FaDrawPolygon /> },
    delete: { label: 'Borrar líneas', icon: <FaTrash /> },
    buffer: { label: 'Buffer de línea', icon: <FaBuffer /> },
    stretch: { label: 'Estirar línea', icon: <FaArrowsAltH /> },
};

const FilterToolbar = ({
                           isSidebarOpen,
                           isDashboardIndicators,
                           filterOptions = [],
                           onDateChange,
                           onFilterClick,
                           loteOptions = [],
                           operadorOptions = [],
                           fincasOptions = [],
                           zafrasOptions = [],
                           tercioOptions = [],
                           productosOptionsAA = [],
                           operadorOptionsAA = [],
                           fincasOptionsAA = [],
                           zafraOptionsAA = [],
                           loteOptionsAA = [],
                           tercioOptionsAA = [],
                           dateRange,
                           selectedActivity,
                           onGenerateReport,
                           isGeneratingReport,
                           handleCutLine,
                           handleDrawLine,
                           handleDeleteLine,
                           handleToggleBuffer,
                           handleUndo,
                           handleStretchLine,
                           activeTool,
                           isBufferActive,
                           bufferValue,
                           setBufferValue,
                           showUnfilteredLines,
                           toggleUnfilteredLines,
                           selectedAnalysisType,
                       }) => {
    moment.locale('es');

    const startOfMonth = useMemo(() => moment().startOf('month'), []);
    const endOfMonth = useMemo(() => moment().endOf('month'), []);

    const [startDate, setStartDate] = useState(dateRange?.startDate || startOfMonth);
    const [endDate, setEndDate] = useState(dateRange?.endDate || endOfMonth);

    const [selectedFilters, setSelectedFilters] = useState({
        loteCM: null,
        operadorCM: null,
        tipoAeronave: null,
        zafraCM: null,
        tercioCM: null,
        fincaCM: null,
        operadorAA: null,
        fincaAA: null,
        productoAA: null,
        zafraAA: null,
        loteAA: null,
        tercioAA: null,
    });

    useEffect(() => {
        setSelectedFilters({
            loteCM: null,
            operadorCM: null,
            tipoAeronave: null,
            zafraCM: null,
            tercioCM: null,
            fincaCM: null,
            operadorAA: null,
            fincaAA: null,
            productoAA: null,
            zafraAA: null,
            loteAA: null,
            tercioAA: null,
        });
        setStartDate(dateRange?.startDate || startOfMonth);
        setEndDate(dateRange?.endDate || endOfMonth);
    }, [selectedActivity, dateRange, startOfMonth, endOfMonth]);

    const handleStartDateChange = useCallback(
        (date) => {
            if (date && date.isValid() && moment(date).isSameOrBefore(endDate, 'day')) {
                setStartDate(date);
                if (onDateChange) {
                    onDateChange({ startDate: date, endDate });
                }
            }
        },
        [endDate, onDateChange]
    );

    const handleEndDateChange = useCallback(
        (date) => {
            if (date && date.isValid() && moment(date).isSameOrAfter(startDate, 'day')) {
                setEndDate(date);
                if (onDateChange) {
                    onDateChange({ startDate, endDate: date });
                }
            }
        },
        [startDate, onDateChange]
    );

    const handleFilterChange = useCallback((filterName, value) => {
        setSelectedFilters((prevFilters) => ({
            ...prevFilters,
            [filterName]: value,
        }));
    }, []);

    const handleReset = useCallback(() => {
        setStartDate(startOfMonth);
        setEndDate(endOfMonth);
        setSelectedFilters({
            loteCM: null,
            operadorCM: null,
            tipoAeronave: null,
            zafraCM: null,
            tercioCM: null,
            fincaCM: null,
            operadorAA: null,
            fincaAA: null,
            productoAA: null,
            zafraAA: null,
            loteAA: null,
            tercioAA: null,
        });
        if (onDateChange) {
            onDateChange({ startDate: startOfMonth, endDate: endOfMonth });
        }
    }, [onDateChange, startOfMonth, endOfMonth]);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleFilterButtonClick = useCallback(() => {
        if (onFilterClick) {
            onFilterClick(selectedFilters);
        }
    }, [onFilterClick, selectedFilters]);

    const dynamicFilters = useMemo(() => {
        return filterOptions.filter(
            (filter) => filter.value !== 'fechaInicio' && filter.value !== 'fechaFin'
        );
    }, [filterOptions]);

    const getOptionsForFilter = useCallback(
        (filterValue) => {
            switch (filterValue) {
                case 'tipoAeronave':
                    return {
                        options:
                            filterOptions.find((f) => f.value === 'tipoAeronave')?.options ||
                            [],
                        loading: false,
                    };
                case 'loteCM':
                    return { options: loteOptions, loading: false };
                case 'operadorCM':
                    return { options: operadorOptions, loading: false };
                case 'fincaCM':
                    return { options: fincasOptions, loading: false };
                case 'zafraCM':
                    return { options: zafrasOptions, loading: false };
                case 'tercioCM':
                    return { options: tercioOptions, loading: false };

                case 'operadorAA':
                    return { options: operadorOptionsAA, loading: false };
                case 'fincaAA':
                    return { options: fincasOptionsAA, loading: false };
                case 'zafraAA':
                    return { options: zafraOptionsAA, loading: false };
                case 'loteAA':
                    return { options: loteOptionsAA, loading: false };
                case 'tercioAA':
                    return { options: tercioOptionsAA, loading: false };
                case 'productoAA':
                    return { options: productosOptionsAA, loading: false };

                default:
                    return { options: [], loading: false };
            }
        },
        [
            filterOptions,
            loteOptions,
            operadorOptions,
            fincasOptions,
            zafrasOptions,
            tercioOptions,
            operadorOptionsAA,
            fincasOptionsAA,
            zafraOptionsAA,
            loteOptionsAA,
            tercioOptionsAA,
            productosOptionsAA,
        ]
    );

    const isAerialApplications = selectedAnalysisType === 'APLICACIONES_AEREAS';

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const currentTool = activeTool ? toolDetails[activeTool] : null;

    return (
        <FilterBar
            position="static"
            isSidebarOpen={isSidebarOpen}
            isDashboardIndicators={isDashboardIndicators}
            isSmallScreen={isSmallScreen}
        >
            <Toolbar
                variant="dense"
                disableGutters
                sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                {/* Filtros a la izquierda */}
                <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="nowrap"
                    gap={0.5}
                    sx={{ overflowX: 'auto' }}
                >
                    <IconButton edge="start" color="inherit" aria-label="filter" size="small">
                        <FilterListIcon />
                    </IconButton>

                    {onDateChange && (
                        <LocalizationProvider dateAdapter={AdapterMoment} locale="es">
                            <DatePicker
                                label={isSmallScreen ? 'Inicio' : 'Fecha Inicio'}
                                value={startDate}
                                onChange={handleStartDateChange}
                                maxDate={endDate}
                                disableFuture
                                inputFormat="DD/MM/YYYY"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        sx={{
                                            marginRight: 1,
                                            minWidth: isSmallScreen ? 70 : 100,
                                            maxWidth: isSmallScreen ? 100 : 140,
                                            height: 36,
                                            '& .MuiInputBase-input': {
                                                height: '1.25em',
                                                fontSize: isSmallScreen ? '0.7rem' : '0.8rem',
                                            },
                                        }}
                                    />
                                )}
                            />
                            <DatePicker
                                label={isSmallScreen ? 'Fin' : 'Fecha Fin'}
                                value={endDate}
                                onChange={handleEndDateChange}
                                minDate={startDate}
                                disableFuture
                                inputFormat="DD/MM/YYYY"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        sx={{
                                            marginRight: 1,
                                            minWidth: isSmallScreen ? 70 : 100,
                                            maxWidth: isSmallScreen ? 100 : 140,
                                            height: 36,
                                            '& .MuiInputBase-input': {
                                                height: '1.25em',
                                                fontSize: isSmallScreen ? '0.7rem' : '0.8rem',
                                            },
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    )}

                    {dynamicFilters.map((filter) => {
                        const { options, loading } = getOptionsForFilter(filter.value);
                        const filterValue = selectedFilters[filter.value] || null;
                        const isLargeDataset = options.length > 100;

                        return (
                            <Autocomplete
                                key={filter.value}
                                options={options}
                                getOptionLabel={(option) => option}
                                loading={loading}
                                value={filterValue}
                                onChange={(event, newValue) => {
                                    handleFilterChange(filter.value, newValue);
                                }}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={
                                            isSmallScreen
                                                ? filter.label.substring(0, 10)
                                                : filter.label
                                        }
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            marginRight: 0.5,
                                            minWidth: isSmallScreen ? 70 : 100,
                                            maxWidth: isSmallScreen ? 100 : 140,
                                            height: 36,
                                            '& .MuiInputBase-input': {
                                                height: '1.25em',
                                                fontSize: isSmallScreen ? '0.7rem' : '0.8rem',
                                            },
                                        }}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                        sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            maxWidth: '200px',
                                        }}
                                    >
                                        {option}
                                    </Box>
                                )}
                                ListboxComponent={
                                    isLargeDataset ? ListboxComponent : undefined
                                }
                                sx={{
                                    marginRight: 0.5,
                                    minWidth: isSmallScreen ? 70 : 100,
                                    maxWidth: isSmallScreen ? 100 : 140,
                                }}
                            />
                        );
                    })}
                </Box>

                {/* Botones y herramientas a la derecha */}
                <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="nowrap"
                    gap={0.5}
                >
                    {/* Botón Filtrar */}
                    {onFilterClick && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFilterButtonClick}
                            sx={{ marginRight: 0.5 }}
                            size={isSmallScreen ? 'small' : 'medium'}
                            disabled={isGeneratingReport}
                        >
                            Filtrar
                        </Button>
                    )}

                    {/* Botón para generar reporte */}
                    {onGenerateReport && (
                        <Tooltip title="Generar Reporte">
                            <IconButton
                                onClick={onGenerateReport}
                                sx={{ color: '#A9A9A9', marginLeft: '5px' }}
                                disabled={isGeneratingReport}
                                size="small"
                            >
                                <FaFilePdf size={20} />
                            </IconButton>
                        </Tooltip>
                    )}

                    {!isDashboardIndicators && <StyledDivider orientation="vertical" flexItem />}

                    {!isDashboardIndicators && (
                        <>
                            <Button
                                variant="outlined"
                                startIcon={currentTool ? currentTool.icon : <BuildIcon />}
                                endIcon={<ArrowDropDownIcon />}
                                onClick={handleMenuOpen}
                                disabled={!isAerialApplications}
                                sx={{
                                    textTransform: 'none',
                                    borderColor: isAerialApplications
                                        ? '#1976d2'
                                        : 'rgba(0, 0, 0, 0.23)',
                                    color: isAerialApplications
                                        ? '#1976d2'
                                        : 'rgba(0, 0, 0, 0.6)',
                                    padding: '6px 8px',
                                    fontSize: '0.8rem',
                                }}
                            >
                                {currentTool ? currentTool.label : 'Aplicaciones Aéreas'}
                            </Button>
                            <ToolsMenu
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                disableScrollLock
                                disableAutoFocusItem
                                keepMounted
                            >
                                <Box display="flex" flexDirection="column" gap={0.5} sx={{ padding: 1 }}>
                                    <ToolButton
                                        onClick={() => {
                                            handleCutLine();
                                            handleMenuClose();
                                        }}
                                        active={activeTool === 'cut'}
                                    >
                                        <FaCut className="icon" />
                                        Cortar línea
                                    </ToolButton>
                                    <ToolButton
                                        onClick={() => {
                                            handleDrawLine();
                                            handleMenuClose();
                                        }}
                                        active={activeTool === 'draw'}
                                    >
                                        <FaDrawPolygon className="icon" />
                                        Dibujar línea
                                    </ToolButton>
                                    <ToolButton
                                        onClick={() => {
                                            handleDeleteLine();
                                            handleMenuClose();
                                        }}
                                        active={activeTool === 'delete'}
                                    >
                                        <FaTrash className="icon" />
                                        Borrar líneas
                                    </ToolButton>
                                    <ToolButton
                                        onClick={() => {
                                            handleToggleBuffer();
                                        }}
                                        active={isBufferActive}
                                    >
                                        <FaBuffer className="icon" />
                                        Buffer de línea
                                    </ToolButton>
                                    <ToolButton
                                        onClick={() => {
                                            handleUndo();
                                            handleMenuClose();
                                        }}
                                        disabled={!isAerialApplications}
                                    >
                                        <FaUndo className="icon" />
                                        Deshacer
                                    </ToolButton>
                                    <ToolButton
                                        onClick={() => {
                                            handleStretchLine();
                                            handleMenuClose();
                                        }}
                                        active={activeTool === 'stretch'}
                                    >
                                        <FaArrowsAltH className="icon" />
                                        Estirar línea
                                    </ToolButton>

                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={showUnfilteredLines}
                                                onChange={toggleUnfilteredLines}
                                                color="primary"
                                                disabled={!isAerialApplications}
                                            />
                                        }
                                        label="Ruta de vuelo"
                                        sx={{ marginLeft: 0, paddingLeft: 0 }}
                                    />

                                    {isBufferActive && (
                                        <TextField
                                            label="Ancho (m)"
                                            type="number"
                                            value={bufferValue}
                                            onChange={(e) => setBufferValue(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                width: '100%',
                                                marginTop: 0.5,
                                                fontSize: '0.8rem',
                                            }}
                                            disabled={!isAerialApplications}
                                        />
                                    )}
                                </Box>
                            </ToolsMenu>
                        </>
                    )}
                    <ResetButton
                        startIcon={<RefreshIcon />}
                        onClick={handleReset}
                        size={isSmallScreen ? 'small' : 'medium'}
                        disabled={isGeneratingReport}
                        sx={{ padding: '6px 8px', fontSize: '0.8rem' }}
                    >
                        Reset
                    </ResetButton>
                </Box>
            </Toolbar>
        </FilterBar>
    );
};

export default FilterToolbar;
