// AerialApplications.jsx

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSocket } from '../../context/SocketContext';
import { API_BASE_PYTHON_SERVICE } from '../../utils/config';
import axios from 'axios';
import {
    polygon as turfPolygon,
    area as turfArea,
    union as turfUnion,
    difference as turfDifference,
    intersect as turfIntersect,
    buffer as turfBuffer,
    lineSplit as turfLineSplit,
    lineString as turfLineString,
    lineIntersect as turfLineIntersect,
    length as turfLength
} from '@turf/turf';
import BarIndicator from '../../components/BarIndicator/BarIndicator';
import { v4 as uuidv4 } from 'uuid';
import CommonMap from '../../components/CommonMap/CommonMap';
import MapDialog from '../../components/MapDialog/MapDialog';
import { Snackbar, Alert } from '@mui/material';

const AerialApplications = ({
                                idAnalisis,
                                tipoAnalisis,
                                onAreasCalculated,
                                onPromediosCalculated,
                                activarEdicionInteractiva,
                                highlightedLote,
                                activeLotes,
                                polygonsData,
                                onSelectLote,
                                onLeaveLote,
                                onHoverLote,
                                isFilterDialogOpen,
                                closeFilterDialog,
                                setImgLaflet,
                                mapRef,
                                polygonsLayerRef,
                                setLabelsAndColors,
                                setOnAreasCalculatedLoader,
                                activeTool,
                                isBufferActive,
                                bufferValue,
                                showUnfilteredLines,
                                setActiveTool
                            }) => {

    const userData = JSON.parse(localStorage.getItem('userData')) || {};

    const [polygons, setPolygons] = useState([]);
    const [areasSuperpuestas, setAreasSuperpuestas] = useState([]);
    const [mapCenter] = useState([0, 0]);
    const [zoom] = useState(3);
    const [activeFilter, setActiveFilter] = useState(null);
    const [polygonsProperties, setPolygonsProperties] = useState([]);
    const [lines, setLines] = useState([]);
    const [lineasNoFiltradas, setLineasNoFiltradas] = useState([]);
    const [bufferedLines, setBufferedLines] = useState([]);
    const [bufferedIntersections, setBufferedIntersections] = useState([]);
    const [kmlPolygons, setKmlPolygons] = useState([]);
    const [isKml, setIsKml] = useState(false);
    const [popupInfo, setPopupInfo] = useState(null);
    const [isFilteringLines, setIsFilteringLines] = useState(true);
    const [actionHistory, setActionHistory] = useState([]);
    const [areasCalculated, setAreasCalculated] = useState(false);

    const socketContext = useSocket();
    const { socket, socketSessionID } = socketContext || {};

    const [availableFilters, setAvailableFilters] = useState({
        speed: false,
        altitude: false,
        realDose: false,
    });
    const [filterSpeed, setFilterSpeed] = useState(false);
    const [filterAltitude, setFilterAltitude] = useState(false);
    const [filterRealDose, setFilterRealDose] = useState(false);

    const [lowSpeed, setLowSpeed] = useState(0);
    const [medSpeed, setMedSpeed] = useState(0);
    const [highSpeed, setHighSpeed] = useState(0);

    const [lowAltitude, setLowAltitude] = useState(0);
    const [medAltitude, setMedAltitude] = useState(0);
    const [highAltitude, setHighAltitude] = useState(0);

    const [lowRealDose, setLowRealDose] = useState(0);
    const [medRealDose, setMedRealDose] = useState(0);
    const [highRealDose, setHighRealDose] = useState(0);

    const [nonIntersectedAreas, setNonIntersectedAreas] = useState([]);

    const isDrawingRef = useRef(false);
    const newLineCoordsRef = useRef([]);
    const isCuttingRef = useRef(false);
    const cutLineCoordsRef = useRef([]);

    const workerRef = useRef(null);
    const activeToolRef = useRef(activeTool);

    const timeoutRef = useRef(null);

    const nextLineIdRef = useRef(0);

    const currentToolCleanupRef = useRef(null);

    const onAreasCalculatedRef = useRef(onAreasCalculated);

    useEffect(() => {
        onAreasCalculatedRef.current = onAreasCalculated;
    }, [onAreasCalculated]);

    const safeMapCall = useCallback((callback) => {
        const map = mapRef?.current;
        if (map && typeof map.isStyleLoaded === 'function' && map.isStyleLoaded()) {
            try {
                callback(map);
            } catch (error) {
                console.error('Error in map operation:', error);
            }
        }
    }, [mapRef]);

    const removeDrawingLayer = useCallback(() => {
        if (mapRef.current) {
            const map = mapRef.current;
            if (map.getLayer('drawing-line-layer')) {
                map.removeLayer('drawing-line-layer');
            }
            if (map.getSource('drawing-line')) {
                map.removeSource('drawing-line');
            }
        }
    }, [mapRef]);

    const removeCuttingLayer = useCallback(() => {
        if (mapRef.current) {
            const map = mapRef.current;
            if (map.getLayer('cutting-line-layer')) {
                map.removeLayer('cutting-line-layer');
            }
            if (map.getSource('cutting-line')) {
                map.removeSource('cutting-line');
            }
        }
    }, [mapRef]);

    const updateGeoJSON = useCallback(async () => {
        const analisisId = idAnalisis?.data?.ID_ANALISIS;
        if (!analisisId || !tipoAnalisis) {
            return;
        }

        const features = [];

        if (Array.isArray(lines)) {
            for (const line of lines) {
                if (!line || !Array.isArray(line.latlngs)) continue;
                const lineFeature = {
                    type: "Feature",
                    geometry: {
                        type: "LineString",
                        coordinates: line.latlngs.map(coord => [coord.lng, coord.lat]),
                    },
                    properties: {
                        ...(line.properties || {}),
                        type: "line",
                        lineId: line.id
                    },
                    id: `line-${line.id}`,
                };
                features.push(lineFeature);
            }
        }

        if (Array.isArray(bufferedLines)) {
            bufferedLines.forEach((bufferedLine, index) => {
                if (!bufferedLine || !bufferedLine.geometry) return;
                const bufferedFeature = {
                    type: "Feature",
                    geometry: bufferedLine.geometry,
                    properties: {
                        type: "buffer",
                        originalLineId: bufferedLine.originalLineId || `buffer-${index}`
                    },
                    id: `buffer-${index}-${uuidv4()}`,
                };
                features.push(bufferedFeature);
            });
        }

        if (Array.isArray(bufferedIntersections)) {
            bufferedIntersections.forEach((intersection, index) => {
                if (!intersection) return;
                const intersectionFeature = {
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        coordinates: [intersection],
                    },
                    properties: {
                        type: "bufferIntersection"
                    },
                    id: `bufferIntersection-${index}-${uuidv4()}`,
                };
                features.push(intersectionFeature);
            });
        }
        if (Array.isArray(polygons)) {
            polygons.forEach((polygon, index) => {
                if (!polygon) return;
                const props = polygonsProperties[index] || {};
                const polygonFeature = {
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        coordinates: [polygon.map(coord => [coord[1], coord[0]])],
                    },
                    properties: {
                        ...props,
                        type: "polygon",
                        accumulatedColors: props.accumulatedColors || {}
                    },
                    id: `polygon-${index}-${uuidv4()}`,
                };
                features.push(polygonFeature);
            });
        }

        if (Array.isArray(areasSuperpuestas)) {
            areasSuperpuestas.forEach((overlap, index) => {
                if (!overlap) return;
                const overlapFeature = {
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        coordinates: [overlap],
                    },
                    properties: {
                        type: "overlap"
                    },
                    id: `overlap-${index}-${uuidv4()}`,
                };
                features.push(overlapFeature);
            });
        }

        const newGeoJson = {
            type: "FeatureCollection",
            features: features,
        };

        const limites = {
            VELOCIDAD_KMH: { low: lowSpeed, med: medSpeed, high: highSpeed },
            ALTURA: { low: lowAltitude, med: medAltitude, high: highAltitude },
            DOSISREAL: { low: lowRealDose, med: medRealDose, high: highRealDose },
        };

        const isEmptyLimits = Object.values(limites).every(limit =>
            limit.low === 0 && limit.med === 0 && limit.high === 0
        );

        const formData = new FormData();
        formData.append('id_analisis', analisisId);
        const geoJsonBlob = new Blob([JSON.stringify(newGeoJson)], { type: 'application/json' });
        formData.append('geojson_file', geoJsonBlob, 'data.geojson');
        formData.append('tipo_analisis', tipoAnalisis);
        formData.append('limites', isEmptyLimits ? '{}' : JSON.stringify(limites));


        try {
            const response = await axios.post(`${API_BASE_PYTHON_SERVICE}mapping/update_geojson`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data?.status === 'success') {
                // Puedes manejar alguna acción adicional si es necesario
            } else {
                console.error('Error al actualizar GeoJSON:', response.data?.message);
            }
        } catch (error) {
            console.error('Error en la petición de actualización de GeoJSON:', error);
        }
    }, [
        lines,
        bufferedLines,
        bufferedIntersections,
        polygons,
        areasSuperpuestas,
        polygonsProperties,
        lowSpeed,
        medSpeed,
        highSpeed,
        lowAltitude,
        medAltitude,
        highAltitude,
        lowRealDose,
        medRealDose,
        highRealDose,
        idAnalisis,
        tipoAnalisis
    ]);

    const applyFilters = useCallback(() => {
        setPolygonsProperties((prevProps) =>
            prevProps.map((prop, index) => {
                if (!prop) return prop;
                let color = 'green';

                const speed = prop.VELOCIDAD;
                const altitude = prop.ALTURA;
                const realDose = prop.DOSISREAL;

                if (filterSpeed && typeof speed === 'number') {
                    if (speed < lowSpeed) {
                        color = 'green';
                    } else if (speed >= lowSpeed && speed < medSpeed) {
                        color = 'yellow';
                    } else if (speed >= medSpeed && speed <= highSpeed) {
                        color = 'orange';
                    } else if (speed > highSpeed) {
                        color = 'red';
                    }
                }

                if (filterAltitude && typeof altitude === 'number') {
                    if (altitude < lowAltitude) {
                        color = 'green';
                    } else if (altitude >= lowAltitude && altitude < medAltitude) {
                        color = 'yellow';
                    } else if (altitude >= medAltitude && altitude <= highAltitude) {
                        color = 'orange';
                    } else if (altitude > highAltitude) {
                        color = 'red';
                    }
                }

                if (filterRealDose && typeof realDose === 'number') {
                    if (realDose < lowRealDose) {
                        color = 'green';
                    } else if (realDose >= lowRealDose && realDose < medRealDose) {
                        color = 'yellow';
                    } else if (realDose >= medRealDose && realDose <= highRealDose) {
                        color = 'orange';
                    } else if (realDose > highRealDose) {
                        color = 'red';
                    }
                }

                return { ...prop, color };
            })
        );
    }, [
        filterSpeed, filterAltitude, filterRealDose,
        lowSpeed, medSpeed, highSpeed,
        lowAltitude, medAltitude, highAltitude,
        lowRealDose, medRealDose, highRealDose
    ]);

    const formatPolygon = useCallback((polygon) => {
        if (!Array.isArray(polygon) || polygon.length < 3) return [];
        const first = polygon[0];
        const last = polygon[polygon.length - 1];
        if (first[0] !== last[0] || first[1] !== last[1]) {
            polygon.push([first[0], first[1]]);
        }
        const formatted = polygon.map((coordPair) => {
            if (Array.isArray(coordPair) && coordPair.length >= 2) {
                return [coordPair[0], coordPair[1]];
            }
            return [];
        });
        return formatted;
    }, []);


    const fixCoordinate = ([a, b]) => {
        if (typeof a !== 'number' || typeof b !== 'number') {
            return null;
        }
        const latOk = a >= -90 && a <= 90;
        const lngOk = b >= -180 && b <= 180;
        if (latOk && lngOk) {
            return [a, b];
        }
        const latOk2 = b >= -90 && b <= 90;
        const lngOk2 = a >= -180 && a <= 180;
        if (latOk2 && lngOk2) {
            return [b, a];
        }
        return null;
    };

    const closeRing = (coords) => {
        if (!Array.isArray(coords) || coords.length === 0) return [];
        const first = coords[0];
        const last = coords[coords.length - 1];
        if (first[0] !== last[0] || first[1] !== last[1]) {
            coords.push([...first]);
        }
        while (coords.length < 4) {
            coords.push([...coords[0]]);
        }
        return coords;
    };


    const normalizeRing = (ring) => {
        const fixed = ring.map(fixCoordinate).filter(Boolean);
        const closed = closeRing(fixed);
        return closed.length >= 4 ? closed : [];
    };

    // Manejo de mensajes del worker
    useEffect(() => {
        workerRef.current = new Worker('Workers/dataWorker.js');
        workerRef.current.onmessage = (e) => {
            if (e.data.action === 'geoJsonDataProcessed') {
                const data = e.data.data || {};
                const createLatLngArray = (paths) => {
                    let latLngArray = [];
                    const processCoordinates = (coords) => {
                        if (Array.isArray(coords[0])) {
                            coords.forEach((coord) => {
                                processCoordinates(coord);
                            });
                        } else {
                            if (Array.isArray(coords) && coords.length === 2 &&
                                typeof coords[0] === 'number' && typeof coords[1] === 'number') {
                                const [lat, lng] = coords;
                                latLngArray.push({ lat, lng });
                            }
                        }
                    };
                    if (paths) processCoordinates(paths);
                    return latLngArray;
                };

                if (data.filtradas && data.filtradas.lines) {
                    const filtradasLines = data.filtradas.lines;
                    const filtradasPolygons = data.filtradas.polygons || [];
                    const lineIds = [];
                    const filtradasLinesWithEvents = filtradasLines
                        .map((line) => {
                            if (!line) return null;
                            let lineId = parseInt(line.id, 10);
                            if (isNaN(lineId)) {
                                lineId = nextLineIdRef.current++;
                            }
                            lineIds.push(lineId);
                            const latLngArray = createLatLngArray(line.paths);
                            if (latLngArray.length < 2) return null;
                            // Asignamos props con lineId para identificar la línea al eliminar
                            const properties = { ...line.properties, lineId };
                            return { id: lineId, latlngs: latLngArray, properties };
                        })
                        .filter((line) => line !== null);

                    const maxLineId = Math.max(...lineIds);
                    if (!isNaN(maxLineId) && nextLineIdRef.current <= maxLineId) {
                        nextLineIdRef.current = maxLineId + 1;
                    }

                    setLines(filtradasLinesWithEvents);
                    setIsKml(true);
                    setKmlPolygons(filtradasPolygons);
                    setIsFilteringLines(true);
                }

                if (data.noFiltradas && data.noFiltradas.lines) {
                    const noFiltradasLines = data.noFiltradas.lines;
                    const lineIds = [];
                    const noFiltradasLinesWithEvents = noFiltradasLines
                        .map((line) => {
                            if (!line) return null;
                            let lineId = parseInt(line.id, 10);
                            if (isNaN(lineId)) {
                                lineId = nextLineIdRef.current++;
                            }
                            lineIds.push(lineId);
                            const latLngArray = createLatLngArray(line.paths);
                            if (latLngArray.length < 2) return null;
                            // Aquí no tenemos props, pero si las hubiera, las agregamos
                            const properties = { lineId };
                            return { id: lineId, latlngs: latLngArray, properties };
                        })
                        .filter((line) => line !== null);

                    const maxLineId = Math.max(...lineIds);
                    if (!isNaN(maxLineId) && nextLineIdRef.current <= maxLineId) {
                        nextLineIdRef.current = maxLineId + 1;
                    }

                    setLineasNoFiltradas(noFiltradasLinesWithEvents);
                }

                if (data.lines && !data.noFiltradas) {
                    const { lines: dlines, polygons: dpolygons } = data;
                    const lineIds = [];
                    const linesWithEvents = dlines
                        .map((line) => {
                            if (!line) return null;
                            let lineId = parseInt(line.id, 10);
                            if (isNaN(lineId)) {
                                lineId = nextLineIdRef.current++;
                            }
                            lineIds.push(lineId);
                            const latLngArray = createLatLngArray(line.paths);
                            if (latLngArray.length < 2) return null;
                            const properties = { ...line.properties, lineId };
                            return { id: lineId, latlngs: latLngArray, properties };
                        })
                        .filter((line) => line !== null);

                    const maxLineId = Math.max(...lineIds);
                    if (!isNaN(maxLineId) && nextLineIdRef.current <= maxLineId) {
                        nextLineIdRef.current = maxLineId + 1;
                    }

                    setLines(linesWithEvents);
                    setIsKml(true);
                    const finalPolygons = [];
                    (dpolygons || []).forEach((dp) => {
                        if (!dp || !Array.isArray(dp.rings)) return;

                        dp.rings.forEach((maybeSubRing) => {
                            let realRing = maybeSubRing;
                            if (
                                Array.isArray(maybeSubRing) &&
                                maybeSubRing.length === 1 &&
                                Array.isArray(maybeSubRing[0])
                            ) {
                                realRing = maybeSubRing[0];
                            }

                            const normalized = normalizeRing(realRing);

                            if (normalized.length >= 4) {
                                finalPolygons.push(normalized);
                            }
                        });
                    });


                    setPolygons(finalPolygons);
                    setIsFilteringLines(false);
                }

                if (!data.lines && data.polygons) {
                    const dpolygons = data.polygons || [];
                    const formattedPolygons = dpolygons.map((poly, index) => {
                        if (!poly || !Array.isArray(poly.polygon) || !Array.isArray(poly.polygon[0])) return [];
                        const formatted = formatPolygon(poly.polygon[0]);
                        return formatted;
                    });

                    setPolygonsProperties(dpolygons.map((poly) => poly.properties || {}));
                    setPolygons(formattedPolygons);
                    setIsFilteringLines(false);
                }
            }
        };

        if (socket && socketSessionID) {
            socket.on(`${socketSessionID}:updateGeoJSONLayer`, (geojsonData) => {
                if (workerRef.current) {
                    workerRef.current.postMessage({
                        action: 'processGeoJsonData',
                        geojsonData,
                        type: tipoAnalisis,
                        activarEdicionInteractiva,
                    });
                }
            });
        }

        return () => {
            if (workerRef.current) {
                workerRef.current.terminate();
            }
            if (socket && socketSessionID) {
                socket.off(`${socketSessionID}:updateGeoJSONLayer`);
            }
        };
    }, [activarEdicionInteractiva, socket, socketSessionID, tipoAnalisis, formatPolygon]);


    // Actualizar el estado del worker cuando cambia activarEdicionInteractiva
    useEffect(() => {
        if (workerRef.current) {
            workerRef.current.postMessage({ action: 'setActivarEdicionInteractiva', activarEdicionInteractiva });
        }
    }, [activarEdicionInteractiva]);

    // Función para agregar un buffer a una línea
    const addBufferToLine = useCallback((line, width) => {
        try {
            if (!line || !Array.isArray(line.latlngs) || line.latlngs.length === 0) {
                return null;
            }
            const coordinates = line.latlngs.map((coord) => [coord.lng, coord.lat]);
            const lineString = {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: coordinates,
                },
                properties: line.properties || {},
            };
            const bufferWidth = (typeof width === 'number' && width > 0) ? width : 1;
            const bufferedLine = turfBuffer(lineString, bufferWidth, { units: 'meters' });
            if (
                !bufferedLine ||
                !bufferedLine.geometry ||
                !Array.isArray(bufferedLine.geometry.coordinates) ||
                bufferedLine.geometry.coordinates.length === 0
            ) {
                return null;
            }
            bufferedLine.originalLineId = line.id;
            return bufferedLine;
        } catch (error) {
            console.error('Error in addBufferToLine:', error);
            return null;
        }
    }, []);

    // Actualizar las líneas bufferizadas cuando cambia bufferValue o isBufferActive
    useEffect(() => {
        if (isBufferActive && Array.isArray(lines)) {
            const newBufferedLines = lines
                .map((line) => {
                    const buffered = addBufferToLine(line, parseFloat(bufferValue));

                    return buffered;
                })
                .filter((bufferedLine) => bufferedLine !== null);
            setBufferedLines(newBufferedLines);
            setAreasCalculated(false);
        } else {
            setBufferedLines([]);
            setBufferedIntersections([]);
        }
    }, [bufferValue, isBufferActive, addBufferToLine, lines]);

    // Normalizar un polígono (invertir lat/lng y asegurar que esté cerrado)
    const normalizePolygon = (polygon) => {
        if (!Array.isArray(polygon)) return null;
        const normalized = polygon.map(coordPair => {
            if (Array.isArray(coordPair) && coordPair.length >= 2) {
                return [coordPair[1], coordPair[0]];
            }
            return null;
        }).filter(coord => coord !== null);

        if (
            normalized.length >= 3 &&
            (normalized[0][0] !== normalized[normalized.length - 1][0] ||
                normalized[0][1] !== normalized[normalized.length - 1][1])
        ) {
            normalized.push([...normalized[0]]);
        }

        if (normalized.length < 4) {
            return null;
        }
        return normalized;
    };

    // Encontrar intersecciones entre polígonos
    const findIntersections = useCallback((polygons) => {
        const intersections = [];
        if (!Array.isArray(polygons)) return;
        polygons.forEach((poly1, i) => {
            const normalizedPoly1 = normalizePolygon(poly1);
            if (!normalizedPoly1) return;
            polygons.slice(i + 1).forEach((poly2) => {
                const normalizedPoly2 = normalizePolygon(poly2);
                if (!normalizedPoly2) return;
                try {
                    const turfPoly1 = turfPolygon([normalizedPoly1]);
                    const turfPoly2 = turfPolygon([normalizedPoly2]);
                    const intersection = turfIntersect(turfPoly1, turfPoly2);
                    if (intersection) {
                        if (intersection.geometry.type === 'MultiPolygon') {
                            intersection.geometry.coordinates.forEach((coords) => {
                                intersections.push(coords[0]);
                            });
                        } else if (intersection.geometry.type === 'Polygon') {
                            intersections.push(intersection.geometry.coordinates[0]);
                        }
                    }
                } catch (error) {
                    console.error('Error intersectando polígonos:', error);
                }
            });
        });
        setAreasSuperpuestas(intersections);
    }, []);

    // Calcular áreas no intersectadas
    const calculateNonIntersectedAreas = useCallback((polygons) => {
        if (!Array.isArray(polygons) || polygons.length === 0) return [];
        if (polygons.length === 1) {
            return [polygons[0]];
        }

        try {
            const unionOfAllPolygons = polygons.slice(1).reduce((acc, polygon) => {
                return turfUnion(acc, turfPolygon([polygon]));
            }, turfPolygon([polygons[0]]));

            const nonIntersected = [];
            polygons.forEach((polygon, index) => {
                const difference = turfDifference(turfPolygon([polygon]), unionOfAllPolygons);
                if (difference) {
                    nonIntersected.push(difference.geometry.coordinates);
                }
            });
            return nonIntersected;
        } catch (error) {
            console.error('Error calculando áreas no intersectadas:', error);
            return [];
        }
    }, []);

    // Calcular áreas no intersectadas cuando cambian los polígonos
    useEffect(() => {
        if (Array.isArray(polygons) && polygons.length > 0) {
            const newNonIntersectedAreas = calculateNonIntersectedAreas(polygons);
            setNonIntersectedAreas(newNonIntersectedAreas);
        }
    }, [polygons, calculateNonIntersectedAreas]);

    // Encontrar intersecciones cuando cambian los polígonos
    useEffect(() => {
        if (Array.isArray(polygons) && polygons.length > 0) {
            findIntersections(polygons);
        }
    }, [polygons, lines, isFilteringLines, findIntersections]);

    // Verificar y establecer filtros disponibles
    useEffect(() => {
        const hasSpeed = polygonsProperties.some((prop) => typeof prop?.VELOCIDAD === 'number');
        const hasAltitude = polygonsProperties.some((prop) => typeof prop?.ALTURA === 'number');
        const hasRealDose = polygonsProperties.some((prop) => typeof prop?.DOSISREAL === 'number');

        setAvailableFilters({
            speed: hasSpeed,
            altitude: hasAltitude,
            realDose: hasRealDose,
        });

    }, [polygonsProperties]);

    // Manejar el clic en una línea
    const handleLineClick = useCallback((e) => {
        const feature = e?.features?.[0];
        const lngLat = e?.lngLat;
        if (!feature || !lngLat) return;

        if (activeToolRef.current === 'delete') {
            const lineId = feature.properties?.lineId;
            if (typeof lineId !== 'number') return;
            const lineInState = lines.find((l) => l.id === lineId);
            if (lineInState) {
                setLines((prevLines) => prevLines.filter((l) => l.id !== lineInState.id));
                setActionHistory((prevHistory) => [...prevHistory, { type: 'delete', line: lineInState }]);
            }
            return;
        }

        const properties = feature.properties || {};
        const coordinates = feature.geometry.coordinates;
        const lineString = turfLineString(coordinates);
        const lengthKm = turfLength(lineString, { units: 'kilometers' });
        const lengthMiles = turfLength(lineString, { units: 'miles' });
        const lengthMeters = lengthKm * 1000;

        const propertyList =
            Object.keys(properties).length > 0
                ? Object.entries(properties).map(([key, value]) => (
                    <li key={key}><strong>{key}:</strong> {value !== undefined && value !== null ? String(value) : 'N/A'}</li>
                ))
                : null;

        const popupContent = (
            <div>
                {propertyList && (
                    <div>
                        <strong>Información de aplicación:</strong>
                        <ul>
                            {propertyList}
                        </ul>
                    </div>
                )}
                <div>
                    <strong>Tamaño línea aplicación:</strong>
                    <ul>
                        <li>{lengthKm.toFixed(3)} km</li>
                        <li>{lengthMiles.toFixed(3)} mi</li>
                        <li>{lengthMeters.toFixed(3)} m</li>
                    </ul>
                </div>
            </div>
        );

        setPopupInfo({ lngLat, content: popupContent });
    }, [lines]);

    // Calcular áreas aplicadas cuando cambian los polígonos
    useEffect(() => {
        if (!Array.isArray(polygons) || polygons.length === 0 || !Array.isArray(polygonsData) || polygonsData.length === 0) return;

        const calculateAreas = async () => {
            const analisisId = idAnalisis?.data?.ID_ANALISIS;
            if (!analisisId) {
                return;
            }

            const requestData = {
                bufferedLines: [],
                polygons: polygons.map((polygon) => ({
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            polygon.map(([lat, lon]) => [lon, lat]),
                        ],
                    },
                })),
                polygonsData: polygonsData.map((polygonData) => ({
                    type: polygonData.type,
                    geometry: polygonData.geometry,
                    properties: polygonData.properties,
                })),
                id_analisis: analisisId,
            };
            try {
                const response = await axios.post(`${API_BASE_PYTHON_SERVICE}mapping/calculate_areas`, requestData);
                setOnAreasCalculatedLoader(true);

                if (response.status === 200 && response.data && response.data.data) {
                    const {
                        areaSobreAplicada,
                        areaAplicada,
                        areaNoAplicada,
                        porcentajeDeVariacion,
                        areaAplicadaEnCalles,
                        areaAplicadaFueraDelLote,
                        dosisTotalDiferencia,
                        dosisTotalRealAplicada,
                    } = response.data.data;

                    if (typeof onAreasCalculatedRef.current === 'function') {
                        onAreasCalculatedRef.current({
                            areaSobreAplicada,
                            areaAplicada,
                            areaNoAplicada,
                            porcentajeDeVariacion,
                            areaAplicadaEnCalles,
                            areaAplicadaFueraDelLote,
                            dosisTotalRealAplicada,
                            dosisTotalDiferencia,
                        });

                    }
                }
            } catch (error) {
                console.error('Error al calcular áreas:', error);
            }
        };

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            calculateAreas();
        }, 500);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [polygons, polygonsData, idAnalisis, onAreasCalculatedRef, setOnAreasCalculatedLoader]);

    // Calcular promedios cuando cambian las propiedades de los polígonos
    useEffect(() => {
        if (!Array.isArray(polygons) || polygons.length === 0 || !Array.isArray(polygonsProperties) || polygonsProperties.length === 0) return;

        const totalSpeed = polygonsProperties.reduce((acc, curr) => acc + (typeof curr.VELOCIDAD === 'number' ? curr.VELOCIDAD : 0), 0);
        const totalAltitude = polygonsProperties.reduce((acc, curr) => acc + (typeof curr.ALTURA === 'number' ? curr.ALTURA : 0), 0);
        const totalRealDose = polygonsProperties.reduce((acc, curr) => acc + (typeof curr.DOSISREAL === 'number' ? curr.DOSISREAL : 0), 0);

        const promedioVelocidad = (polygonsProperties.length > 0) ? (totalSpeed / polygonsProperties.length).toFixed(3) : 0;
        const promedioAltura = (polygonsProperties.length > 0) ? (totalAltitude / polygonsProperties.length).toFixed(3) : 0;
        const promedioDosisReal = (polygonsProperties.length > 0) ? (totalRealDose / polygonsProperties.length).toFixed(3) : 0;


        if (typeof onPromediosCalculated === 'function') {
            onPromediosCalculated({
                promedioVelocidad,
                promedioAltura,
                promedioDosisReal,
            });

        }
    }, [polygons, polygonsProperties, onPromediosCalculated]);

    // Calcular intersecciones bufferizadas cuando cambian bufferedLines

    useEffect(() => {
        if (bufferedLines.length === 0 || polygonsData.length === 0) return;

        const calculateBufferedIntersections = (bufferedLines) => {
            let intersections = [];

            bufferedLines.forEach((buffer1, i) => {
                bufferedLines.slice(i + 1).forEach((buffer2) => {
                    try {
                        const intersection = turfIntersect(buffer1, buffer2);
                        if (intersection) {
                            const coords = intersection.geometry.coordinates;
                            if (intersection.geometry.type === 'MultiPolygon') {
                                coords.forEach((coord) => intersections.push(coord[0]));
                            } else {
                                intersections.push(coords[0]);
                            }
                        }
                    } catch (error) {
                        // Manejar error si es necesario
                    }
                });
            });
            setBufferedIntersections(intersections);

            const totalIntersectionArea = intersections.reduce(
                (acc, intersection) => acc + turfArea(turfPolygon([intersection])) / 10000,
                0
            );

            return totalIntersectionArea;
        };

        calculateBufferedIntersections(bufferedLines);

        const calculateAreas = async () => {
            try {
                const requestData = {
                    bufferedLines: bufferedLines.map((line) => ({
                        geometry: line.geometry,
                    })),
                    polygons: [],
                    polygonsData: polygonsData.map((polygonData) => ({
                        type: polygonData.type,
                        geometry: polygonData.geometry,
                        properties: polygonData.properties,
                    })),
                    id_analisis: idAnalisis.data.ID_ANALISIS,
                };

                const response = await axios.post(`${API_BASE_PYTHON_SERVICE}mapping/calculate_areas`, requestData);
                setOnAreasCalculatedLoader(true);

                if (response.status === 200 && response.data) {
                    const {
                        areaSobreAplicada,
                        areaAplicada,
                        areaNoAplicada,
                        porcentajeDeVariacion,
                        areaAplicadaEnCalles,
                        areaAplicadaFueraDelLote,
                        dosisTotalDiferencia,
                        dosisTotalRealAplicada,
                    } = response.data.data;

                    if (onAreasCalculated) {
                        onAreasCalculated({
                            areaSobreAplicada,
                            areaAplicada,
                            areaNoAplicada,
                            porcentajeDeVariacion,
                            areaAplicadaEnCalles,
                            areaAplicadaFueraDelLote,
                            dosisTotalRealAplicada,
                            dosisTotalDiferencia,
                        });
                    }

                    setAreasCalculated(true);
                } else {
                    console.error('Error al calcular áreas: Respuesta no válida del servidor');
                }
            } catch (error) {
                console.error('Error al calcular áreas:', error);
            }
        };

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            setOnAreasCalculatedLoader(false);
            calculateAreas();
        }, 2000);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [bufferedLines, polygonsData, idAnalisis]);

    const handleUndo = useCallback(() => {
        if (actionHistory.length > 0) {
            const lastAction = actionHistory[actionHistory.length - 1];
            let newLines = [...lines];
            switch (lastAction.type) {
                case 'cut':
                    newLines = lastAction.originalLines;
                    break;
                case 'draw':
                    newLines = newLines.filter((line) => line.id !== lastAction.line.id);
                    break;
                case 'delete':
                    newLines = [...newLines, lastAction.line];
                    break;
                default:
                    break;
            }
            setLines(newLines);
            setActionHistory(actionHistory.slice(0, -1));
        }
    }, [actionHistory, lines]);

    const deactivateTool = useCallback(() => {
        setActiveTool(null);
        if (currentToolCleanupRef.current) {
            currentToolCleanupRef.current();
            currentToolCleanupRef.current = null;
        }
        safeMapCall((map) => {
            try {
                if (map.getLayer('drawing-line-layer')) {
                    map.removeLayer('drawing-line-layer');
                }
                if (map.getSource('drawing-line')) {
                    map.removeSource('drawing-line');
                }
                if (map.getLayer('cutting-line-layer')) {
                    map.removeLayer('cutting-line-layer');
                }
                if (map.getSource('cutting-line')) {
                    map.removeSource('cutting-line');
                }
            } catch (error) {
                console.error('Error al desactivar herramienta:', error);
            }
        });
        // Reset drawing and cutting refs
        isDrawingRef.current = false;
        newLineCoordsRef.current = [];
        isCuttingRef.current = false;
        cutLineCoordsRef.current = [];
    }, [safeMapCall, setActiveTool]);

    const autoPanMapFunction = useCallback((e) => { // Cambié el nombre para evitar duplicación
        const map = mapRef?.current;
        if (!map || typeof map.getCanvas !== 'function') {
            return;
        }
        const canvas = map.getCanvas();
        if (!canvas) {
            return;
        }
        const rect = canvas.getBoundingClientRect();
        const edgeThreshold = 50;

        const mouseX = e.point.x;
        const mouseY = e.point.y;

        const panOffset = { x: 0, y: 0 };

        if (mouseX < edgeThreshold) {
            panOffset.x = -50;
        } else if (mouseX > rect.width - edgeThreshold) {
            panOffset.x = 50;
        }

        if (mouseY < edgeThreshold) {
            panOffset.y = -50;
        } else if (mouseY > rect.height - edgeThreshold) {
            panOffset.y = 50;
        }

        if (panOffset.x !== 0 || panOffset.y !== 0) {
            safeMapCall((m) => {
                m.panBy([panOffset.x, panOffset.y], { duration: 100 });
            });
        }
    }, [mapRef, safeMapCall]);

    const resetDrawing = useCallback(() => {
        if (mapRef.current) {
            const map = mapRef.current;
            isDrawingRef.current = false;
            newLineCoordsRef.current = [];
            removeDrawingLayer();
        }
    }, [mapRef, removeDrawingLayer]);

    const resetCutting = useCallback(() => {
        if (mapRef.current) {
            const map = mapRef.current;
            isCuttingRef.current = false;
            cutLineCoordsRef.current = [];
            removeCuttingLayer();
        }
    }, [mapRef, removeCuttingLayer]);

    useEffect(() => {

        if (currentToolCleanupRef.current) {
            currentToolCleanupRef.current();
            currentToolCleanupRef.current = null;
        }

        isDrawingRef.current = false;
        newLineCoordsRef.current = [];
        isCuttingRef.current = false;
        cutLineCoordsRef.current = [];

        const map = mapRef?.current;
        if (!map || typeof map.isStyleLoaded !== 'function' || !map.isStyleLoaded()) {
            return;
        }

        if (activeTool === 'draw') {
            const onMapClick = (e) => {
                const coords = [e.lngLat.lng, e.lngLat.lat];
                if (!isDrawingRef.current) {
                    isDrawingRef.current = true;
                    newLineCoordsRef.current = [coords];
                } else {
                    newLineCoordsRef.current.push(coords);
                }
                updateDrawingLayer(newLineCoordsRef.current);
            };

            const onMouseMove = (e) => {
                if (isDrawingRef.current && newLineCoordsRef.current.length > 0) {
                    const coords = [e.lngLat.lng, e.lngLat.lat];
                    const currentLine = [...newLineCoordsRef.current, coords];
                    updateDrawingLayer(currentLine);
                    autoPanMapFunction(e);
                    autoPanMap(e);

                }
            };

            const onRightClick = (e) => {
                e.preventDefault();

                if (!isDrawingRef.current || newLineCoordsRef.current.length < 2) {
                    return;
                }
                const lineObj = {
                    id: nextLineIdRef.current++,
                    latlngs: newLineCoordsRef.current.map((coord) => ({ lat: coord[1], lng: coord[0] })),
                    properties: { lineId: nextLineIdRef.current - 1 },
                };
                setLines((prevLines) => [...prevLines, lineObj]);
                setActionHistory((prevHistory) => [...prevHistory, { type: 'draw', line: lineObj }]);

                isDrawingRef.current = false;
                newLineCoordsRef.current = [];
                resetDrawing();
                updateDrawingLayer([]);
            };

            const updateDrawingLayer = (coordinates) => {
                safeMapCall((m) => {
                    const source = m.getSource('drawing-line');
                    if (source && source.setData) {
                        source.setData({
                            type: 'Feature',
                            geometry: {
                                type: 'LineString',
                                coordinates: coordinates,
                            },
                        });
                    } else {
                        if (!m.getSource('drawing-line')) {
                            m.addSource('drawing-line', {
                                type: 'geojson',
                                data: {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'LineString',
                                        coordinates: coordinates,
                                    },
                                },
                            });
                        }

                        if (!m.getLayer('drawing-line-layer')) {
                            m.addLayer({
                                id: 'drawing-line-layer',
                                type: 'line',
                                source: 'drawing-line',
                                paint: {
                                    'line-color': '#ff0000',
                                    'line-width': 2,
                                },
                            });
                        }
                    }
                });
            };

            map.on('click', onMapClick);
            map.on('contextmenu', onRightClick);
            map.on('mousemove', onMouseMove);

            currentToolCleanupRef.current = () => {
                map.off('click', onMapClick);
                map.off('contextmenu', onRightClick);
                map.off('mousemove', onMouseMove);
                removeDrawingLayer();
            };
        } else if (activeTool === 'cut') {
            const onCuttingMapClick = (e) => {
                const coords = [e.lngLat.lng, e.lngLat.lat];
                if (!isCuttingRef.current) {
                    isCuttingRef.current = true;
                    cutLineCoordsRef.current = [coords];
                } else {
                    cutLineCoordsRef.current.push(coords);
                    performCut();
                    isCuttingRef.current = false;
                    cutLineCoordsRef.current = [];
                    resetCutting();
                    updateCuttingLayer([]);
                }
                updateCuttingLayer(cutLineCoordsRef.current);
            };

            const onCuttingMouseMove = (e) => {
                if (isCuttingRef.current && cutLineCoordsRef.current.length > 0) {
                    const coords = [e.lngLat.lng, e.lngLat.lat];
                    const currentLine = [...cutLineCoordsRef.current, coords];
                    updateCuttingLayer(currentLine);
                    autoPanMapFunction(e);
                    autoPanMap(e);
                }
            };

            const performCut = () => {
                if (cutLineCoordsRef.current.length < 2) {
                    return;
                }
                const cutLineString = turfLineString(cutLineCoordsRef.current);
                const newLines = [];
                let cutSuccessful = false;
                const originalLines = [...lines];
                for (const line of lines) {
                    const latlngs = line.latlngs;
                    if (!Array.isArray(latlngs) || latlngs.length < 2) {
                        newLines.push(line);
                        continue;
                    }
                    const lineString = turfLineString(latlngs.map((coord) => [coord.lng, coord.lat]));
                    const intersections = turfLineIntersect(lineString, cutLineString);
                    if (intersections.features.length > 0) {
                        cutSuccessful = true;
                        const splitResult = turfLineSplit(lineString, cutLineString);
                        for (const f of splitResult.features) {
                            const newLineCoordsArr = f.geometry.coordinates.map((coord) => ({
                                lat: coord[1],
                                lng: coord[0],
                            }));
                            const newLineObj = {
                                id: nextLineIdRef.current++,
                                latlngs: newLineCoordsArr,
                                properties: { ...line.properties, lineId: nextLineIdRef.current - 1 },
                            };
                            newLines.push(newLineObj);
                        }
                    } else {
                        newLines.push(line);
                    }
                }

                if (cutSuccessful) {
                    setLines(newLines);
                    setActionHistory((prevHistory) => [...prevHistory, { type: 'cut', originalLines }]);
                }
            };

            const updateCuttingLayer = (coordinates) => {
                safeMapCall((m) => {
                    const source = m.getSource('cutting-line');
                    if (source && source.setData) {
                        source.setData({
                            type: 'Feature',
                            geometry: {
                                type: 'LineString',
                                coordinates: coordinates,
                            },
                        });
                    } else {
                        if (!m.getSource('cutting-line')) {
                            m.addSource('cutting-line', {
                                type: 'geojson',
                                data: {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'LineString',
                                        coordinates: coordinates,
                                    },
                                },
                            });
                        }

                        if (!m.getLayer('cutting-line-layer')) {
                            m.addLayer({
                                id: 'cutting-line-layer',
                                type: 'line',
                                source: 'cutting-line',
                                paint: {
                                    'line-color': '#0000ff',
                                    'line-width': 2,
                                    'line-dasharray': [2, 4],
                                },
                            });
                        }
                    }
                });
            };

            map.on('click', onCuttingMapClick);
            map.on('mousemove', onCuttingMouseMove);

            currentToolCleanupRef.current = () => {
                map.off('click', onCuttingMapClick);
                map.off('mousemove', onCuttingMouseMove);
                removeCuttingLayer();
            };
        } else {
            if (currentToolCleanupRef.current) {
                currentToolCleanupRef.current();
                currentToolCleanupRef.current = null;
            }
            safeMapCall((m) => {
                if (m.getLayer('drawing-line-layer')) {
                    m.removeLayer('drawing-line-layer');
                }
                if (m.getSource('drawing-line')) {
                    m.removeSource('drawing-line');
                }
                if (m.getLayer('cutting-line-layer')) {
                    m.removeLayer('cutting-line-layer');
                }
                if (m.getSource('cutting-line')) {
                    m.removeSource('cutting-line');
                }
            });
            isDrawingRef.current = false;
            newLineCoordsRef.current = [];
            isCuttingRef.current = false;
            cutLineCoordsRef.current = [];
        }
    }, [
        activeTool,
        mapRef,
        autoPanMapFunction,
        safeMapCall,
        resetDrawing,
        resetCutting,
        removeDrawingLayer,
        removeCuttingLayer
    ]);

    const autoPanMap = useCallback((e) => {
        const map = mapRef.current;
        if (!map) return;

        const canvas = map.getCanvas();
        const rect = canvas.getBoundingClientRect();
        const edgeThreshold = 50;

        const mouseX = e.point.x;
        const mouseY = e.point.y;

        const panOffset = { x: 0, y: 0 };

        if (mouseX < edgeThreshold) {
            panOffset.x = -50;
        } else if (mouseX > rect.width - edgeThreshold) {
            panOffset.x = 50;
        }

        if (mouseY < edgeThreshold) {
            panOffset.y = -50;
        } else if (mouseY > rect.height - edgeThreshold) {
            panOffset.y = 50;
        }

        if (panOffset.x !== 0 || panOffset.y !== 0) {
            map.panBy([panOffset.x, panOffset.y], { duration: 100 });
        }
    }, [mapRef]);

    useEffect(() => {
        applyFilters();
    }, [
        filterSpeed, filterAltitude, filterRealDose,
        lowSpeed, medSpeed, highSpeed,
        lowAltitude, medAltitude, highAltitude,
        lowRealDose, medRealDose, highRealDose,
        applyFilters
    ]);

    useEffect(() => {
        activeToolRef.current = activeTool;
    }, [activeTool]);

    const handleLabelClick = useCallback(
        (text, color) => {
            if (typeof setLabelsAndColors === 'function') {
                setLabelsAndColors({ text, color });
            }
        },
        [setLabelsAndColors]
    );

    useEffect(() => {
        if (isFilteringLines && lines.length > 0 && activarEdicionInteractiva) {
            setActiveFilter("aerialApplicationsTraslapeDrones");
            setIsFilteringLines(false);
        }
    }, [isFilteringLines, lines, activarEdicionInteractiva]);

    useEffect(() => {
        if (lines.length > 0) {
            updateGeoJSON();
        }
    }, [updateGeoJSON, lines]);

    // Definir si se está editando basado en la herramienta activa
    const isEditing = activeTool === 'draw' || activeTool === 'cut';

    return (
        <>
            <CommonMap
                center={mapCenter}
                zoom={zoom}
                polygons={polygons}
                setPolygons={setPolygons}
                lines={lines}
                points={null}
                hullPolygon={null}
                nonIntersectedAreas={nonIntersectedAreas}
                bufferedLines={bufferedLines}
                bufferedIntersections={bufferedIntersections}
                onLineHover={() => { console.log('Line hovered'); }}
                onLineMouseOut={() => { console.log('Line mouse out'); }}
                onLineClick={handleLineClick}
                polygonProperties={polygonsProperties}
                showIntersections={true}
                mapRef={mapRef}
                userId={userData.userId || null}
                stretchPoints={[]}
                lineasNoFiltradas={showUnfilteredLines ? lineasNoFiltradas : []}
                highlightedLote={highlightedLote}
                polygonsData={polygonsData}
                activeLotes={activeLotes}
                onLeaveLote={onLeaveLote}
                onSelectLote={onSelectLote}
                onHoverLote={onHoverLote}
                areasSuperpuestas={areasSuperpuestas}
                setImgLaflet={setImgLaflet}
                polygonsLayerRef={polygonsLayerRef}
                setLines={setLines}
                popupInfo={popupInfo}
                setPopupInfo={setPopupInfo}
                isEditing={isEditing} // Nueva prop añadida
            />

            {((Array.isArray(polygons) && polygons.length > 0) || (Array.isArray(lines) && lines.length > 0)) && (
                <BarIndicator
                    filterType={activeFilter ? activeFilter : 'aerialApplicationsTraslape'}
                    isHistory={false}
                    onLabelClick={handleLabelClick}
                    setLabelsAndColors={setLabelsAndColors}
                />
            )}

            <MapDialog
                isOpen={!!isFilterDialogOpen}
                onClose={() => {
                    if (typeof closeFilterDialog === 'function') closeFilterDialog();
                }}
                availableFilters={availableFilters}
                handleToggleFilter={(fName) => {
                    switch (fName) {
                        case 'VELOCIDAD':
                            setFilterSpeed(prev => !prev);
                            break;
                        case 'ALTURA':
                            setFilterAltitude(prev => !prev);
                            break;
                        case 'DOSISREAL':
                            setFilterRealDose(prev => !prev);
                            break;
                        default:
                            break;
                    }
                }}
                filterSpeed={filterSpeed}
                filterAltitude={filterAltitude}
                filterRealDose={filterRealDose}
                lowSpeed={lowSpeed}
                medSpeed={medSpeed}
                highSpeed={highSpeed}
                setLowSpeed={setLowSpeed}
                setMedSpeed={setMedSpeed}
                setHighSpeed={setHighSpeed}
                lowAltitude={lowAltitude}
                medAltitude={medAltitude}
                highAltitude={highAltitude}
                setLowAltitude={setLowAltitude}
                setMedAltitude={setMedAltitude}
                setHighAltitude={setHighAltitude}
                lowRealDose={lowRealDose}
                medRealDose={medRealDose}
                highRealDose={highRealDose}
                setLowRealDose={setLowRealDose}
                setMedRealDose={setMedRealDose}
                setHighRealDose={setHighRealDose}
            />

            <Snackbar
                open={!areasCalculated && Array.isArray(lines) && lines.length > 0}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert severity="warning" variant="filled" sx={{ width: '100%' }}>
                    <strong>Advertencia:</strong> Genera el buffer en las líneas para mostrar las áreas en el dashboard.
                </Alert>
            </Snackbar>
        </>
    );
};

export default AerialApplications;
