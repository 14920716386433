import React from 'react';
import {
    DataCardContainer,
    DataCardTitle,
    DataCardValue,
    MinMaxContainer,
    MinMaxValue,
} from './DataCardStyle';
import NoDataMessage from '../NoDataMessage/NoDataMessage';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const DataCard = ({ title, children, min, max, icon, color }) => {
    const isNoData =
        children === 'No hay datos' ||
        (typeof children === 'object' && children?.props?.message === 'No hay datos');
    let finalContent = children;

    if (!isNoData) {
        const numericValue = Number(children);

        if (!Number.isNaN(numericValue) && Number.isFinite(numericValue)) {
            finalContent = new Intl.NumberFormat('en-US').format(numericValue);
        }
    }

    return (
        <DataCardContainer>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '8px',
                }}
            >
                {icon && <span style={{ marginRight: '8px', color }}>{icon}</span>}
                <DataCardTitle>{title}</DataCardTitle>
            </div>

            <DataCardValue color={isNoData ? '#B0B0B0' : undefined}>
                {isNoData ? <NoDataMessage message="No hay datos" /> : finalContent}
            </DataCardValue>

            {min !== undefined && max !== undefined && (
                <MinMaxContainer>
                    <div style={{ textAlign: 'center' }}>
                        <ArrowDownwardIcon style={{ color: 'green' }} />
                        <MinMaxValue color="green">
                            {min !== 'N/A' ? Number(min).toFixed(2) : 'N/A'}
                        </MinMaxValue>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <ArrowUpwardIcon style={{ color: 'red' }} />
                        <MinMaxValue color="red">
                            {max !== 'N/A' ? Number(max).toFixed(2) : 'N/A'}
                        </MinMaxValue>
                    </div>
                </MinMaxContainer>
            )}
        </DataCardContainer>
    );
};

export default DataCard;
