import React, { useContext } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import navbarLogo from '../../assets/img/logo.png';
import {
    NavbarContainer,
    NavbarSectionLeft,
    NavbarSectionCentered,
    NavbarSectionRight,
    CompanyLogoImage,
    NavbarLogoImage,
} from './NavbarStyle';

const Navbar = ({ isSidebarOpen }) => {
    const { logo } = useContext(CompanyContext);

    return (
        <NavbarContainer isSidebarOpen={isSidebarOpen}>
            <NavbarSectionLeft>
                <CompanyLogoImage src={logo} alt="Logo de la Empresa" />
            </NavbarSectionLeft>
            <NavbarSectionCentered>
                <NavbarLogoImage src={navbarLogo} alt="Logo del Navbar" />
            </NavbarSectionCentered>
            <NavbarSectionRight>
            </NavbarSectionRight>
        </NavbarContainer>
    );
};

export default Navbar;
