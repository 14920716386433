// analysis/cosechaMecanica/cosechaMecanicaDataCards.jsx
import React from 'react';
import DataCard from "../../components/CardData/DataCard";
import { displayValue } from "../../utils/generalUtils";
import { styled } from '@mui/system';

const CategoryContainer = styled('div')({
    marginTop: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    width: '100%',
});

const CategoryTitle = styled('h3')({
    fontSize: '1.2em',
    fontWeight: '600',
    color: '#fff',
    backgroundColor: '#1565C0',
    padding: '10px',
    borderRadius: '4px',
    marginBottom: '20px',
    textAlign: 'center',
    textTransform: 'uppercase'
});

const CardsRow = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px,1fr))',
    gap: '20px',
    width: '100%',
});

function renderCategory(categoryTitle, cards) {
    return (
        <CategoryContainer>
            <CategoryTitle>{categoryTitle}</CategoryTitle>
            <CardsRow>
                {cards.map((card, index) => (
                    <DataCard key={index} title={card.title}>
                        {card.value}
                    </DataCard>
                ))}
            </CardsRow>
        </CategoryContainer>
    );
}

export const renderCosechaMecanicaDataCards = (validations, datosAnalisis, indicadores) => {

    const variosCards = [
        { title: "Equipo", value: datosAnalisis.nombreMaquina?.valor ? displayValue(datosAnalisis.nombreMaquina.valor) : "No hay datos" },
        { title: "Hora Inicio (H)", value: datosAnalisis.horaInicio?.valor ? displayValue(datosAnalisis.horaInicio.valor) : "No hay datos" },
        { title: "Hora Fin (H)", value: datosAnalisis.horaFin?.valor ? displayValue(datosAnalisis.horaFin.valor) : "No hay datos" },
        { title: "Tiempo total (H)", value: datosAnalisis.tiempoTotalActividad?.valor ? displayValue(datosAnalisis.tiempoTotalActividad.valor) : "No hay datos" },
        { title: "Combustible Gal/H", value: datosAnalisis.consumoCombustible?.valor ? displayValue(datosAnalisis.consumoCombustible.valor) : "No hay datos" },
        { title: "Calidad GPS", value: datosAnalisis.calidadGps?.valor ? displayValue(datosAnalisis.calidadGps.valor) : "No hay datos" },
        { title: "Velocidad Km/H", value: datosAnalisis.promedioVelocidad?.valor ? displayValue(datosAnalisis.promedioVelocidad.valor) : "No hay datos" },
        { title: "RPM", value: datosAnalisis.rpm?.valor ? displayValue(datosAnalisis.rpm.valor) : "No hay datos" },
        { title: "TCH", value: datosAnalisis.tch?.valor ? displayValue(datosAnalisis.tch.valor) : "No hay datos" },
        { title: "TAH", value: datosAnalisis.tah?.valor ? displayValue(datosAnalisis.tah.valor) : "No hay datos" },
        { title: "Presión Cortador Base (Bar)", value: datosAnalisis.presionCortadorBase?.valor ? displayValue(datosAnalisis.presionCortadorBase.valor) : "No hay datos" },
        { title: "Piloto Automático", value: indicadores.porcentajeAreaPilotoCm ? displayValue(indicadores.porcentajeAreaPilotoCm) : "No hay datos" },
        { title: "Auto Tracket", value: indicadores.porcentajeAreaAutoTrackerCm ? displayValue(indicadores.porcentajeAreaAutoTrackerCm) : "No hay datos" },
        { title: "Corte Base", value: indicadores.porcentajeModoCortadorBaseCm ? displayValue(indicadores.porcentajeModoCortadorBaseCm) : "No hay datos" },
    ];

    return (
        <>
            {renderCategory("Indicadores Varios", variosCards)}
        </>
    );
};
