import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';

export const StyledCard = styled(Card)(({ size }) => {
    let widthValue = '100%';

    if (size === 'half') {
        widthValue = 'calc(50% - 16px)';
    }

    return {
        margin: '8px',
        width: widthValue,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'visible',
        height: '400px',
        maxWidth: '400px'
    };
});
