// ChartCard.jsx
import React, { useState, useEffect } from 'react';
import { Typography, IconButton } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { BasicTooltip } from '@nivo/tooltip';

import { StyledCard } from './ChartCardStyle';
import NoDataMessage from '../NoDataMessage/NoDataMessage';

/**
 * Componente genérico para renderizar un chart con Nivo (line, pie, bar).
 * 'title' => título
 * 'data' => array con { name, value }
 * 'type' => 'line' | 'pie' | 'bar'
 */
const ChartCard = ({
                       title,
                       data = [],
                       type = 'line',
                       color,
                       colors,
                       icon,
                       min,
                       max,
                       size = 'full',
                       children,
                   }) => {
    const [expanded, setExpanded] = useState(false);
    const [hasData, setHasData] = useState(true);

    useEffect(() => {
        if (
            !data ||
            data.length === 0 ||
            data.some((item) => item.value == null || item.name == null)
        ) {
            setHasData(false);
        } else {
            setHasData(true);
        }
    }, [data]);

    const handleToggleExpand = () => setExpanded(!expanded);

    const formatValue = (value) => Number(value).toFixed(2);

    const getMaxYValue = () => {
        if (data.length === 0) return 0;
        const maxValue = Math.max(...data.map((item) => item.value));
        return maxValue + maxValue * 0.3;
    };

    // Tema de estilos
    const nivoTheme = {
        tooltip: {
            container: {
                background: '#fff',
                color: '#333',
                fontSize: '14px',
                borderRadius: '4px',
                boxShadow: '0 3px 9px rgba(0, 0, 0, 0.5)',
                zIndex: 9999999,
            },
        },
        axis: {
            ticks: {
                text: { fontSize: '12px', fill: '#555' },
            },
            legend: {
                text: { fontSize: '14px', fill: '#333' },
            },
        },
    };

    const renderChart = () => {
        if (!hasData) {
            return <NoDataMessage message="No hay datos" />;
        }

        switch (type) {
            case 'line':
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            width: '100%',
                            height: '300px',
                            maxHeight: '300px',
                        }}
                    >
                        <div style={{ width: '85%', height: '90%' }}>
                            <ResponsiveLine
                                data={[
                                    {
                                        id: title,
                                        data: data.map((item) => ({
                                            x: item.name,
                                            y: formatValue(item.value),
                                        })),
                                    },
                                ]}
                                margin={{ top: 20, right: 20, bottom: 60, left: 60 }}
                                xScale={{ type: 'point' }}
                                yScale={{
                                    type: 'linear',
                                    min: 0,
                                    max: getMaxYValue(),
                                    stacked: false,
                                    reverse: false,
                                }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    orient: 'bottom',
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 45,
                                    legendOffset: 36,
                                    legendPosition: 'middle',
                                }}
                                axisLeft={{
                                    orient: 'left',
                                    tickSize: 5,
                                    tickPadding: 5,
                                    legendOffset: -40,
                                    legendPosition: 'middle',
                                }}
                                enableArea
                                areaOpacity={0.3}
                                pointSize={10}
                                pointColor={{ theme: 'background' }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: 'serieColor' }}
                                useMesh
                                tooltip={({ point }) => (
                                    <BasicTooltip
                                        id={<strong>{point.data.xFormatted}</strong>}
                                        value={point.data.yFormatted}
                                        enableChip
                                        color={point.color}
                                    />
                                )}
                                colors={color ? [color] : { scheme: 'category10' }}
                                theme={nivoTheme}
                                animate
                            />
                        </div>
                    </div>
                );

            case 'bar':
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            width: '100%',
                            height: '300px',
                            maxHeight: '300px',
                        }}
                    >
                        <div style={{ width: '85%', height: '90%' }}>
                            <ResponsiveBar
                                data={data}
                                keys={['value']}
                                indexBy="name"
                                margin={{ top: 20, right: 20, bottom: 60, left: 60 }}
                                padding={0.3}
                                valueScale={{ type: 'linear' }}
                                indexScale={{ type: 'band', round: true }}
                                colors={color ? [color] : { scheme: 'category10' }}
                                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    orient: 'bottom',
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 45,
                                    legendOffset: 36,
                                    legendPosition: 'middle',
                                }}
                                axisLeft={{
                                    orient: 'left',
                                    tickSize: 5,
                                    tickPadding: 5,
                                    legendOffset: -40,
                                    legendPosition: 'middle',
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                enableGridY
                                theme={nivoTheme}
                                tooltip={({ indexValue, value, color: c }) => (
                                    <BasicTooltip
                                        id={<strong>{indexValue}</strong>}
                                        value={formatValue(value)}
                                        enableChip
                                        color={c}
                                    />
                                )}
                                animate
                            />
                        </div>
                    </div>
                );

            case 'pie':
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            width: '100%',
                            height: '300px',
                            maxHeight: '300px',
                        }}
                    >
                        <div style={{ width: '85%', height: '90%' }}>
                            <ResponsivePie
                                data={data}
                                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                activeOuterRadiusOffset={8}
                                borderWidth={1}
                                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                arcLinkLabelsSkipAngle={10}
                                arcLinkLabelsTextColor="#333333"
                                arcLinkLabelsThickness={2}
                                arcLinkLabelsColor={{ from: 'color' }}
                                arcLabelsSkipAngle={10}
                                arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                                colors={colors || { scheme: 'category10' }}
                                theme={nivoTheme}
                                tooltip={({ datum }) => (
                                    <BasicTooltip
                                        id={<strong>{datum.label}</strong>}
                                        value={formatValue(datum.value)}
                                        enableChip
                                        color={datum.color}
                                    />
                                )}
                                animate
                            />
                        </div>
                    </div>
                );

            default:
                return <NoDataMessage message="Tipo de gráfico no soportado" />;
        }
    };

    return (
        <StyledCard size={size}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '8px',
                }}
            >
                <Typography variant="h6">{title}</Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {(type === 'line' || type === 'bar') && (
                        <IconButton onClick={handleToggleExpand}>
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    )}
                    {icon && <span style={{ marginLeft: '8px' }}>{icon}</span>}
                </div>
            </div>

            {renderChart()}

            {/* Mostrar min y max si hay datos */}
            {hasData && min !== undefined && max !== undefined && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '10px',
                        padding: '0 16px 8px',
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <ArrowDownwardIcon style={{ color: 'green' }} />
                        <div style={{ fontSize: '12px', color: 'green' }}>
                            {min !== 'N/A' ? Number(min).toFixed(2) : 'N/A'}
                        </div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <ArrowUpwardIcon style={{ color: 'red' }} />
                        <div style={{ fontSize: '12px', color: 'red' }}>
                            {max !== 'N/A' ? Number(max).toFixed(2) : 'N/A'}
                        </div>
                    </div>
                </div>
            )}
        </StyledCard>
    );
};

export default ChartCard;
