// Mapping.jsx

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';

import { useSocket } from '../../context/SocketContext';
import * as turf from '@turf/turf';
import BarIndicator from "../../components/BarIndicator/BarIndicator";
import { toast } from 'react-toastify';
import CommonMap from '../../components/CommonMap/CommonMap';
import MapDialog from '../../components/MapDialog/MapDialog';
import { API_BASE_PYTHON_SERVICE } from '../../utils/config';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';


const convertTimeToDecimalHours = (time) => {
    try {
        const parts = time.split(' ');
        let days = 0;
        let timePart = time;

        if (parts.length === 3 && parts[1] === 'days') {
            days = parseInt(parts[0], 10);
            timePart = parts[2];
        }

        const timeParts = timePart.split(':');
        const hours = parseInt(timeParts[0], 10) || 0;
        const minutes = timeParts[1] ? parseInt(timeParts[1], 10) / 60 : 0;
        const seconds = timeParts[2] ? parseInt(timeParts[2], 10) / 3600 : 0;

        return days * 24 + hours + minutes + seconds;
    } catch (error) {
        console.error('Error en convertTimeToDecimalHours:', error);
        return 0;
    }
};

const Mapping = ({
                     onAreaCalculated,
                     percentageAutoPilot,
                     progressFinish,
                     idAnalisis,
                     tipoAnalisis,
                     highlightedLote,
                     polygonsData,
                     activeLotes,
                     onSelectLote,
                     onLeaveLote,
                     onHoverLote,
                     closeFilterDialog,
                     isFilterDialogOpen,
                     setImgLaflet,
                     mapRef,
                     polygonsLayerRef,
                     setLabelsAndColors,
                     setTipoMapeo
                 }) => {

    const userData = JSON.parse(localStorage.getItem("userData") || '{}');
    const socketContext = useSocket();
    const { socket, socketSessionID } = socketContext;

    const [hullPolygon, setHullPolygon] = useState(null);
    const [pilotAutoPercentage, setPilotAutoPercentage] = useState(0);
    const [autoTracketPercentage, setAutoTracketPercentage] = useState(0);
    const [activeFilter, setActiveFilter] = useState(null);
    const [points, setPoints] = useState([]);
    const [filteredPoints, setFilteredPoints] = useState([]);
    const [mapCenter, setMapCenter] = useState([0, 0]);
    const [filterAutoPilot, setFilterAutoPilot] = useState(false);
    const [filterAutoTracket, setFilterAutoTracket] = useState(false);
    const [mapBounds, setMapBounds] = useState(null);
    const [openIndicator, setOpenIndicator] = useState(null);

    const [lowSpeed, setLowSpeed] = useState(0);
    const [medSpeed, setMedSpeed] = useState(0);
    const [highSpeed, setHighSpeed] = useState(0);

    const [lowGpsQuality, setLowGpsQuality] = useState(0);
    const [medGpsQuality, setMedGpsQuality] = useState(0);
    const [highGpsQuality, setHighGpsQuality] = useState(0);

    const [lowFuel, setLowFuel] = useState(0);
    const [medFuel, setMedFuel] = useState(0);
    const [highFuel, setHighFuel] = useState(0);

    const [lowRpm, setLowRpm] = useState(0);
    const [medRpm, setMedRpm] = useState(0);
    const [highRpm, setHighRpm] = useState(0);

    const [lowCutterBase, setLowCutterBase] = useState(0);
    const [medCutterBase, setMedCutterBase] = useState(0);
    const [highCutterBase, setHighCutterBase] = useState(0);

    const [zoom, setZoom] = useState(3);

    const [filterSpeed, setFilterSpeed] = useState(false);
    const [filterGpsQuality, setFilterGpsQuality] = useState(false);
    const [filterFuel, setFilterFuel] = useState(false);
    const [filterRpm, setFilterRpm] = useState(false);
    const [filterCutterBase, setFilterCutterBase] = useState(false);
    const [filterModeCutterBase, setFilterModeCutterBase] = useState(false);
    const [isMapDialogOpen, setIsMapDialogOpen] = useState(false);
    const [mapKey, setMapKey] = useState(Date.now());
    const workerRef = useRef(null);

    const [polygon, setPolygon] = useState([]);
    const [outsidePolygon, setOutsidePolygon] = useState([]);
    const [isAreaDataCalculated, setIsAreaDataCalculated] = useState(false);
    const [toastShown, setToastShown] = useState(false);
    const [areaData, setAreaData] = useState({
        polygonArea: null,
        outsidePolygonArea: null,
        areaDifference: null
    });

    const [geoJson, setGeoJson] = useState({
        type: "FeatureCollection",
        features: []
    });


    const [formData, setFormData] = useState({
        filterAutoPilot: false,
        filterAutoTracket: false,
        filterModeCutterBase: false,
        filterSpeed: false,
        lowSpeed: 0,
        medSpeed: 0,
        highSpeed: 0,
        filterGpsQuality: false,
        lowGpsQuality: 0,
        medGpsQuality: 0,
        highGpsQuality: 0,
        filterFuel: false,
        lowFuel: 0,
        medFuel: 0,
        highFuel: 0,
        filterRpm: false,
        lowRpm: 0,
        medRpm: 0,
        highRpm: 0,
        filterCutterBase: false,
        lowCutterBase: 0,
        medCutterBase: 0,
        highCutterBase: 0,
        idAnalisis: 0
    });
    const [percentage, setPercentage] = useState({
        autoTracket: null,
        autoPilot: null,
        totalEfficiency: null
    });
    const [availableFilters, setAvailableFilters] = useState({
        speed: false,
        gpsQuality: false,
        fuel: false,
        rpm: false,
        cutterBase: false,
        autoPilot: false,
        autoTracket: false,
        modeCutterBase: false,
    });

    const [isMapButtonDisabled, setIsMapButtonDisabled] = useState(!progressFinish);
    const [originalPoints, setOriginalPoints] = useState([]);
    const [appliedAnalyses, setAppliedAnalyses] = useState([]);

    // Función para convertir los puntos a GeoJSON
    const convertToGeoJSON = useCallback(() => {
        try {
            const geojson = {
                type: "FeatureCollection",
                features: []
            };

            // Convertir puntos filtrados
            filteredPoints.forEach(point => {
                geojson.features.push({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [point.geometry.coordinates[0], point.geometry.coordinates[1]]
                    },
                    properties: {
                        ...point.properties,
                        ...appliedAnalyses.reduce((acc, analysis) => {
                            acc[analysis.type.toLowerCase() + 'Color'] = point.color || '#000000';
                            return acc;
                        }, {})
                    }
                });
            });
            return geojson;
        } catch (error) {
            console.error('Error en convertToGeoJSON:', error);
            return {
                type: "FeatureCollection",
                features: []
            };
        }
    }, [filteredPoints, appliedAnalyses]);

    useEffect(() => {
        try {
            const calculateArea = (polygonCoords) => {
                if (!polygonCoords || polygonCoords.length < 4) {
                    console.error("Polygon must have at least 4 positions.");
                    return 0;
                }
                const firstPoint = polygonCoords[0];
                const lastPoint = polygonCoords[polygonCoords.length - 1];
                if (firstPoint[0] !== lastPoint[0] || firstPoint[1] !== lastPoint[1]) {
                    console.error("The first and last position of the Polygon must be equal.");
                    polygonCoords.push(firstPoint);
                }

                const turfPolygonObj = turf.polygon([polygonCoords]);
                const areaInSquareMeters = turf.area(turfPolygonObj);
                return areaInSquareMeters / 10000;
            };

            if (polygon.length > 0 && outsidePolygon.length > 0) {
                const turfPolygonCoords = transformPolygonCoords(polygon);
                const turfOutsidePolygonCoords = transformPolygonCoords(outsidePolygon);

                const polygonArea = calculateArea(turfPolygonCoords.flat());
                const outsidePolygonArea = calculateArea(turfOutsidePolygonCoords.flat());
                const areaDifference = Math.abs(polygonArea - outsidePolygonArea);

                setAreaData({
                    polygonArea,
                    outsidePolygonArea,
                    areaDifference,
                });
                setIsAreaDataCalculated(true);

                onAreaCalculated?.(polygonArea, outsidePolygonArea, areaDifference);
            }
        } catch (error) {
            console.error('Error en useEffect para calcular áreas:', error);
        }
    }, [polygon, outsidePolygon, onAreaCalculated]);

    useEffect(() => {
        try {
            if (idAnalisis && typeof idAnalisis.then === 'function') {
                idAnalisis.then((resultado) => {
                    if (resultado && resultado.data && resultado.data.ID_ANALISIS) {
                        setFormData((prevFormData) => ({
                            ...prevFormData,
                            idAnalisis: resultado.data.ID_ANALISIS,
                        }));
                    } else {
                        console.error("Resultado de idAnalisis no tiene la propiedad 'data.ID_ANALISIS'.", resultado);
                    }
                }).catch(error => {
                    console.error("Error al obtener idAnalisis:", error);
                });
            }
        } catch (error) {
            console.error('Error en useEffect para idAnalisis:', error);
        }
    }, [idAnalisis]);

    useEffect(() => {
        try {
            if (toastShown) {
                toast.warn('No se encontraron puntos para el polígono brindado.');
            }
        } catch (error) {
            console.error('Error en useEffect para mostrar toast:', error);
        }
    }, [toastShown]);

    // Función para obtener color basado en tipo de análisis y valor
    const getColorForAnalysis = useCallback((type, value) => {
        try {
            let color = 'green';

            switch (type) {
                case 'VELOCIDAD_KMH':
                    if (value < lowSpeed) {
                        color = 'green';
                    } else if (value >= lowSpeed && value < medSpeed) {
                        color = 'yellow';
                    } else if (value >= medSpeed && value <= highSpeed) {
                        color = 'orange';
                    } else {
                        color = 'red';
                    }
                    break;
                case 'CALIDAD_GPS':
                    if (value < lowGpsQuality) {
                        color = 'green';
                    } else if (value >= lowGpsQuality && value < medGpsQuality) {
                        color = 'yellow';
                    } else if (value >= medGpsQuality && value <= highGpsQuality) {
                        color = 'orange';
                    } else {
                        color = 'red';
                    }
                    break;
                case 'COMBUSTIBLE':
                    if (value < lowFuel) {
                        color = 'green';
                    } else if (value >= lowFuel && value < medFuel) {
                        color = 'yellow';
                    } else if (value >= medFuel && value <= highFuel) {
                        color = 'orange';
                    } else {
                        color = 'red';
                    }
                    break;
                case 'RPM':
                    if (value < lowRpm) {
                        color = 'green';
                    } else if (value >= lowRpm && value < medRpm) {
                        color = 'yellow';
                    } else if (value >= medRpm && value <= highRpm) {
                        color = 'orange';
                    } else {
                        color = 'red';
                    }
                    break;
                case 'PRESION_DE_CORTADOR_BASE_BAR':
                    if (value < lowCutterBase) {
                        color = 'green';
                    } else if (value >= lowCutterBase && value < medCutterBase) {
                        color = 'yellow';
                    } else if (value >= medCutterBase && value <= highCutterBase) {
                        color = 'orange';
                    } else {
                        color = 'red';
                    }
                    break;
                case 'PILOTO_AUTOMATICO':
                    color = value === 1 ? 'green' : 'red'; // 1 == 'Engaged'
                    break;
                case 'AUTO_TRACKET':
                    color = value === 1 ? 'green' : 'red'; // 1 == 'Engaged'
                    break;
                case 'MODO_DE_CORTE_BASE':
                    color = value === 1 ? 'green' : 'red'; // 1 == 'Automatic'
                    break;
                default:
                    color = 'green';
            }
            return color;
        } catch (error) {
            console.error(`Error en getColorForAnalysis para tipo ${type} y valor ${value}:`, error);
            return 'green';
        }
    }, [
        lowSpeed, medSpeed, highSpeed,
        lowGpsQuality, medGpsQuality, highGpsQuality,
        lowFuel, medFuel, highFuel,
        lowRpm, medRpm, highRpm,
        lowCutterBase, medCutterBase, highCutterBase
    ]);

    const applyFilters = useCallback((pointsToFilter = originalPoints) => {
        try {
            const analyses = [
                {
                    type: 'VELOCIDAD_KMH',
                    active: filterSpeed,
                    values: [lowSpeed, medSpeed, highSpeed],
                    colors: ['green', 'yellow', 'orange', 'red'],
                },
                {
                    type: 'CALIDAD_GPS',
                    active: filterGpsQuality,
                    values: [lowGpsQuality, medGpsQuality, highGpsQuality],
                    colors: ['green', 'yellow', 'orange', 'red'],
                },
                {
                    type: 'COMBUSTIBLE',
                    active: filterFuel,
                    values: [lowFuel, medFuel, highFuel],
                    colors: ['green', 'yellow', 'orange', 'red'],
                },
                {
                    type: 'RPM',
                    active: filterRpm,
                    values: [lowRpm, medRpm, highRpm],
                    colors: ['green', 'yellow', 'orange', 'red'],
                },
                {
                    type: 'PRESION_DE_CORTADOR_BASE_BAR',
                    active: filterCutterBase,
                    values: [lowCutterBase, medCutterBase, highCutterBase],
                    colors: ['green', 'yellow', 'orange', 'red'],
                },
                {
                    type: 'PILOTO_AUTOMATICO',
                    active: filterAutoPilot,
                    values: [0, 1], // 0: Not Engaged, 1: Engaged
                    colors: ['red', 'green'],
                    isBoolean: true,
                },
                {
                    type: 'AUTO_TRACKET',
                    active: filterAutoTracket,
                    values: [0, 1], // 0: Not Engaged, 1: Engaged
                    colors: ['red', 'green'],
                    isBoolean: true,
                },
                {
                    type: 'MODO_DE_CORTE_BASE',
                    active: filterModeCutterBase,
                    values: [0, 1], // 0: Manual, 1: Automatic
                    colors: ['red', 'green'],
                    isBoolean: true,
                },
            ];

            // Filtrar los puntos basados en los filtros activos
            const filtered = pointsToFilter.map((point) => {
                let color = 'green';
                analyses.forEach(analysis => {

                    if (analysis.active && point.properties[analysis.type] != null) {
                        const value = point.properties[analysis.type];
                        let analysisColor = 'green';

                        if (analysis.isBoolean) {
                            analysisColor = getColorForAnalysis(analysis.type, value);
                        } else {
                            analysisColor = getColorForAnalysis(analysis.type, value);
                        }
                        color = analysisColor;
                    }
                });

                return { ...point, color };
            });

            setFilteredPoints(filtered);

            const newAppliedAnalyses = analyses
                .filter(analysis => analysis.active)
                .map(analysis => ({ type: analysis.type, colorProperty: `${analysis.type.toLowerCase()}Color` }));

            setAppliedAnalyses(newAppliedAnalyses);
        } catch (error) {
            console.error('Error en applyFilters:', error);
        }
    }, [
        filterSpeed, lowSpeed, medSpeed, highSpeed,
        filterGpsQuality, lowGpsQuality, medGpsQuality, highGpsQuality,
        filterFuel, lowFuel, medFuel, highFuel,
        filterRpm, lowRpm, medRpm, highRpm,
        filterCutterBase, lowCutterBase, medCutterBase, highCutterBase,
        filterAutoPilot,
        filterAutoTracket,
        filterModeCutterBase,
        getColorForAnalysis,
        originalPoints
    ]);


    useEffect(() => {
        try {
            const worker = new Worker('Workers/dataWorker.js');
            workerRef.current = worker;

            worker.onmessage = (e) => {
                try {
                    if (e.data.action === 'geoJsonDataProcessed') {
                        const { points: newPoints, polygon: newPolygon, outsidePolygon: newOutsidePolygon } = e.data.data;
                        setPoints(newPoints || []);
                        setOriginalPoints(newPoints || []);
                        setPolygon(newPolygon || []);
                        setOutsidePolygon(newOutsidePolygon || []);
                        applyFilters(newPoints || []);
                    }
                } catch (error) {
                    console.error('Error al procesar mensaje del worker:', error);
                }
            };

            if (socket && socket.on) {
                const handleUpdateGeoJSONLayer = (data) => {
                    try {
                        if (data) {
                            // Eliminada la lógica relacionada con GeoTIFF
                            // Directamente procesar los datos GeoJSON
                            worker.postMessage({ action: 'processGeoJsonData', geojsonData: data, type: tipoAnalisis });
                        } else {
                            console.warn('handleUpdateGeoJSONLayer: Datos recibidos son null o undefined.');
                        }
                    } catch (error) {
                        console.error('Error en handleUpdateGeoJSONLayer:', error);
                    }
                };

                // Escucha el evento usando el sessionID específico
                socket.on(`${socketSessionID}:updateGeoJSONLayer`, handleUpdateGeoJSONLayer);

                return () => {
                    try {
                        worker.terminate();
                        socket.off(`${socketSessionID}:updateGeoJSONLayer`, handleUpdateGeoJSONLayer);
                    } catch (error) {
                        console.error('Error al limpiar el worker o los listeners de socket:', error);
                    }
                };
            }
        } catch (error) {
            console.error('Error en useEffect para el worker y socket:', error);
        }
    }, [socket, tipoAnalisis, socketSessionID, applyFilters]);

    useEffect(() => {
        try {
            localStorage.setItem('formData', JSON.stringify(formData));
        } catch (error) {
            console.error('Error al guardar formData en localStorage:', error);
        }
    }, [formData, filterSpeed, filterGpsQuality, filterFuel, filterRpm, filterCutterBase, filterAutoPilot]);

    const transformPolygonCoords = (polygon) => {
        try {
            return polygon.map(ring => {
                if (Array.isArray(ring) && ring.every(coords => Array.isArray(coords) && coords.length === 2 && coords.every(coord => typeof coord === 'number'))) {
                    return ring.map(coords => [coords[1], coords[0]]);
                } else {
                    console.error('Coordenadas no válidas en Polygon:', ring);
                    return [];
                }
            }).filter(ring => ring.length > 0);
        } catch (error) {
            console.error('Error en transformPolygonCoords:', error);
            return [];
        }
    };


    useEffect(() => {
        try {
            // No correr si no hay puntos
            if (!points || points.length === 0) return;

            const pointsData = points;
            const totalPoints = pointsData.length;

            const pilotAutoPoints = pointsData.filter(point =>
                point.properties.PILOTO_AUTOMATICO &&
                typeof point.properties.PILOTO_AUTOMATICO === 'string' &&
                point.properties.PILOTO_AUTOMATICO.trim().toLowerCase() === 'engaged'
            ).length;

            const autoTracketPoints = pointsData.filter(point =>
                point.properties.AUTO_TRACKET &&
                typeof point.properties.AUTO_TRACKET === 'string' &&
                point.properties.AUTO_TRACKET.trim().toLowerCase() === 'engaged'
            ).length;

            const modoCorteBase = pointsData.filter(point =>
                point.properties.MODO_DE_CORTE_BASE &&
                typeof point.properties.MODO_DE_CORTE_BASE === 'string' &&
                point.properties.MODO_DE_CORTE_BASE.trim().toLowerCase() === 'automatic'
            ).length;

            const puntoEncontrado = pointsData.find(point =>
                point.properties.TIEMPO_TOTAL && point.properties.TIEMPO_TOTAL !== ""
            );

            let tiempoTotal = "00:00:00";
            if (puntoEncontrado) {
                tiempoTotal = puntoEncontrado.properties.TIEMPO_TOTAL;
            }

            const tiempoDecimal = convertTimeToDecimalHours(tiempoTotal);
            const outsideArea = isNaN(areaData.outsidePolygonArea) || areaData.outsidePolygonArea == null
                ? 0
                : areaData.outsidePolygonArea;

            const eficienciaTotal = isNaN(tiempoDecimal) || tiempoDecimal == null
                ? 0
                : tiempoDecimal;

            let totalEfficiency = eficienciaTotal === 0 ? 0 : outsideArea / eficienciaTotal;

            const calculatedPilotAutoPercentage = totalPoints > 0
                ? (pilotAutoPoints / totalPoints) * 100
                : 0;

            const calculatedAutoTracketPercentage = totalPoints > 0
                ? (autoTracketPoints / totalPoints) * 100
                : 0;

            const calculatedModoCortadorBasePercentage = totalPoints > 0
                ? (modoCorteBase / totalPoints) * 100
                : 0;

            setPercentage(prev => {
                const isSame =
                    prev.autoTracket === calculatedAutoTracketPercentage &&
                    prev.autoPilot === calculatedPilotAutoPercentage &&
                    prev.totalEfficiency === totalEfficiency;

                if (!isSame) {
                    return {
                        autoTracket: calculatedAutoTracketPercentage,
                        autoPilot: calculatedPilotAutoPercentage,
                        totalEfficiency: totalEfficiency
                    };
                }
                return prev;
            });

            if (percentageAutoPilot && typeof percentageAutoPilot === 'function') {
                percentageAutoPilot(
                    calculatedAutoTracketPercentage,
                    calculatedPilotAutoPercentage,
                    calculatedModoCortadorBasePercentage,
                    totalEfficiency
                );
            }
        } catch (error) {
            console.error('Error en useEffect para calcular porcentajes:', error);
        }
    }, [
        points,
        areaData.outsidePolygonArea,
        percentageAutoPilot
    ]);

    useEffect(() => {
        try {
            setIsMapButtonDisabled(!progressFinish);
        } catch (error) {
            console.error('Error en useEffect para isMapButtonDisabled:', error);
        }
    }, [progressFinish]);

    useEffect(() => {
        try {
            const checkAvailableFilters = () => {
                const hasSpeed = points.some(point => point.properties.VELOCIDAD_KMH != null && point.properties.VELOCIDAD_KMH !== "");
                const hasGpsQuality = points.some(point => point.properties.CALIDAD_GPS != null && point.properties.CALIDAD_GPS !== "");
                const hasFuel = points.some(point => point.properties.COMBUSTIBLE != null && point.properties.COMBUSTIBLE !== "");
                const hasRpm = points.some(point => point.properties.RPM != null && point.properties.RPM !== "");
                const hasCutterBase = points.some(point => point.properties.PRESION_DE_CORTADOR_BASE_BAR != null && point.properties.PRESION_DE_CORTADOR_BASE_BAR !== "");
                const hasAutoPilot = points.some(point => point.properties.PILOTO_AUTOMATICO != null && point.properties.PILOTO_AUTOMATICO !== "");
                const hasAutoTracket = points.some(point => point.properties.AUTO_TRACKET != null && point.properties.AUTO_TRACKET !== "");
                const hasModeCutterBase = points.some(point => point.properties.MODO_DE_CORTE_BASE != null && point.properties.MODO_DE_CORTE_BASE !== "");

                setAvailableFilters({
                    speed: hasSpeed,
                    gpsQuality: hasGpsQuality,
                    fuel: hasFuel,
                    rpm: hasRpm,
                    cutterBase: hasCutterBase,
                    autoPilot: hasAutoPilot,
                    autoTracket: hasAutoTracket,
                    modeCutterBase: hasModeCutterBase,
                });
            };

            checkAvailableFilters();
        } catch (error) {
            console.error('Error en useEffect para checkAvailableFilters:', error);
        }
    }, [points]);

    // Eliminado useEffect relacionado con GeoTIFF



    const updateGeoJSON = useCallback(async () => {
        try {
            const features = [];

            filteredPoints.forEach(point => {
                const accumulatedColors = {};

                appliedAnalyses.forEach(analysis => {
                    const value = point.properties[analysis.type];

                    const analysisColor = getColorForAnalysis(analysis.type, value);
                    if (analysisColor) {
                        accumulatedColors[analysis.type] = analysisColor;
                    }
                });

                let finalColor = 'green';
                if (Object.keys(accumulatedColors).length > 0) {
                    const lastAnalysisType = appliedAnalyses[appliedAnalyses.length - 1]?.type;
                    finalColor = accumulatedColors[lastAnalysisType] || finalColor;
                }

                const pointFeature = {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [point.geometry.coordinates[0], point.geometry.coordinates[1]]
                    },
                    properties: {
                        ...point.properties,
                        color: finalColor,
                        accumulatedColors: accumulatedColors
                    },
                    id: `point-${point.id || uuidv4()}`
                };

                features.push(pointFeature);
            });

            const newGeoJson = {
                type: "FeatureCollection",
                features: features,
            };

            setGeoJson(newGeoJson);

            const limites = {
                VELOCIDAD_KMH: { low: lowSpeed, med: medSpeed, high: highSpeed },
                CALIDAD_GPS: { low: lowGpsQuality, med: medGpsQuality, high: highGpsQuality },
                COMBUSTIBLE: { low: lowFuel, med: medFuel, high: highFuel },
                RPM: { low: lowRpm, med: medRpm, high: highRpm },
                PRESION_DE_CORTADOR_BASE_BAR: { low: lowCutterBase, med: medCutterBase, high: highCutterBase },
            };

            const isEmptyLimits = Object.values(limites).every(limit =>
                limit.low === 0 && limit.med === 0 && limit.high === 0
            );

            const formDataToSend = new FormData();
            if (idAnalisis && idAnalisis.data && idAnalisis.data.ID_ANALISIS) {
                formDataToSend.append('id_analisis', idAnalisis.data.ID_ANALISIS);
            } else {
                console.error("idAnalisis o idAnalisis.data.ID_ANALISIS es null o undefined.");
                formDataToSend.append('id_analisis', '0'); // Valor por defecto o manejo alternativo
            }
            const geoJsonBlob = new Blob([JSON.stringify(newGeoJson)], { type: 'application/json' });
            formDataToSend.append('geojson_file', geoJsonBlob, 'data.geojson');
            formDataToSend.append('tipo_analisis', tipoAnalisis || 'default_tipo');
            formDataToSend.append('limites', isEmptyLimits ? '{}' : JSON.stringify(limites));

            try {
                const response = await axios.post(`${API_BASE_PYTHON_SERVICE}mapping/update_geojson`, formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.data && response.data.status === 'success') {
                } else if (response.data && response.data.message) {
                    console.error('Error al actualizar GeoJSON:', response.data.message);
                } else {
                    console.error('Error desconocido al actualizar GeoJSON.');
                }
            } catch (error) {
                console.error('Error en la petición de actualización de GeoJSON:', error);
            }
        } catch (error) {
            console.error('Error en updateGeoJSON:', error);
        }
    }, [
        filteredPoints,
        appliedAnalyses,
        getColorForAnalysis,
        lowSpeed,
        medSpeed,
        highSpeed,
        lowGpsQuality,
        medGpsQuality,
        highGpsQuality,
        lowFuel,
        medFuel,
        highFuel,
        lowRpm,
        medRpm,
        highRpm,
        lowCutterBase,
        medCutterBase,
        highCutterBase,
        idAnalisis,
        tipoAnalisis
    ]);

    useEffect(() => {
        try {
            updateGeoJSON();
        } catch (error) {
            console.error('Error en useEffect para updateGeoJSON:', error);
        }
    }, [updateGeoJSON]);

    // Función para asignar colores basados en análisis aplicados
    const assignColors = useCallback((point) => {
        try {
            let color = 'green'; // Color predeterminado

            // Iterar sobre los análisis aplicados y determinar el color
            appliedAnalyses.forEach(analysis => {
                const value = point.properties[analysis.type];
                const analysisColor = getColorForAnalysis(analysis.type, value);
                // Combinar colores si es necesario. Aquí se sobrescribe el color, pero podrías modificarlo para combinar
                color = analysisColor;
            });

            return color;
        } catch (error) {
            console.error('Error en assignColors:', error);
            return 'green';
        }
    }, [appliedAnalyses, getColorForAnalysis]);


    // Aplicar filtros cuando cambian los filtros o los valores de los filtros
    useEffect(() => {
        try {
            applyFilters();
        } catch (error) {
            console.error('Error en useEffect para aplicar filtros:', error);
        }
    }, [
        applyFilters
    ]);

    // Función para manejar la apertura del diálogo de filtros
    const handleOpenMapDialog = () => {
        try {
            setIsMapDialogOpen(true);
        } catch (error) {
            console.error('Error en handleOpenMapDialog:', error);
        }
    };

    const handleCloseMapDialog = () => {
        try {
            setIsMapDialogOpen(false);
        } catch (error) {
            console.error('Error en handleCloseMapDialog:', error);
        }
    };

    // Función para alternar filtros
    const handleToggleFilter = (filterName) => {
        try {
            setActiveFilter(filterName);
            setOpenIndicator(filterName);
            switch (filterName) {
                case 'PILOTO_AUTOMATICO':
                    setFilterAutoPilot(prev => !prev);
                    break;
                case 'AUTO_TRACKET':
                    setFilterAutoTracket(prev => !prev);
                    break;
                case 'MODO_CORTE_BASE':
                    setFilterModeCutterBase(prev => !prev);
                    break;
                case 'VELOCIDAD_Km_H':
                    setFilterSpeed(prev => !prev);
                    break;
                case 'CALIDAD_DE_SENAL':
                    setFilterGpsQuality(prev => !prev);
                    break;
                case 'CONSUMOS_DE_COMBUSTIBLE':
                    setFilterFuel(prev => !prev);
                    break;
                case 'RPM':
                    setFilterRpm(prev => !prev);
                    break;
                case 'PRESION_DE_CORTADOR_BASE':
                    setFilterCutterBase(prev => !prev);
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(`Error en handleToggleFilter para filterName ${filterName}:`, error);
        }
    };

    const handleLabelClick = (text) => {
        try {
            // Implementa la lógica para manejar el clic en la etiqueta si es necesario
        } catch (error) {
            console.error('Error en handleLabelClick:', error);
        }
    };

    return (
        <>
            {/* Indicadores para cada filtro activo */}
            {availableFilters.speed && filterSpeed && openIndicator === 'VELOCIDAD_KMH' && (
                <BarIndicator filterType="speed" onLabelClick={handleLabelClick} setLabelsAndColors={setLabelsAndColors} />
            )}
            {availableFilters.gpsQuality && filterGpsQuality && openIndicator === 'CALIDAD_GPS' && (
                <BarIndicator filterType="gpsQuality" onLabelClick={handleLabelClick} setLabelsAndColors={setLabelsAndColors} />
            )}
            {availableFilters.fuel && filterFuel && openIndicator === 'COMBUSTIBLE' && (
                <BarIndicator filterType="fuel" onLabelClick={handleLabelClick} setLabelsAndColors={setLabelsAndColors} />
            )}
            {availableFilters.rpm && filterRpm && openIndicator === 'RPM' && (
                <BarIndicator filterType="rpm" onLabelClick={handleLabelClick} setLabelsAndColors={setLabelsAndColors} />
            )}
            {availableFilters.cutterBase && filterCutterBase && openIndicator === 'PRESION_DE_CORTADOR_BASE_BAR' && (
                <BarIndicator filterType="cutterBase" onLabelClick={handleLabelClick} setLabelsAndColors={setLabelsAndColors} />
            )}
            {availableFilters.autoPilot && filterAutoPilot && openIndicator === 'PILOTO_AUTOMATICO' && (
                <BarIndicator filterType="autoPilot" onLabelClick={handleLabelClick} setLabelsAndColors={setLabelsAndColors} />
            )}
            {availableFilters.autoTracket && filterAutoTracket && openIndicator === 'AUTO_TRACKET' && (
                <BarIndicator filterType="autoTracket" onLabelClick={handleLabelClick} setLabelsAndColors={setLabelsAndColors} />
            )}
            {availableFilters.modeCutterBase && filterModeCutterBase && openIndicator === 'MODO_DE_CORTE_BASE' && (
                <BarIndicator filterType="modeCutterBase" onLabelClick={handleLabelClick} setLabelsAndColors={setLabelsAndColors} />
            )}

            <CommonMap
                key={mapKey}
                center={mapCenter}
                zoom={zoom}
                polygons={[polygon]}
                points={filteredPoints}
                hullPolygon={hullPolygon}
                areasSuperpuestas={[]}
                nonIntersectedAreas={[]}
                bufferedLines={[]}
                bufferedIntersections={[]}
                activeFilter={activeFilter}
                filterValues={{
                    PILOTO_AUTOMATICO: { low: 0, medium: 0, high: 1 },
                    AUTO_TRACKET: { low: 0, medium: 0, high: 1 },
                    MODO_DE_CORTE_BASE: { low: 0, medium: 0, high: 1 },
                    VELOCIDAD_Km_H: { low: lowSpeed, medium: medSpeed, high: highSpeed },
                    CALIDAD_DE_SENAL: { low: lowGpsQuality, medium: medGpsQuality, high: highGpsQuality },
                    CONSUMOS_DE_COMBUSTIBLE: { low: lowFuel, medium: medFuel, high: highFuel },
                    RPM: { low: lowRpm, medium: medRpm, high: highRpm },
                    PRESION_DE_CORTADOR_BASE_BAR: { low: lowCutterBase, medium: medCutterBase, high: highCutterBase }
                }}
                polygonProperties={[]}
                showIntersections={false}
                onLineHover={() => { }}
                onLineMouseOut={() => { }}
                onLineClick={() => { }}
                mapRef={mapRef}
                userId={userData.userId || 'defaultUserId'}
                highlightedLote={highlightedLote}
                polygonsData={polygonsData}
                activeLotes={activeLotes}
                onSelectLote={onSelectLote}
                onHoverLote={onHoverLote}
                onLeaveLote={onLeaveLote}
                setImgLaflet={setImgLaflet}
                polygonsLayerRef={polygonsLayerRef}
                // Eliminados props relacionados con GeoTIFF
            />

            <MapDialog
                isOpen={isFilterDialogOpen}
                onClose={closeFilterDialog}
                availableFilters={availableFilters}
                filterSpeed={filterSpeed}
                filterGpsQuality={filterGpsQuality}
                filterFuel={filterFuel}
                filterRpm={filterRpm}
                filterCutterBase={filterCutterBase}
                filterAutoPilot={filterAutoPilot}
                filterAutoTracket={filterAutoTracket}
                filterModeCutterBase={filterModeCutterBase}
                lowSpeed={lowSpeed}
                medSpeed={medSpeed}
                highSpeed={highSpeed}
                lowGpsQuality={lowGpsQuality}
                medGpsQuality={medGpsQuality}
                highGpsQuality={highGpsQuality}
                lowFuel={lowFuel}
                medFuel={medFuel}
                highFuel={highFuel}
                lowRpm={lowRpm}
                medRpm={medRpm}
                highRpm={highRpm}
                lowCutterBase={lowCutterBase}
                medCutterBase={medCutterBase}
                highCutterBase={highCutterBase}
                handleToggleFilter={handleToggleFilter}
                setLowSpeed={setLowSpeed}
                setMedSpeed={setMedSpeed}
                setHighSpeed={setHighSpeed}
                setLowGpsQuality={setLowGpsQuality}
                setMedGpsQuality={setMedGpsQuality}
                setHighGpsQuality={setHighGpsQuality}
                setLowFuel={setLowFuel}
                setMedFuel={setMedFuel}
                setHighFuel={setHighFuel}
                setLowRpm={setLowRpm}
                setMedRpm={setMedRpm}
                setHighRpm={setHighRpm}
                setLowCutterBase={setLowCutterBase}
                setMedCutterBase={setMedCutterBase}
                setHighCutterBase={setHighCutterBase}
                usarVelocidadKmH={true}
                setTipoMapeo={setTipoMapeo}
            />
        </>
    );
};

export default Mapping;
