import { styled } from '@mui/system';
import { Box, Button, IconButton, Tooltip, Select } from '@mui/material';

export const ToolbarContainer = styled(Box)(({ theme, isSidebarOpen }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#d3d3d3',
    padding: '10px 20px',
    color: '#fff',
    position: 'fixed',
    top: '60px',
    width: 'calc(100% - 100px)',
    transition: 'width 0.5s ease',
    zIndex: 1001,
    height: '75px',
    justifyContent: 'space-between',
    '& .MuiButton-root': {
        margin: theme.spacing(1),
    },
}));

export const ButtonSection = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

export const ButtonGroup = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

export const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: '30px',
    backgroundColor: '#3a3a3a',
    color: '#fff',
    minWidth: '48px',
    fontSize: '11px',
    '& .MuiButton-startIcon': {
        margin: '0',
    },
    '&:hover': {
        backgroundColor: '#565656',
    },
    '&:disabled': {
        backgroundColor: '#b6b6b6',
        color: '#888',
    },
}));

export const IconButtonStyled = styled(IconButton)(({ theme, active }) => ({
    margin: theme.spacing(1),
    padding: 0,
    color: active ? '#0574C8' : 'default',
    '&:hover': {
        backgroundColor: active ? 'rgba(255, 255, 0, 0.1)' : 'inherit',
    },
}));

export const TooltipStyled = styled(Tooltip)({});

// === StyledSelect para que luzca igual que el StyledButton ===
export const StyledSelect = styled(Select)(({ theme }) => ({
    borderRadius: '30px',
    backgroundColor: '#3a3a3a',
    color: '#fff',
    fontSize: '11px',
    minWidth: '120px',
    marginRight: '10px',

    // El contorno (outline) del Select en modo normal:
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
    },

    // El icono de la flecha que abre el menú:
    '& .MuiSvgIcon-root': {
        color: '#fff',
    },

    // Efecto hover en el borde
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#565656',
    },

    // Efecto al estar enfocado
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#565656',
    },

    // Si quieres un padding interno:
    '& .MuiSelect-select': {
        padding: '6px 10px',
        display: 'flex',
        alignItems: 'center',
    },

    // Estilo para estado disabled
    '&.Mui-disabled': {
        backgroundColor: '#b6b6b6',
        color: '#888',
        '.MuiSvgIcon-root': {
            color: '#888',
        },
    },
}));
