import React, { useEffect, useState } from 'react';
import {
    BarIndicatorContainer,
    BarIndicatorWrapper,
    Tab,
    BarTitle,
    LabelContainer,
    LabelItem,
} from './BarIndicatorStyle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Tooltip from '@mui/material/Tooltip';

const BarIndicator = React.memo(({ filterType, onLabelClick, setLabelsAndColors }) => {
    const [isOpen, setIsOpen] = useState(false);

    const getTitleAndLabels = (type) => {
        switch (type) {
            case "speed":
                return {
                    title: "Velocidad",
                    labels: [
                        { color: '#4caf50', text: 'Bajo' },
                        { color: '#ffeb3b', text: 'Medio' },
                        { color: '#f44336', text: 'Alto' }
                    ]
                };
            case "gpsQuality":
                return {
                    title: "Calidad GPS",
                    labels: [
                        { color: '#4caf50', text: 'Bajo' },
                        { color: '#ffeb3b', text: 'Medio' },
                        { color: '#f44336', text: 'Alto' }
                    ]
                };
            case "fuel":
                return {
                    title: "Combustible",
                    labels: [
                        { color: '#4caf50', text: 'Bajo' },
                        { color: '#ffeb3b', text: 'Medio' },
                        { color: '#f44336', text: 'Alto' }
                    ]
                };
            case "autoPilot":
                return {
                    title: "Piloto Automático",
                    labels: [
                        { color: 'green', text: 'Enganchado' },
                        { color: 'red', text: 'Desenganchado' }
                    ]
                };
            case "cutterBase":
                return {
                    title: "Presión Cortador Base",
                    labels: [
                        { color: '#4caf50', text: 'Bajo' },
                        { color: '#ffeb3b', text: 'Medio' },
                        { color: '#f44336', text: 'Alto' }
                    ]
                };
            case "rpm":
                return {
                    title: "RPM",
                    labels: [
                        { color: '#4caf50', text: 'Bajo' },
                        { color: '#ffeb3b', text: 'Medio' },
                        { color: '#f44336', text: 'Alto' }
                    ]
                };
            case "modeCutterBase":
                return {
                    title: "Modo de corte base",
                    labels: [
                        { color: 'green', text: 'Automático' },
                        { color: 'red', text: 'Manual' }
                    ]
                };
            case "autoTracket":
                return {
                    title: "Auto Tracket",
                    labels: [
                        { color: 'green', text: 'Enganchado' },
                        { color: 'red', text: 'Desenganchado' }
                    ]
                };
            case "aplicacionesAreas":
                return {
                    title: "Aplicaciones Áreas",
                    labels: [
                        { color: 'green', text: 'Área aplicada' },
                        { color: 'red', text: 'Área sobre aplicada' }
                    ]
                };
            case "dosisReal":
                return {
                    title: "Dosis Real",
                    labels: [
                        { color: '#4caf50', text: 'Bajo' },
                        { color: '#ffeb3b', text: 'Medio' },
                        { color: '#f44336', text: 'Alto' }
                    ]
                };
            case "aerialApplicationsTraslape":
                return {
                    title: "Traslape de aplicaciones aéreas",
                    labels: [
                        { color: 'green', text: 'sin traslape' },
                        { color: 'red', text: 'traslape' }
                    ]
                };
            case "aerialApplicationsTraslapeDrones":
                return {
                    title: "Leyenda",
                    labels: [
                        { color: 'purple', text: 'Ancho de faja' },
                        { color: 'blue', text: 'traslape' },
                        { color: 'red', text: 'Linea de aplicación' },
                        { color: 'gray', text: 'Ruta de vuelo' }
                    ]
                };
            case "altura":
                return {
                    title: "Altura",
                    labels: [
                        { color: '#4caf50', text: 'Bajo' },
                        { color: '#ffeb3b', text: 'Medio' },
                        { color: '#f44336', text: 'Alto' }
                    ]
                };
            default:
                return {
                    title: "Indicador",
                    labels: [
                        { color: '#4caf50', text: 'Bajo' },
                        { color: '#ffeb3b', text: 'Medio' },
                        { color: '#f44336', text: 'Alto' }
                    ]
                };
        }
    };

    const { title, labels } = getTitleAndLabels(filterType);

    useEffect(() => {
        if (setLabelsAndColors && (title !== '' || labels.length > 0)) {
            setLabelsAndColors((prevState) => {
                if (
                    prevState.title === title &&
                    JSON.stringify(prevState.labels) === JSON.stringify(labels)
                ) {
                    return prevState;
                }
                return { title, labels };
            });
        }
    }, [title, labels, setLabelsAndColors]);

    return (
        <BarIndicatorContainer>
            <BarIndicatorWrapper isOpen={isOpen}>
                <BarTitle>{title}</BarTitle>
                <LabelContainer>
                    {labels.map((label, index) => (
                        <LabelItem
                            key={index}
                            style={{ backgroundColor: label.color }}
                            onClick={() => onLabelClick(label.text, label.color)}
                        >
                            {label.text}
                        </LabelItem>
                    ))}
                </LabelContainer>
            </BarIndicatorWrapper>
            <Tooltip title={isOpen ? "Ocultar leyenda" : "Mostrar leyenda"}>
                <Tab onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
                    {isOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </Tab>
            </Tooltip>
        </BarIndicatorContainer>
    );
});

export default BarIndicator;
